import React from 'react';

const PhoneInputNode = ({ label ,handleInputSelecting, inputSelected}) => {
    return (
        <div  key={label}  onClick={() => handleInputSelecting(label)} style={{borderColor: inputSelected[label] ? '#ba3291' : ''}} className="input-node d-flex center">
            <i className='bx bxs-phone'></i>
            <p style={{color: inputSelected[label] ? '#ba3291' : ''}}>{"Telefone"}</p>

        </div>
    );
}
export default PhoneInputNode;