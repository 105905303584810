import React, { useState } from 'react';

const ListResponseMessage = ({ title, description }) => {
    const [isListVisible, setIsListVisible] = useState(false);

    const toggleListVisibility = () => setIsListVisible(!isListVisible);

    // Opções do menu
    const options = [
        { id: "option_1", title: "Opção 1", description: "Descrição da opção 1" },
        { id: "option_2", title: "Opção 2", description: "Descrição da opção 2" },
        { id: "option_3", title: "Opção 3", description: "Descrição da opção 3" }
    ];

    // Função chamada ao clicar em uma opção
    const handleOptionClick = (option) => {
        // Enviar a opção selecionada ao componente pai
        onOptionSelect(option);
        setIsListVisible(false); // Opcional, para ocultar a lista após a seleção
    };

    return (
        <div className="list-response-message">
            {/* Cabeçalho */}
            <div className="list-response-message-header">
                <h5 style={{fontWeight: "bold"}}>{title}</h5>
            </div>

            {/* Corpo */}
            <div className="list-response-message-body">
                <p>{description}</p>
            </div>

            {/* Botão para exibir opções */}
            <div className="list-response-message-footer center">
                <button disabled={true}>
                    {"Ver Opções"}
                </button>
            </div>

            {/* Opções de Resposta */}
            {isListVisible && (
                <div className="list-response-message-options">
                    {options.map((option) => (
                        <div
                            key={option.id}
                            className="option"
                            onClick={() => handleOptionClick(option)}
                            role="button"
                            tabIndex={0}
                        >
                            <strong>{option.title}</strong>
                            <p>{option.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ListResponseMessage;
