import React, { useState, useEffect } from 'react';
import ImageWithFallback from "../ImageWithFallback";
import FilePreview from "../../FilePreview";

const SendResponseMessage = ({ messageId, messageContext, handleReplyMessage, onSendResponse , replyMessageId, selectedMessageReply, image_avatar, currentCompany, selectedChat}) => {

    const [showModal, setShowModal] = useState(false);
    const [replyText, setReplyText] = useState("");

    const handleClose = () => {
        setShowModal(false);
        handleReplyMessage(false, "")
        setReplyText(""); // Limpa o texto ao fechar o modal
        const elementos = document.querySelectorAll(".modal-backdrop");
        elementos.forEach(elemento => {
            elemento.classList.remove("modal-backdrop");
        });
    };

    useEffect(() => {
        if (messageId === replyMessageId && selectedMessageReply) {
            setShowModal(true);


            // Cria um novo elemento div
            let backdrop = document.createElement("div");

            // Adiciona as classes ao novo elemento
            backdrop.classList.add("modal-backdrop", "fade", "show");

            // Adiciona o elemento ao final do body
            document.body.appendChild(backdrop);

            document.querySelector('.modal-backdrop').style.zIndex = "1040";
            document.querySelector('.modal').style.zIndex = "1050";
            document.querySelector('.modal-content').style.zIndex = "1060";
        }

    }, [replyMessageId, selectedMessageReply, messageId]);

    const sendResponseMessage = () => {
        if (replyText.trim()) {
            onSendResponse(messageId, replyText); // Envia a resposta para o componente pai
            handleClose();
            setReplyText(""); // Limpa o texto ao fechar o modal
        }
    };


    return (<div className={`modal custom-modal fade ${showModal ? "show":  ""}`}
                 style={{ position:"absolute",

                     zIndex: 1050,
                     display: showModal ? "block": "none"}}
                 id={`responseMessage-${messageId}`}
                 key={`responseMessage-${messageId}`}
                 role="dialog">
                <div className="modal-dialog modal-dialog-centered" role="document">
                 <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title"> Responder</h5>
                    <button
                        className="btn-close"
                        type="button"
                        onClick={() => handleClose(`responseMessage-${messageId}`)}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        data-bs-original-title=""
                        title=""></button>
                </div>
                     <div className="modal-body list-persons">
                         <div className="d-flex align-items-center justify-content-center">
                             <div
                                 className={`msg mb-10 ${messageContext.position === "right" ? 'right-msg' : 'left-msg'}`} style={{minWidth: 420}}>
                                 <div className="msg-img">
                                     <ImageWithFallback
                                         classImage={""}
                                         src={messageContext.avatar}
                                         fallback={image_avatar}
                                         alt="Imagem"
                                     />
                                 </div>
                                 <div className="msg-bubble" style={{
                                     backgroundColor: messageContext.position === "right" ? "#e7e7e7" : "#f4ddfd",
                                     maxWidth: 365
                                 }}>
                                     <div className="msg-info">
                                         <div className="msg-info-name">
                                             {messageContext.position === "right" ?
                                                 `${currentCompany.name}${messageContext.sender ? " - " + messageContext.sender : ""}` :
                                                 selectedChat.title}
                                         </div>
                                     </div>
                                     {messageContext.type === 'text' && messageContext.subtype === 'text' &&
                                         <div
                                             className="msg-text message-bubble">{messageContext.text}</div>
                                     }
                                     {(messageContext.type === 'text/file' || messageContext.type === 'file') && (
                                         <React.Fragment>
                                             {messageContext.subtype.split('/')[0] === 'image' && (
                                                 <div className="msg-imagem center">
                                                     <a href={messageContext.file} target="_blank"
                                                        rel="noopener noreferrer">
                                                         <img src={messageContext.file} style={{
                                                             width: "30%",
                                                             borderRadius: "5px"
                                                         }}/>
                                                     </a>
                                                 </div>
                                             )}
                                             {messageContext.subtype.split('/')[0] === 'audio' && (
                                                 <div className="msg-audio">
                                                     <div className="holder">
                                                         <div
                                                             className="audio green-audio-player">
                                                             <a href={messageContext.file} target="_blank"
                                                                rel="noopener noreferrer">
                                                                 <audio width={230} height={230}
                                                                        src={messageContext.file} controls/>
                                                             </a>
                                                         </div>
                                                     </div>
                                                 </div>
                                             )}
                                             {messageContext.subtype.split('/')[0] === 'video' && (
                                                 <div className="msg-video">
                                                     <div className="holder">
                                                         <div
                                                             className="video green-video-player">
                                                             <a href={messageContext.file} target="_blank"
                                                                rel="noopener noreferrer">
                                                                 <video width={230} height={230}
                                                                        src={messageContext.file} controls/>
                                                             </a>
                                                         </div>
                                                     </div>
                                                 </div>
                                             )}
                                             {(messageContext.subtype.split('/')[0] === 'pdf' || messageContext.subtype.split('/')[1] === 'pdf') && (
                                                 <div className="msg-imagem">
                                                     <FilePreview fileUrl={messageContext.file}
                                                                  file_name={messageContext.file_name || ''}
                                                                  extension={messageContext.subtype}/>
                                                 </div>
                                             )}
                                             {messageContext.type === 'text/file' && messageContext.subtype.split('/')[0] !== 'image' && (
                                                 <div className="msg-text message-bubble">{messageContext.text}</div>
                                             )}
                                             { messageContext.type === 'text/file' &&  messageContext.subtype.split('/')[0] === 'image' && messageContext.context && messageContext.context["caption"] && (
                                                 <div className="msg-text message-bubble">{messageContext.context["caption"]}</div>
                                             )}
                                         </React.Fragment>
                                     )}

                                 </div>
                             </div>

                         </div>

                         <div className="msger-inputarea col-xl-12">
                              <textarea
                                  className="w-100"
                                  value={replyText}
                                  style={{height: 55}}
                                  onChange={(e) => setReplyText(e.target.value)}
                                  placeholder="Digite sua resposta..."
                              />
                         </div>


                         <div className="mt-10 ps-0">
                             <div
                                 className="d-flex align-items-center justify-content-center">

                             <button
                                         style={{marginRight: 20}}
                                         className="btn btn-secondary "
                                         type="submit"
                                         onClick={() => sendResponseMessage(messageId, replyText)}
                                         data-bs-original-title=""
                                         title="">Enviar
                                     </button>

                                     <button
                                         className="btn btn-primary"
                                         type="button"
                                         data-bs-dismiss="modal"
                                         onClick={() => handleClose(`responseMessage-${messageId}`)}
                                         data-bs-original-title=""
                                         title="">Cancelar
                                     </button>
                                 </div>
                             </div>


                     </div>
                 </div>
                </div>
    </div>
);

};

export default SendResponseMessage;
