import React, { useState, useEffect } from 'react';
import ImageWithFallback from "../ImageWithFallback";
const ResponseMessage = ({message, image_avatar, chat, currentCompany}) => {


    return (
        <div className="list-response-message" key={`response-message-${message.id}`} style={{borderLeft: "4px solid #007bff",
            paddingLeft: 16, /* Espaço entre a linha e o conteúdo do card */
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8
        }}>
            {/* Cabeçalho */}

            <div className="chat-time" style={{opacity: 0.3}}>
                <div className="d-flex justify-content-start">
                    <ImageWithFallback
                        classImage={"img-fluid rounded-circle"}
                        src={message.context["avatar"]}
                        style={{width: 50, height: 50}}
                        fallback={image_avatar}
                        alt="user"
                    />
                    <div className="d-flex" style={{
                        paddingLeft: 10,
                        alignItems: "flex-start",
                        flexWrap: "wrap",
                        justifyContent: 'flex-start',
                        flexDirection: "column"

                    }}>
                        <span className="msg-info-name">{message.context["position"] === "right" ?
                            `${currentCompany.name}${message.context["sender"]  ? " - " + message.context["sender"] : ""}` :
                            chat.title}</span>

                        {message.referringMessageType === "conversation" && (
                            <p style={{marginTop: "-3px"}} className="msg-text">{message.context["text"]}</p>
                        )}
                        {message.referringMessageType === "imageMessage"  &&  message.context["caption"] && (
                            <p style={{marginTop: "-3px"}} className="msg-text">{message.context["caption"]}</p>
                        )}
                        {message.referringMessageType === "audioMessage"  && (
                            <div className="msg-audio">
                                <div className="holder">
                                    <div className="audio">
                                        <a href={message.context["url"]} target="_blank"
                                           rel="noopener noreferrer">
                                            <audio width={230} height={230}
                                                   src={message.context["url"]} controls/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        )}


                    </div>
                    {message.referringMessageType === "imageMessage" && message.context["url"] && (

                        <div className="msg-imagem" style={{paddingLeft: 125}}>
                            <a href={message.context["url"]} target="_blank"
                               rel="noopener noreferrer">
                                <img src={message.context["url"]} style={{
                                    minWidth: 80,
                                    maxWidth: 80,
                                    minHeight: 60,
                                    maxHeight: 60,
                                    borderRadius: "5px"
                                }} alt={`imageMessage${message.id}`}/>
                            </a>
                        </div>
                    )}
                    {message.referringMessageType === "videoMessage" && message.context["url"] && (

                        <div className="msg-video" style={{paddingLeft: 125}}>
                            <a href={message.context["url"]} target="_blank"
                               rel="noopener noreferrer">
                                <video src={ message.context["url"]} style={{
                                    minWidth: 80,
                                    maxWidth: 80,
                                    minHeight: 60,
                                    maxHeight: 60,
                                    borderRadius: "5px"
                                }} controls/>
                            </a>
                        </div>
                    )}

                </div>


            </div>

            {/*<div className="list-response-message-header">
                <h4>{"ees"}</h4>
            </div>}


            {/* Corpo */}
            {/* <div className="list-response-message-body">
                <p>{"fdgsdfgdg"}</p>
            </div>*/}


        </div>
    );
}
export default ResponseMessage;