import React from "react";
import axios from 'axios';
import "../style/slick.css";
import "../style/slick-theme.css";
import "../style/conversa.css";
import "../style/scrollbar.css";
import "../style/emoji/uikit.min.css";
import Chats from "../support/Chats";
import Chat from "../support/Chat";
import CarouselComponent from '.././whatsapp/CarouselComponent';

class ChatRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedChat: null,
            myService: false,
            isLoading: false,
            currentChat: null,
            selectedInfoContact: null,
            selectInputSearch: false,
            chatValidate: true,
            messagesUnread: 0,
            chats:[],
            my_chats:[],
            waitingForServiceChats:[],
            selectedChatInfo:{
                ticket:[],
                selectedChat: null
            }
        };
    }
    backGroundStyle = {
        height: 785,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${this.props.login_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };

    handleChatSelect = (chat) => {
        this.setState({isLoading: true});
        this.setState({ selectedChat: chat });

        this.setState(prevState => ({
            selectedChatInfo: {
                ...prevState.selectedChatInfo,
                [chat]: chat
            }
        }));
    }
    handleSendSearch = (chats)=> {
        this.setState({ chats: chats });

    }
    async fetchGetChats() {
        if( !this.state.selectInputSearch){
            let response = await axios.get('/messagings/support/get_list_chats.json',);
            this.setState({chats: response.data.chats})
        }
    }


    changeChat = (chat) => {
        this.setState({currentChat: chat});
    }
    isLoadingUp = (isLoading) => {
        this.setState({isLoading: isLoading});
    }
    handleSelectSendSearch = (selectInputSearch)=> {
        this.setState({ selectInputSearch: !selectInputSearch });
    }
    handleChatValidate= (chatValidate)=> {
        this.setState({ chatValidate: chatValidate });
    }
    handleMyService= (myService)=> {
        this.setState({ myService: myService });
    }
    handleSelectedInfoContact= (selectedInfoContact)=> {
        this.setState({ selectedInfoContact: selectedInfoContact });
    }
    componentDidMount() {
        this.fetchGetChats();
        this.interval = setInterval(() => this.fetchGetChats(), 5000);
        if(this.state.onSelectSendSearch === false){

        }
    }
    componentWillUnmount() {
        clearInterval(this.interval);
        if(this.state.onSelectSendSearch === false){
           // Limpe o intervalo quando o componente for desmontado
        }
    }
    render() {
        const {  chats, selectInputSearch,messagesUnread, waitingForServiceChats , chatValidate, my_chats , selectedChatInfo, isLoading, currentChat} = this.state;
        const {companies, image_avatar, user_role, image_building,  current_company, image_support_v2, image_support,
            image_pdf_url,
            image_video_url,
            image_mp3_url,
            image_doc_url,
            image_audio_url,
            image_image_url,
            loader,
            current_user_name,
            current_user_id,
            current_user_profile_picture,
            user_support,
            chat,
            initial_messages

        } = this.props

        return (
            <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-xxl-3 col-xl-4 col-md-5 box-col-5">
                        <div className="left-sidebar-wrapper card">
                            <div className="left-sidebar-chat">

                            </div>
                            <div className="advance-options">

                                <div className="tab-content" id="chat-options-tabContent">
                                    <div className="tab-pane fade active show" id="new-chats" role="tabpanel"
                                         aria-labelledby="new-chats-tab">


                                        <Chats

                                            companies={companies}
                                            chats={chats}
                                            current_company={current_company}
                                            selectChatPhonebook={10}
                                            user_support={user_support}
                                            messagesUnread={messagesUnread}
                                            image_avatar={image_avatar}
                                            image_support_v2={image_support_v2}
                                            image_support={image_support}
                                            image_building={image_building}
                                            user_role={user_role}
                                            selectInputSearch={selectInputSearch}
                                            onChatSelect={this.handleChatSelect}
                                            onSendSearch={this.handleSendSearch}
                                            onSelectSendSearch={this.handleSelectSendSearch}
                                        />



                                    </div>


                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-md-7 box-col-7">

                        {user_role !== "support" &&
                            <Chat

                                image_avatar={image_avatar}
                                loader={loader}
                                user_role={user_role}
                                isLoading={isLoading}
                                image_support_v2={image_support_v2}
                                image_support={image_support}
                                image_building={image_building}
                                current_company={current_company}
                                currentChat={currentChat}
                                changeChat={this.changeChat}
                                isLoadingUp={this.isLoadingUp}
                                image_pdf_url={image_pdf_url}
                                image_doc_url={image_doc_url}
                                image_video_url={image_video_url}
                                image_mp3_url={image_mp3_url}
                                image_audio_url={image_audio_url}
                                image_image_url={image_image_url}
                                chat={chat}
                                initial_messages ={initial_messages}
                                user_support={user_support}
                                current_user_profile_picture={current_user_profile_picture}
                                current_user_name={current_user_name}
                                current_user_id={current_user_id}
                                onChatValidate={this.handleChatValidate}
                                onMyService={this.handleMyService}
                                onSelectedInfoContact={this.handleSelectedInfoContact}
                            />

                        }
                        {user_role === "support" &&
                            <Chat
                                chats={chats}
                                selectedChat={this.state.selectedChat}
                                image_avatar={image_avatar}
                                loader={loader}
                                user_role={user_role}
                                isLoading={isLoading}
                                image_support_v2={image_support_v2}
                                image_support={image_support}
                                image_building={image_building}
                                current_company={current_company}
                                currentChat={currentChat}
                                changeChat={this.changeChat}
                                isLoadingUp={this.isLoadingUp}
                                image_pdf_url={image_pdf_url}
                                image_doc_url={image_doc_url}
                                image_video_url={image_video_url}
                                image_mp3_url={image_mp3_url}
                                image_audio_url={image_audio_url}
                                image_image_url={image_image_url}
                                chat={chat}
                                initial_messages ={initial_messages}
                                user_support={user_support}
                                current_user_profile_picture={current_user_profile_picture}
                                current_user_name={current_user_name}
                                current_user_id={current_user_id}
                                onChatValidate={this.handleChatValidate}
                                onMyService={this.handleMyService}
                                onSelectedInfoContact={this.handleSelectedInfoContact}
                            />

                        }


                    </div>
                </div>
            </div>
        );
    }
}

export default ChatRoom;