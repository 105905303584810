import React from 'react';
import axios from 'axios';
export default class DashboardChat extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
        }
    }

    componentDidMount() {

    }

    render(){
        const {calendars, calendar_hours } = this.props;

        return (<>

            <div className="content-item mb-wrap">
                <div className="left">
                    <h5 className="font-w500">10:00</h5>
                </div>
                <div className="right bg-orange">
                    <div className="content-box w-100">
                        <h5 className="font-wb text-white fs-20">iOs Dev team meeting</h5>
                        <h6 className="font-w400 text-w07">10:00 - 11:00</h6>
                    </div>
                </div>
            </div>
            <div className="content-item mb-wrap mb-32">
                <div className="left">
                    <h5 className="font-w500">11:00</h5>
                </div>
                <div className="right d-flex pd-0">
                    <div className="content-box bg-yellow">
                        <h5 className="font-wb text-white fs-20">Design meeting</h5>
                        <h6 className="font-w400 text-w07">11:00 - 11:30</h6>
                    </div>
                    <div className="content-box bg-blue">
                        <h5 className="font-wb text-white fs-20">SEO meeting</h5>
                        <h6 className="font-w400 text-w07">11:30 12:00</h6>
                    </div>
                </div>
            </div>
            <div className="content-item mb-0 mb-wrap">
                <div className="left">
                    <h5 className="font-w500">12:00</h5>
                </div>
                <div className="right bg-light">
                    <div className="content-box w-100">
                        <h5 className="font-w500">Lunch Break</h5>
                        <h6 className="font-w400 mt-10">12:00 - 1:00</h6>
                    </div>
                </div>
            </div>
        </>);
    }
}