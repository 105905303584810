import React, { useState, useEffect } from 'react';
import "./style/kanban.css.scss";
const CustomLaneFooter = ({ onAddCardClick, ...laneProps }) => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '10px',
                backgroundColor: '#f0f0f0', // Cor de fundo para o rodapé da lane
                cursor: 'pointer',
                borderTop: '1px solid #ddd',
            }}

        >
            <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    fontSize: '16px',
                    cursor: 'pointer',
                    color: '#007bff'
                }}
            >
                ➕ Add Card
            </button>
        </div>
    );
};
export default CustomLaneFooter;