import React from 'react';

const EmailInputNode = ({label, inputSelected, handleInputSelecting}) => {


    return (
        <div  key={label} onClick={()=>handleInputSelecting(label)} style={{borderColor: inputSelected[label]  ? '#ba3291' : ''}}  className="input-node d-flex center">

            <i className='bx bx-envelope'></i>
            <p style={{color: inputSelected[label] ? '#ba3291' : ''}}>{"Email"}</p>

        </div>
    );
}
export default EmailInputNode;