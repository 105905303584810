import React, { useRef, useState, useEffect } from 'react';

const VideoPlayer = ({ videoSrc }) => {
    const videoPlayer = useRef(null);
    const playPauseIcon = useRef(null);
    const progress = useRef(null);
    const volumeProgress = useRef(null);
    const currentTime = useRef(null);
    const totalTime = useRef(null);
    const speaker = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [isVolumeOpen, setIsVolumeOpen] = useState(false);

    const togglePlay = () => {
        const player = videoPlayer.current;
        if (isPlaying) {
            player.pause();
            playPauseIcon.current.setAttribute('d', "M18 12L0 24V0");
        } else {
            player.play();
            playPauseIcon.current.setAttribute('d', "M0 0h6v24H0zM12 0h6v24h-6z");
        }
        setIsPlaying(!isPlaying);
    };

    const updateProgress = () => {
        const player = videoPlayer.current;
        const percent = (player.currentTime / player.duration) * 100;
        progress.current.style.width = `${percent}%`;
        currentTime.current.textContent = formatTime(player.currentTime);
    };

    const updateVolume = () => {
        const player = videoPlayer.current;
        volumeProgress.current.style.height = `${player.volume * 100}%`;
        if (player.volume >= 0.5) {
            speaker.current.setAttribute('d', 'M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z');
        } else if (player.volume < 0.5 && player.volume > 0.05) {
            speaker.current.setAttribute('d', 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667M17.333 11.373C17.333 9.013 16 6.987 14 6v10.707c2-.947 3.333-2.987 3.333-5.334z');
        } else if (player.volume <= 0.05) {
            speaker.current.setAttribute('d', 'M0 7.667v8h5.333L12 22.333V1L5.333 7.667');
        }
    };

    const formatTime = (time) => {
        const min = Math.floor(time / 60);
        const sec = Math.floor(time % 60);
        return `${min}:${sec < 10 ? '0' + sec : sec}`;
    };

    useEffect(() => {
        const player = videoPlayer.current;
        player.addEventListener('timeupdate', updateProgress);
        player.addEventListener('volumechange', updateVolume);
        player.addEventListener('loadedmetadata', () => {
            totalTime.current.textContent = formatTime(player.duration);
        });
        player.addEventListener('canplay', () => {
            playPauseIcon.current.style.display = 'block';
        });
        player.addEventListener('ended', () => {
            playPauseIcon.current.setAttribute('d', "M18 12L0 24V0");
            player.currentTime = 0;
            setIsPlaying(false);
        });

        const handleResize = () => {
            // Add your directionAware logic here
        };

        window.addEventListener('resize', handleResize);

        return () => {
            player.removeEventListener('timeupdate', updateProgress);
            player.removeEventListener('volumechange', updateVolume);
            player.removeEventListener('loadedmetadata', () => {
                totalTime.current.textContent = formatTime(player.duration);
            });
            player.removeEventListener('canplay', () => {
                playPauseIcon.current.style.display = 'block';
            });
            player.removeEventListener('ended', () => {
                playPauseIcon.current.setAttribute('d', "M18 12L0 24V0");
                player.currentTime = 0;
                setIsPlaying(false);
            });
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="msg-video">
            <div className="holder">
                <div className="video green-video-player">
                    <div className="loading">
                        <div className="spinner"></div>
                    </div>
                    <div className="play-pause-btn" onClick={togglePlay}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 18 24">
                            <path fill="#566574" fillRule="evenodd" d="M18 12L0 24V0" ref={playPauseIcon} />
                        </svg>
                    </div>

                    <div className="controls">
                        <span className="current-time" ref={currentTime}>0:00</span>
                        <div className="slider1" data-direction="horizontal">
                            <div className="progress" ref={progress}>
                                <div className="pin" id="progress-pin" data-method="rewind"></div>
                            </div>
                        </div>
                        <span className="total-time" ref={totalTime}>0:00</span>
                    </div>

                    <div className="volume">
                        <div className="volume-btn" onClick={() => setIsVolumeOpen(!isVolumeOpen)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#566574" fillRule="evenodd" d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z" ref={speaker} />
                            </svg>
                        </div>
                        <div className={`volume-controls ${isVolumeOpen ? '' : 'hidden'}`}>
                            <div className="slider" data-direction="vertical">
                                <div className="progress" ref={volumeProgress}>
                                    <div className="pin" id="volume-pin" data-method="changeVolume"></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <video ref={videoPlayer} crossOrigin="" controls>
                        <source src={videoSrc} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    );
};

export default VideoPlayer;