import React, {useState} from 'react';

const TextInputNode = ({label, inputSelected, handleInputSelecting, onAddCollectInputNode, handleInputTextChange, inputTextSelected}) => {

    return (
        <>
            <div key={label} onClick={()=>handleInputSelecting(label)} style={{borderColor: inputSelected[label]  ? '#ba3291' : '', width: "100%"}} className="input-node d-flex center">
                <i className='bx bx-text'></i>
               <p style={{color: inputSelected[label] ? '#ba3291' : ''}}>{"Texto"}</p>
            </div>
            <div  style={{display: inputSelected[label]  ? 'block' : 'none'}} className="opcao">
                <div>

                    <input
                        type="text"
                        style={{width: 260}}
                        value={inputTextSelected}
                        placeholder="Digite nome do input coletado ..."
                        className="proximostextarea textareamensgem"
                        onChange={handleInputTextChange}

                    />
                </div>
                <button className="btn btn-success btn-sm" onClick={onAddCollectInputNode}
                        type="button">Salvar
                </button>
            </div>
        </>


    );
}
export default TextInputNode;