import React, { useState } from 'react';
import styled from 'styled-components';
import ImageWithFallback from "../chats/ImageWithFallback";
import "./style/kanban.css.scss";

import ReactDOM from 'react-dom';
import {Button, Col, Form, Image, ListGroup, Modal, ProgressBar, Row} from "react-bootstrap";
import {ChromePicker} from "react-color";
const CardContainer = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  background-color: #fff;
  max-width: 290px;
  min-width: 290px;

`;

const CustomCard = ({ title, description,handleTagColor,handleCloseModal, closeModal,handleSaveChanges, onClick, color, currentUserId, ...props }) => {

    const usersToShow = props.users.slice(0, 2); // Mostra os primeiros 4 usuários
    const remainingCount = props.users.length - usersToShow.length; // Calcula o número de usuários restantes
    const [showDropdown, setShowDropdown] = useState(false);


    const toggleDropdown = (e) => {
        e.preventDefault();
        setShowDropdown(!showDropdown); // Alterna entre mostrar e esconder
    };


    const searchingUser = () => {
        console.log("searchingUser");

        if (props.users.length > 0) {
            const user = props.users.find(user => user.id === currentUserId);
            return !!user; // Retorna 'true' se o usuário for encontrado, caso contrário, 'false'
        } else {
            return false;
        }
    }
    return (
        <React.Fragment>
            <CardContainer key={props.id} style={{position: 'relative'}}>
                {/* Linha vertical no lado esquerdo */}
                <div style={{
                    width: '5px',
                    backgroundColor: color || "#a10889", // Defina a cor da lane dinamicamente
                    height: '80%',
                    position: 'absolute',
                    left: 0,
                    top: 15
                }}
                     onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = color)}
                ></div>


                <div>
                    <div onClick={onClick} className={'d-flex'}>
                        <div className="active-profile pr-20" style={{paddingRight: 10}}>
                            <ImageWithFallback
                                classImage={"img-fluid rounded-circle "}
                                src={props.avatar}
                                fallback={props.avatar_fallback}
                                alt="user"
                            />

                            <div className="status bg-success"></div>
                        </div>
                        <div>
                            <span>{title}
                                <i className='bx bxl-whatsapp'></i>
                        </span>
                            <p>{props.phone}</p>
                        </div>
                    </div>
                    <div>
                        <div style={{display: "flex", justifyContent: "center"}}>

                            {props.tags.length < 1 && <span style={{padding: "0px 135px"}}> </span>}

                        </div>
                        <div className="divider"></div>
                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                            <div className="linha">
                                <ul className="d-flex">
                                    {props.tags.length > 0 && <li>

                                        <div className="dropdown" style={{position: 'relative', zIndex: 10}}>
                                            <a href="#" onClick={toggleDropdown}>

                                                <i className='bx bx-purchase-tag-alt'></i>
                                            </a>

                                            <div className="dropdown-menu" style={{
                                                position: 'absolute',
                                                top: -83,
                                                left: 25,
                                                zIndex: 1000,
                                                backgroundColor: '#fff',
                                                border: '1px solid #ccc',
                                                borderRadius: '5px',
                                                padding: '10px',
                                               minWidth: 225,
                                                display: showDropdown ? "block": "none"
                                            }}>
                                                {props.tags.length > 0 && props.tags.map((tag) => (  <a className="dropdown-item">

                                                        <span key={tag.code} title={tag.label}
                                                              className={`texto-limitado badge ${handleTagColor(tag.code)}  `}>{tag.label}</span>

                                                </a> ))}

                                            </div>

                                        </div>

                                    </li>}
                                        {/* <li><a href="#" title="Contatos"><i className='bx bxs-contact'></i></a></li>*/}
                                    {searchingUser() === true && (
                                        <li>
                                            <a href={`/messagings.${props.links.chat}`} title="Chat">
                                                <i className='bx bx-chat bx-flip-horizontal'></i>
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div style={{paddingLeft: "55%"}}>
                                <ul className="user-list s3">
                                    {usersToShow.map((user) => (
                                        <li key={user.id}>
                                            <ImageWithFallback
                                                src={user.avatar}
                                                fallback={user.avatar_fallback}
                                                title={user.name}
                                                alt={`user${user.id}`}
                                            />
                                        </li>
                                    ))}
                                    {remainingCount > 0 && (
                                        <li className="total">
                                            <span>+{remainingCount}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>

                        </div>

                    </div>


                </div>

            </CardContainer>



        </React.Fragment>
    );
};
export default CustomCard;