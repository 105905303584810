import React from 'react';

const NameInputNode = ({label, inputSelected, handleInputSelecting}) => {


    return (
        <div key={label} onClick={() => handleInputSelecting(label)}
             style={{borderColor: inputSelected[label] ? '#ba3291' : ''}} className="input-node d-flex center">

            <i className='bx bx-text'></i>
            <p style={{color: inputSelected[label] ? '#ba3291' : ''}}>{"Nome"}</p>

        </div>
    );
}
export default NameInputNode;