import React from 'react';
import "./style/loader.css";
const Loader = ({src, className ='loader'}) => {
    return (
        <div className={className}>
            <img src={src} alt="Loading..." />
        </div>
    );
};

export default Loader;