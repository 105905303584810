import React from "react";
import axios from 'axios';
import InputMask from "react-input-mask";
export default class  EditInfoChatContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptionId: 0,
            selectedOptionDepartment: '',
            selectedOptionUser:'',
            selectedOptionTicket:'',
            openOptionsSelectedUser: false,
            openOptionsSelectedProcessStatus: false,
            selectedProcessStatus: '',
            selectedQueryType: '',
            queryType: [],
            users: [],
            isChecked: false,
            tagSelected: '',
            interest: '',
            numProcess: '',
            email: '',
        };
    }

    handleFirstSelectChange = (event) => {
        this.setState({ selectedOptionId: event.target.value });
    };

    componentDidMount() {
        const {isChecked}  = this.state;
        this.setState({ isChecked:   !this.props.infoContact.newLead});

        //var checkbox = document.querySelector('.switch input[type="checkbox"]');
        //checkbox.checked = !isChecked;
    }
    componentWillUnmount() {

    }
    handleOptionDepartmentChange = (value) => {

        this.setState({selectedOptionDepartment: value });

        const formData = new FormData();
        formData.append('department',value);

        fetch('/messagings/get_user_from_department', {
            method: 'GET',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {

            this.setState({openOptionsSelectedUser: true });
            this.setState({users: data.users});

        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });






    }

    handleOptionProcessStatusChange = (value) => {

        const {  query_types_new_process,query_types_in_progress,query_types_in_close} = this.props;

        this.setState({selectedProcessStatus: value });

        this.setState({openOptionsSelectedProcessStatus: true });

        if(value === 'IN_PROGRESS'){
            this.setState({queryType: query_types_in_progress });
        }else{
            if(value === 'NEW_PROCESS'){
                this.setState({queryType: query_types_new_process });
            }else{
                if(value === 'CLOSED'){
                    this.setState({queryType: query_types_in_close });
                }
            }
        }


    }
    handleOptionQueryTypeChange = (value) => {
        this.setState({selectedQueryType: value });
    }
    handleOptionUserChange = (value) => {
        this.setState({selectedOptionUser: value });
    }
    handleSubmit =  (id) => {
        const {selectedOptionUser }  = this.state;
        const formData = new FormData();
        formData.append('user',selectedOptionUser);
        formData.append('chat_id',id);


        fetch('/messagings/user_from_chat', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {



        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });



    }


    handleEditContactSelected = (id) => {
        const {tagSelected,numProcess, interest} = this.state


        if(tagSelected !== '' && numProcess !== '' && interest !== ''  ){

            const formData = new FormData();
            formData.append('id', id);
            formData.append('tagSelected', tagSelected);
            formData.append('numProcess', numProcess);
            formData.append('interest', interest);

            fetch('/contacts/edit', {
                method: 'POST',
                body: formData
            }).then(response => {
                if (!response.ok) {
                    throw new Error('Erro ao editar contact.');
                }
                return response.json();
            }).then(data => {
                this.setState({tagSelected: ''})
                this.setState({numProcess: ''})
                this.setState({interest: ''})

            })
                .catch(error => {
                    console.error('Erro ao editar contact:', error);
                });

        }

        document.getElementById("contact").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });

    };
    openEditMoreInfo(id_tab, editMoreInfo) {

        let elementEditMoreInfo = document.getElementById(editMoreInfo);

        if (elementEditMoreInfo) {
            elementEditMoreInfo.style.display = "none";
        } else {
            console.error("Element not found:", elementEditMoreInfo);
        }


        let element = document.getElementById(id_tab);
        console.log("Element:", element);
        if (element) {
            element.style.display = "block";
        } else {
            console.error("Element not found:", id_tab);
        }
    }
    handleInputChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    }
    handleTicketSubmit =  (id) => {
        const {selectedProcessStatus, selectedQueryType}  = this.state;

        const formData = new FormData();
        formData.append('process_status',selectedProcessStatus);
        formData.append('query_type',selectedQueryType);
        formData.append('chat_id',id);

        fetch('/messagings/add_ticket', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {



        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });



    }
    handleToggleClick = () => {
        const {isChecked}  = this.state;

        var checkbox = document.querySelector('.switch input[type="checkbox"]');

        this.setState({isChecked: checkbox.checked});


        let result =  axios.post(`/messagings/submit_for_me_the_chat.json`, {
            chat_id: checkbox.value,
            checked: !isChecked
        });

    }
    render() {


        const {selectedOptionId, isChecked, openOptionsSelectedUser, users, openOptionsSelectedProcessStatus, queryType, email}  = this.state;
        const { infoContact, instance_id, tickets, myService, user_role, onMyService, selectedChatInfo, departments  ,  process_status,
            query_types_new_process,query_types_in_progress,query_types_in_close, tags, currentCompany} = this.props;


        return (<div>

            <form>

                <div className="row">
                    <div className="col-sm-12">
                        <div className="email-general">
                            <h6  className="mb-4 mt-24 ">Atualização de Informações</h6>
                            {(user_role === "central"  || user_role === "support") && <div>


                                <a className="ps-0 edit-information mb-20"
                                   href="#"
                                   onClick={() => this.openEditMoreInfo("service", `ediServicetMoreInfo`)}
                                   data-bs-original-title=""
                                   id={`ediServicetMoreInfo`}
                                   title="">Atualizar Informações de Atendimento</a>

                                <div id={`service`}
                                     className="row g-2 more-data"
                                     style={{display: "none"}}>
                                    <h6 className="mb-3">Atendimento</h6>
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Departamento</label>
                                            <select className="form-control" id="idDepartment"
                                                    onChange={(e) => this.handleOptionDepartmentChange(e.target.value)}>
                                                <option defaultValue>Selecione o departamento</option>
                                                {departments.map((option, index) => (
                                                    <option key={option[1]} value={option[1]}>{option[0]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Atendente</label>
                                            <select className="form-control" id="idDepartment"
                                                    onChange={(e) => this.handleOptionUserChange(e.target.value)}>
                                                <option defaultValue>Selecione o atendente</option>
                                                {users.map((option, index) => (
                                                    <option key={option[1]} value={option[1]}>{option[0]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-4 mt-24 ">
                                        <div className="submit-section text-center">
                                            <button onClick={() => this.handleSubmit(infoContact.id)}
                                                    className="btn btn-primary submit-btn">Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>


                            </div>}

                            <a className="ps-0 edit-information mb-20"
                               href="#"
                               onClick={() => this.openEditMoreInfo("dataBasic", "ediDataBasicMoreInfo")}
                               data-bs-original-title=""
                               id={`ediDataBasicMoreInfo`}
                               title="">Atualizar Informações Básicas</a>

                            <div id={`dataBasic`}
                                 className="row g-2 more-data"
                                 style={{display: "none"}}>
                                <h6 className="mb-3">Informaçãos Básicas</h6>

                                <div className="mb-4 mt-24 ">
                                    <div className="form-group">
                                        <label>Email:</label>
                                        <input type="email" onChange={this.handleInputChangeEmail}
                                               value={email} className="form-control string email optional"
                                               id="edit_contact_email" name="edit_contact_email"/>

                                    </div>
                                </div>

                                <div className="mb-4 mt-24 ">
                                    <div className="submit-section text-center">
                                        <button onClick={() => this.handleSubmit(infoContact.id)}
                                                className="btn btn-primary submit-btn">Salvar
                                        </button>
                                    </div>
                                </div>

                            </div>
                            <a className="ps-0 edit-information mb-20"
                               href="#"
                               onClick={() => this.openEditMoreInfo("flux", "ediFluxMoreInfo")}
                               data-bs-original-title=""
                               id={`ediFluxMoreInfo`}
                               title="">Atualizar Informações do Fluxo de atendimento</a>

                            <div id={`flux`}
                                 className="row g-2 more-data"
                                 style={{display: "none"}}>
                                <h6 className="mb-3">Fluxo de Atendimento</h6>

                                <div className="mb-4 mt-24 ">
                                    <div className="form-group">
                                        <label>Etiqueta</label>
                                        <select className="form-control" id="idTicket"
                                                onChange={(e) => this.handleOptionProcessStatusChange(e.target.value)}>
                                            <option defaultValue>Selecione o Etiqueta</option>
                                            {process_status.map((option, index) => (
                                                <option key={option[1]} value={option[1]}>{option[0]}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                {openOptionsSelectedProcessStatus && <div>
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Etapa</label>
                                            <select className="form-control" id="idDepartment"
                                                    onChange={(e) => this.handleOptionQueryTypeChange(e.target.value)}>
                                                <option defaultValue>Selecione a etapa</option>
                                                {queryType.map((option, index) => (
                                                    <option key={option[1]} value={option[1]}>{option[0]}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mb-4 mt-24 ">
                                        <div className="submit-section text-center">
                                            <button onClick={() => this.handleTicketSubmit(infoContact.id)}
                                                    className="btn btn-primary submit-btn">Salvar
                                            </button>
                                        </div>
                                    </div>
                                </div>}
                            </div>


                        </div>





                        {/*<div className="switch-container" onClick={this.handleToggleClick}>
                            <ul className="nav panel-tabs w-100 d-flex justify-content-between">
                                <li>
                                    <label>
                                        <i className='bx bx-message-square-add'></i>
                                        { isChecked ?  "  Adicionado aos meus Atendimentos"  :  "  Adicionar aos meus Atendimentos" }


                                    </label>

                                </li>
                                <li>
                                    <label className="switch">
                                        <input type="checkbox" value={infoContact.id}/>
                                        <span className="slider round"></span>
                                    </label>
                                </li>
                            </ul>


                        </div>*/}
                    </div>
                </div>


                {/*   <div
                    className={`toggle ${isChecked ? 'active' : ''}`}
                    onClick={this.handleSwitchToggle}
                >
                    {isChecked ? 'Ativado' : 'Desativado'}
                </div>*/}


                {/*<div className="row">
                    <div className="col-sm-12">
                            <SelectComponent options={tickets} onSelect={this.handleFirstSelectChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="form-group">
                            <label>Atendente </label>
                            <select className="select">
                                <option>Client 1</option>
                                <option>Client 2</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="submit-section text-center">
                        <button className="btn btn-primary submit-btn">Salvar</button>
                    </div>
                </div>*/}

            </form>

        </div>);
    }

}