import React from "react";
import axios from 'axios';

class ChatRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedChat: null,
            myService: false,
            selectedInfoContact: null,
            selectInputSearch: false,
            chatValidate: true,
            messagesUnread: 0,
            chats:[],
            my_chats:[],
            waitingForServiceChats:[],
            selectedChatInfo:{
                ticket:[],
                selectedChat: null
            }
        };
    }

    componentDidMount() {

    }
    componentWillUnmount() {

    }
    render() {
        return (
            <div>
                test
            </div>
        );
    }
}
export default ChatRoom;