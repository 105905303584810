import React from 'react';
import axios from 'axios';
export default class QrCode extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            instance_id: props.instance_id,
            instance_status: '',
            base64_qrcode: '',
            loading: false,
            intervalTime: 15000,
            countdown: 15,
            countRedirect: 0,
        }
        this.getQRCode = this.getQRCode.bind(this);
        this.getPhoneStatus = this.getPhoneStatus.bind(this);
        this.startCountdown = this.startCountdown.bind(this);
    }

    fullscreenLoader  = {
        position: "fixed",
        top: 0,
        border: 0,
        left: 0,
        right: 0,
        background: "rgba(0,0,0,0.1)",
        zIndex: 10000,
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    };

    async fetchGetQrcode() {
        console.log("test")
        let formulas = await axios.get('/messagings/get_qr_code.json', {
            params: {
                instance_id: this.state.instance_id
            }
        });
        this.setState({base64_qrcode: formulas.data.qrcode})
        this.setState({instance_status: formulas.data.status})

    }

    componentDidMount() {
        // Inicie uma chamada de API a cada X segundos
        this.fetchGetQrcode();
        this.interval = setInterval(() => this.fetchGetQrcode(), 30000);
        this.phoneStatusInterval = setInterval(this.getPhoneStatus, this.state.intervalTime);
        this.countdownInterval = setInterval(this.startCountdown, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Limpe o intervalo quando o componente for desmontado
        clearInterval(this.phoneStatusInterval);
        clearInterval(this.countdownInterval);
    }
    async getQRCode() {
        this.setState({ loading: true });
        this.setState({intervalTime: 15000})
        this.setState({countdown: 15})
        try {
            const response = await axios.get('/messagings/conection_whatsapp.json');
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching QR code:', error);
        } finally {
            this.setState({ loading: false });
        }
    }

    async getPhoneStatus() {
        try {
            const response = await axios.get('/messagings/validate_phone_state.json');
            if (response.data.status === 'connecting' || response.data.status === 'created') {
                clearInterval(this.phoneStatusInterval);
            }

        } catch (error) {
            console.error('Error fetching phone status:', error);
        }
    }
    startCountdown() {
        this.setState(prevState => {
            if (prevState.countdown === 1) {
                return { countdown: 15 };
            }
            return { countdown: prevState.countdown - 1 };
        });
    }
    render() {

        const {base64_qrcode, instance_status, countRedirect} = this.state;
        const {qrcodesmoll, loader} = this.props;


        if (instance_status === "open" && countRedirect < 1) {
            this.setState({ countRedirect: 1 });
            window.location.reload()

        }
        return (
            <div className="info">

                <h2>Pra usar o Whatsapp no seu computador:</h2>
                {this.state.loading ||  instance_status === "open" && (
                    <div style={this.fullscreenLoader}>
                        <img style={{ width: '100px', top: '100px', height: '100px' }} src={loader} alt="Loading..." />
                    </div>
                )}
                {!this.state.loading && (
                    <div className="instructions">
                        <div className="d-flex justify-content-center qr-area mb-30">

                                {(instance_status === "created" || instance_status === "connecting") &&
                                    <div className="center">
                                        <div className="mb-3">
                                            <img width={400} src={base64_qrcode} alt="base64_qrcode"/>
                                        </div>
                                        <span>Gerando um novo QR-CODE em: {this.state.countdown}s</span>

                                    </div>
                                }
                                {(instance_status !== "created" && instance_status !== "connecting" && instance_status !== "open") &&
                                    <img width={400} src={qrcodesmoll} alt="qrcodesmoll"/>
                                }

                        </div>

                    </div>
                )}

                {instance_status !== "open"  &&
                    <div className=" instructions text-center ">
                        <div id="get_qr_code" onClick={this.getQRCode}
                             className=" w-90 btn alert bg-gradient-green logged-alert none text-white"
                             role="alert">Clique aqui para gerar o QrCode <img
                            src="https://cdn.discordapp.com/attachments/813368241954619406/1218635325950394368/firework.png?ex=66086196&is=65f5ec96&hm=6264c26799187ae54ada3e267df80a10c40d1c6cb13648a098669af6880dadfa&"
                            alt=""/>
                        </div>
                    </div>
                }
            </div>

        );




    }
}
