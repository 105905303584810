import React, { useState } from 'react';

const ListMessage = ({title, description, sections}) => {
    // Estado para controlar a visibilidade das seções
    const [isListVisible, setIsListVisible] = useState(false);

    // Função para alternar a visibilidade
    const toggleListVisibility = () => setIsListVisible(!isListVisible);

    // Dados das seções e opções (similar ao formato JSON do WhatsApp)


    return (
        <div className="list-message mb-10" >
            {/* Cabeçalho */}
            <div className="list-message-header">
                <h5 style={{fontWeight: "bold"}}>{title}</h5>
            </div>

            {/* Corpo */}
            <div className="list-message-body">
                <p>{description}</p>
            </div>

            {/* Rodapé */}
            <div className="list-message-footer center"  >
                <button onClick={toggleListVisibility}>
                    {isListVisible ? "Ocultar Opções" : "Ver Opções"}
                </button>
            </div>

            {/* Seções de Opções */}
            {isListVisible && (
                <div className="list-message-sections">
                    {sections.map((section, index) => (
                        <ul key={index} className="section">
                            <h5>{section.title}</h5>
                            <hr></hr>
                            {section.rows.map((row) => (
                                <li key={row.id} className="option">
                                    <h6>{row.title}</h6>
                                    <p>{row.description}</p>
                                </li>
                            ))}
                        </ul>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ListMessage;
