import React from 'react';
import axios from 'axios';
import { FaBolt, FaChevronDown } from 'react-icons/fa';
import ImageWithFallback from '../ImageWithFallback';
//import SimpleBar from 'simplebar-react';
//import 'simplebar-react/dist/simplebar.min.css';
export default class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentChat: null,
            inputSearch: '',
            chatList: [],
            chatSearch: [],
            isProjectOpen:false,
            isTasksOpen:false,
            isUserOpen:false,
        };
    }

     toggleProjectDropdown = () => {
         const { isProjectOpen} = this.state;
         this.setState({ isProjectOpen: !isProjectOpen });
     };

     toggleTasksDropdown = () => {
         const { isTasksOpen} = this.state;
         this.setState({ isTasksOpen: !isTasksOpen });
     };

     toggleUserDropdown = () => {
         const { isUserOpen} = this.state;
         this.setState({ isUserOpen: !isUserOpen });
    };
    handleChatItemClick = (chat) => {
        this.props.onChatSelect(chat);
        this.setState({ currentChat: chat });
    }

    handleSendSearch = async () => {

        const {inputSearch} = this.state;

        let response = await axios.get('/messagings/get_chat_list.json', {
            params: {
                instance_id: this.props.instance_id,
                inputSearch: inputSearch,
                type: "inputSearch",
                chat_type_search: "myChat"
            }
        });


        this.props.onSendSearch(response.data.chats, "myChat");
        this.props.onSelectSendSearch(true);

    }
    handleInputClick = () => {
        this.setState({ inputSearch: ''});
        this.props.onSelectSendSearch(!this.props.selectInputSearch);
        this.getchats();
    }
    getchats = async () => {

        let response = await axios.get('/messagings/support/get_list_chats.json', {});


        this.props.onSendSearch(response.data.chats);

    }
    handleInputChange = (event) => {
        this.setState({ inputSearch: event.target.value });
    }
    componentDidMount() {

    }
    componentWillUnmount() {

    }
    render() {
        const {currentChat, inputSearch, chatSearch, isProjectOpen} = this.state;
        let {chats, messagesUnread, selectChatPhonebook, image_avatar, companies, user_role, image_building, current_company, user_support, image_support, image_support_v2} = this.props
        let idSelectChatPhonebook = 0
        if(selectChatPhonebook){
            idSelectChatPhonebook = selectChatPhonebook
        }


        return (<>
            <div style={{marginBottom: 10}}></div>

            <div className="input-group search-area">
                <span onClick={this.handleSendSearch} className="input-group-text"><a href="#"><i
                    className="bx bx-search"></i></a></span>
                <input onClick={this.handleInputClick} type="text" onChange={this.handleInputChange}
                       value={inputSearch} className="form-control" placeholder="Buscar"></input>

            </div>

            <div style={{marginBottom: 5}}></div>

            <div className="common-space">
                <p>Conversas recentes</p>
                {/*<div className="header-top"><a className="btn badge-primary f-w-500" href="#!"><i
                    className="bx bxs-contact"></i></a></div>*/}
            </div>
            <ul className="chats-user" style={{height: 612}}>

                {user_role !== "support" &&
                    <>
                        {chats.map((chat) => (
                            <li key={chat.id}
                                className={`common-space active`}>
                                <div className="chat-time">
                                    <div className="active-profile">
                                        <ImageWithFallback
                                            classImage={"img-fluid rounded-circle"}
                                            src={image_support_v2}
                                            fallback={image_avatar}
                                            alt="user"
                                        />
                                        <div className="status bg-success"></div>
                                    </div>
                                    <div>
                                        <span>{user_support.name}</span>
                                        <p className="texto-limitado">{chat.lastMessage}</p>
                                    </div>
                                </div>
                                <div>
                                    {chat.countUnRead > 0 &&
                                        <>
                                            <p>{chat.dateLastMessage} </p>
                                            <div className="badge badge-light-success">{chat.countUnRead}</div>
                                        </>
                                    }
                                </div>
                            </li>
                        ))
                        }
                    </>
                }

                {user_role === "support" && <>



                        {/*companies.map((company) => (
                            <li key={company[1]} className="sidebar-submenu">
                                <div className="chat-time">
                                    <div className="active-profile">
                                        <ImageWithFallback
                                            classImage={"img-fluid rounded-circle"}
                                            src={image_building}
                                            fallback={image_building}
                                            alt="business"
                                        />

                                    </div>
                                    <div>
                                        <a href="#" onClick={this.toggleProjectDropdown}
                                           className="sidebar-menu-dropdown "
                                           style={{
                                               display: "flex",
                                               flexWrap: "nowrap",
                                               justifyContent: "space-between"
                                           }}>
                                                <span style={{
                                                    maxWidth: 160,
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis"

                                                }} >{company[0]}</span>
                                                <div  style={{
                                                    display: "flex",
                                                    flexWrap: "nowrap",
                                                    justifyContent: "space-between",
                                                    paddingLeft: 20
                                                }}>
                                                    <span className="badge badge-info">1</span>


                                                    {!isProjectOpen && <i className='bx bx-chevron-down'></i>}
                                                    {isProjectOpen && <i className='bx bx-chevron-up'></i>}

                                                </div>


                                            </a>

                                        </div>
                                    </div>




                                    {isProjectOpen && (
                                        <ul className="chats-user">

                                            {chats.map((chat) => (
                                                <li key={chat.id}
                                                    onClick={() => this.handleChatItemClick(chat)}
                                                    className={`common-space ${(currentChat?.id === chat.id || idSelectChatPhonebook === chat.id) ? 'active' : ''}`}>
                                                    <div className="chat-time">
                                                        <div className="active-profile">
                                                            <ImageWithFallback
                                                                classImage={"img-fluid rounded-circle"}
                                                                src={chat.avatar}
                                                                fallback={image_avatar}
                                                                alt="user"
                                                            />
                                                            <div className="status bg-success"></div>
                                                        </div>
                                                        <div>
                                                            <span>{chat.title}</span>
                                                            <p className="texto-limitado">{chat.lastMessage}</p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p>2 min </p>
                                                        <div
                                                            className="badge badge-light-success">{chat.countUnRead}</div>
                                                    </div>
                                                </li>
                                            ))
                                            }


                                        </ul>
                                    )}
                                </li>

                            ))*/}

                        {chats.map((chat) => (
                                <li key={chat.id}
                                    onClick={() => this.handleChatItemClick(chat)}
                                    className={`common-space active`}>
                                    <div className="chat-time">
                                        <div className="active-profile">
                                            <ImageWithFallback
                                                classImage={"img-fluid rounded-circle"}
                                                src={chat.avatar}
                                                fallback={image_avatar}
                                                alt="user"
                                            />
                                            <div className="status bg-success"></div>
                                        </div>
                                        <div>
                                            <span>{chat.title}</span>
                                            <p className="texto-limitado">{chat.lastMessage}</p>
                                        </div>
                                    </div>
                                    <div>
                                        {chat.countUnRead > 0 &&
                                            <>
                                                <p>{chat.dateLastMessage} </p>
                                                <div className="badge badge-light-success">{chat.countUnRead}</div>
                                            </>
                                        }
                                    </div>
                                </li>
                            ))}


                    </>}


            </ul>

            </>
        );
    }
}