import React from 'react';
import axios from 'axios';
export default class DashboardCalendar extends React.Component {
    constructor(props) {
        super(props)
        this.state ={
            calendars:[],
            calendar_hours:[]
        }
    }
    async fetchGetCalendar() {
        console.log("test")
        let formulas = await axios.get('/dashboards/my_schedule.json', {
            params: {
                instance_id: ''
            }
        });
        this.setState({calendars: formulas.data.calendars})
        this.setState({calendar_hours: formulas.data.calendar_hours})

    }
    componentDidMount() {
        this.fetchGetCalendar();
        this.interval = setInterval(() => this.fetchGetCalendar(), 60000);
    }
    componentWillUnmount() {
        clearInterval(this.interval); // Limpe o intervalo quando o componente for desmontado
    }
    render(){
        const { calendars, calendar_hours, bgs } = this.props;


        return (<>
            {Object.values(calendars).length === 0 && <div>  <a> Nada marcado nesse horario </a> </div> }
            {Object.values(calendars).length > 0 && calendar_hours.map((hours) => (

            <>
                {calendars[hours] && <div className="content-item mb-wrap">
                        <div className="left">
                            <h5 className="font-w500">{hours}:00</h5>
                        </div>

                        {calendars[hours].length > 1 && (
                            <div className="right d-flex pd-0">
                                {calendars[hours].map((calendar, position) => {
                                    const randomIndex = Math.floor(Math.random() * bgs.length);
                                    const randomBg = bgs[randomIndex];
                                    return (
                                        <div className={`content-box ${randomBg}`} key={position}>
                                            <h5 className="font-wb text-white fs-20">{calendar.name}</h5>
                                            <h6 className="font-w400 text-w07">{calendar.startTime} - {calendar.endTime}</h6>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {calendars[hours].length < 2 && calendars[hours].map((calendar) => {
                            const randomIndex = Math.floor(Math.random() * bgs.length);
                            const randomBg = bgs[randomIndex];
                            return (
                                <div className={`right ${randomBg}`} >
                                    <div className="content-box w-100">
                                        <h5 className="font-wb text-white fs-20">{calendar.name}</h5>
                                        <h6 className="font-w400 text-w07">{calendar.startTime} - {calendar.endTime}</h6>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                }
            </>))}

        </>);
    }
}