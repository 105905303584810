import React, { memo, useState } from 'react';
import { Handle, useReactFlow, useStoreApi, Position } from "react-flow-renderer";
import ImageWithFallback from "../chats/ImageWithFallback";


function Option({ value, handleId, nodeId }) {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();


    const onChange = (evt) => {
        console.log("dfsd onChange -> " + evt)
        const { nodeInternals } = store.getState();
        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                if (node.id === nodeId) {
                    node.data = {
                        ...node.data,
                        options: {
                            ...node.data.options,
                            [handleId]: evt.target.value,
                        },
                    };
                }

                return node;
            })
        );
    };


    return (
        <div id={`option-flow-${handleId}`} key={`option-flow-${handleId}`} className="custom-node__select">
            <div className="nodrag" onChange={onChange}>
                <div className="select-flow-flex">
                    <div className="d-flex justify-content-start">
                        <p style={{fontSize: 10}}>{value}</p>
                    </div>
                    <Handle type="source" position={Position.Right} id={handleId}/>

                </div>

            </div>

        </div>

    );
}
function Attributes({ title, description, handleId, nodeId }) {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();


    const onChange = (evt) => {
        console.log("onChange -> ", evt.target.value);
        const { nodeInternals } = store.getState();

        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                // Verifica se o nodeId corresponde
                if (node.id === nodeId) {
                    // Atualiza a seleção no node.data.action.selections
                    node.data.action = {
                        ...node.data.action,
                        attributes: {
                            ...node.data.action.attributes,
                            [handleId]: evt.target.value,  // Atualiza o valor do handleId
                        },
                    };
                }

                return node; // Retorna o nó atualizado ou original se não houver mudanças
            })
        );
    };


    return (
        <div id={`select-flow-${handleId}`}  key={`select-flow-${handleId}`} className="custom-node__select">
            <div className="nodrag" onChange={onChange}>
                <div className="select-flow-flex">
                    <div className="d-flex justify-content-start">
                        <p style={{fontSize: 10}}>{title}</p>
                    </div>
                </div>

            </div>

        </div>

    );
}
function Buttons({ title, typeButton, info, handleId, nodeId }) {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();


    const onChange = (evt) => {
        console.log("onChange -> ", evt.target.value);
        const { nodeInternals } = store.getState();

        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                // Verifica se o nodeId corresponde
                if (node.id === nodeId) {
                    // Atualiza a seleção no node.data.action.selections
                    node.data.action = {
                        ...node.data.action,
                        buttons: {
                            ...node.data.action.buttons,
                            [handleId]: evt.target.value,  // Atualiza o valor do handleId
                        },
                    };
                }

                return node; // Retorna o nó atualizado ou original se não houver mudanças
            })
        );
    };


    return (
        <div id={`select-flow-${handleId}`}  key={`select-flow-${handleId}`} className="custom-node__select">
            <div className="nodrag" onChange={onChange}>
                <div className="select-flow-flex">
                    <div className="d-flex justify-content-start">
                        <p style={{fontSize: 10}}>{title}</p>
                    </div>
                    <Handle type="source" position={Position.Right} id={handleId}/>

                </div>

            </div>

        </div>

    );
}

function Selection({ title, description, handleId, nodeId }) {
    const { setNodes } = useReactFlow();
    const store = useStoreApi();


    const onChange = (evt) => {
        console.log("onChange -> ", evt.target.value);
        const { nodeInternals } = store.getState();

        setNodes(
            Array.from(nodeInternals.values()).map((node) => {
                // Verifica se o nodeId corresponde
                if (node.id === nodeId) {
                    // Atualiza a seleção no node.data.action.selections
                    node.data.action = {
                        ...node.data.action,
                        selections: {
                            ...node.data.action.selections,
                            [handleId]: evt.target.value,  // Atualiza o valor do handleId
                        },
                    };
                }

                return node; // Retorna o nó atualizado ou original se não houver mudanças
            })
        );
    };


    return (
        <div id={`select-flow-${handleId}`}  key={`select-flow-${handleId}`} className="custom-node__select">
            <div className="nodrag" onChange={onChange}>
                <div className="select-flow-flex">
                    <div className="d-flex justify-content-start">
                        <p style={{fontSize: 10}}>{title}</p>
                    </div>
                    <Handle type="source" position={Position.Right} id={handleId}/>

                </div>

            </div>

        </div>

    );
}

function Collection({ inputSelected, inputTextSelected, handleId}){

    console.log( "inputSelected " + inputSelected);
    console.log( "inputTextSelected " + inputTextSelected);

    const title = {
        textInput: inputTextSelected,
        phoneInput: "Telefone",
        emailInput: "Email",
        dateInput: "Data",
        imageInput: "Imagem",
        nameInput: "Nome"
    }

    const icon = {
        textInput: 'bx bx-text',
        phoneInput: 'bx bxs-phone',
        emailInput: 'bx bx-envelope',
        dateInput: 'bx bx-calendar',
        imageInput: 'bx bx-image',
        nameInput: 'bx bx-text'
    }


    return (

        <div id={`collect-flow-${handleId}`} key={`select-flow-${handleId}`} className="custom-node__select">
                <div className="select-flow-flex">
                         <div className="d-flex justify-content-start center" style={{alignItems: "center"}}>
                        <i className={`${icon[inputSelected] || ""}`} style={{color: '#121212'}}></i>
                        <p style={{fontSize: 10, paddingLeft: 5}}>{title[inputSelected] || ""}</p>
                    </div>


                </div>


        </div>

)
}

function formatPhoneNumber (phone)  {
    const cleaned = phone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

    // Verifica se o número tem 10 ou 11 dígitos
    if (cleaned.length === 10) {
        const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }
    } else if (cleaned.length === 11) {
        const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
        }
    }

    // Retorna o número sem formatação se não corresponder a 10 ou 11 dígitos
    return phone;
}
function getProcess(process) {
    switch (process) {
        case 'simulation':
        case 'NEW_PROCESS':
        case 'IN_PROGRESS':
            return 'badge-primary';
        case 'approved':
            return 'badge-success';
        case 'reproved':
            return 'badge-danger';
        case 'resend_documents':
        case 'under_analysis':
        case 'send_documents':
        case 'company_analysing':
        case 'awaiting_contract':
        case 'CLOSED':
            return 'badge-warning';
        case 'REPASSADO_PARA_EMPRESA':
        case 'PRE_APROVADO':
            return 'badge-success';
        case 'hired':
        case 'fill_data':
            return 'badge-info';
        case 'released':
            return 'badge-purple';
        default:
            return 'badge-success';
    }
}
function toggleDropdownBot(element)  {
    // Encontra o menu dropdown mais próximo do botão clicado
    var dropdownMenu = element.nextElementSibling;

    // Verifica se o menu já está visível
    if (dropdownMenu.classList.contains('show')) {
        dropdownMenu.classList.remove('show');  // Fecha o menu se estiver aberto
    } else {
        // Fecha outros dropdowns abertos (opcional, se houver múltiplos dropdowns)
        document.querySelectorAll('.dropdown-menu.show').forEach(function(menu) {
            menu.classList.remove('show');
        });

        // Abre o menu
        dropdownMenu.classList.add('show');

        // Define as propriedades de posição
        dropdownMenu.style.top = '16px';  // Adiciona a propriedade 'top'
        dropdownMenu.style.left = '1px';  // Adiciona a propriedade 'left'
    }
}

function FormComponent({id, data}) {
    const [isExpanded, setIsExpanded] = useState(false); // Estado de expansão

    const toggleExpand = () => {
        setIsExpanded(!isExpanded); // Alterna entre aberto e fechado
    };



    return (
        < >
            {data.type === "start" && <div key={`header_start-${id}`}>

                <div className="container">
                    <div className="custom-node__header">
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>Start</p>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-15">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-flag bx-flip-horizontal' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.message}</p>
                        </div>
                    </div>
                    <Handle type="source" position={Position.Bottom} id={id}/>
                </div>


            </div>}
            {(data.type === "message" || data.type === "files") && <div key={`header_message-${id}`}>

                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}></Handle>
                    <div className="custom-node__header_message" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>Enviar</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown" onClick={(e) => toggleDropdownBot(e.currentTarget)} aria-expanded="false">
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i className='bx bx-duplicate' ></i>Duplicar</a>

                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className={`internal-radius ${(data.message !== "" && Object.keys(data.options).length > 0) ? "mb-15" : '' }`} >
                        {data.media.type !== undefined && <div className="d-flex justify-content-center"
                                                               style={{paddingLeft: 5, alignItems: "center"}}>
                            {data.media.type === "audio" &&
                                <div className="pro-user-username  fs-14">
                                    <audio style={{width: 145}} src={data.media.src} controls/>
                                </div>

                            }
                            {data.media.type === "image" &&
                                <div><img width={100} height={100} src={data.media.src} alt="Preview"/></div>
                            }
                            {data.media.type === "video" &&
                                <div>
                                    <video width={145} height={100} src={data.media.src} controls/>
                                </div>
                            }
                            {(data.media.type === "document" || data.media.type === "application") &&
                                <div>
                                    <img width={100} height={100} src={data.media.viewFile}/>

                                </div>

                            }
                        </div>}
                        {data.message !== "" && <>
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>
                                <i className='bx bx-conversation bx-flip-horizontal' style={{color: '#121212'}}></i>


                                <p style={{fontSize: 10, paddingLeft: 5}}>{data.message}</p>
                            </div>
                        </>}
                        {Object.keys(data.options).length === 0 &&
                            <Handle type="source" position={Position.Right} id={id}/>}
                    </div>
                    {data.options && Object.keys(data.options).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{ paddingLeft: 5,width: "100%"}}>
                                    { data.options.map((option) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Option key={option.id} nodeId={id} value={option.value} handleId={option.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }
                </div>


            </div>}
            {data.type === "action" && <div key={`header_action-${id}`}>

                <div className="container">
                    <div className="custom-node__action">
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.message}</p>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-flag bx-flip-horizontal' style={{color: '#121212'}}></i>

                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.message}</p>

                        </div>
                    </div>
                    <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>
                </div>

            </div>}
            {data.type === "redirect" && <div key={`header_redirect_${id}`}>

                    <div className="container">
                        <div className="custom-node__action" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                            <div className="d-flex justify-content-between">
                                <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                                <div className="dropdown"
                                     style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                    <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown" onClick={(e) => toggleDropdownBot(e.currentTarget)}
                                       aria-expanded="false">
                                        <i className='bx bx-edit'></i>

                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right" >
                                        <a className="dropdown-item"  href="#"
                                           onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                            className='bx bx-duplicate'></i>Duplicar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                            className='bx  bx-edit-alt'></i> Editar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                            className='bx  bx-trash'></i> Excluir</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="internal-radius">
                        <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>
                                {data.action.action.type_redirect ===  'USER' &&
                                    <div>
                                        <p style={{fontSize: 10, paddingLeft: 5}}>Atendente</p>
                                        <div className="d-flex justify-content-start"
                                             style={{paddingLeft: 5, alignItems: "center"}}>
                                            <ImageWithFallback
                                                classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                                src={data.action.action.user_avatar}
                                                style={{width: 50, height: 50}}
                                                fallback={data.action.action.user_avatar}
                                                alt={`${data.action.action.user_name}'s avatar`}
                                            />

                                            <div className="ml-2">
                                                <div className="text-lg font-bold" style={{color: '#121212'}}>{data.action.action.user_name}</div>
                                                <div className="text-gray-500" style={{color: '#121212'}}>{data.action.action.user_job}</div>
                                            </div>

                                        </div>

                                    </div>

                                }
                                {data.action.action.type_redirect === 'DEPARTMENT' &&

                                    <div>
                                        <p style={{fontSize: 10, paddingLeft: 5}}>Departamento</p>
                                        <div className="d-flex justify-content-start"
                                             style={{paddingLeft: 5, alignItems: "center"}}>
                                            <ImageWithFallback
                                                classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                                src={data.action.action.department_avatar}
                                                style={{width: 50, height: 50}}
                                                fallback={data.action.action.department_avatar}
                                                alt={`${data.action.action.department_name}'s department`}
                                            />

                                            <div className="ml-2">
                                                <div className="text-lg font-bold"
                                                     style={{color: '#121212'}}>{data.action.action.department_name}</div>

                                            </div>

                                        </div>

                                    </div>
                                }
                                {data.action.action.type_redirect === 'CONNECT_BOT' && <div>
                                    <p style={{fontSize: 10, paddingLeft: 5}}>ChatBot</p>
                                    <div className="d-flex justify-content-start"
                                         style={{paddingLeft: 5, alignItems: "center"}}>
                                        <ImageWithFallback
                                            classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                            src={data.action.action.bot_avatar}
                                            style={{width: 50, height: 50}}
                                            fallback={data.action.action.bot_avatar}
                                            alt={`${data.action.action.bot_name}'s department`}
                                        />

                                        <div className="ml-2">
                                            <div className="text-lg font-bold"
                                                 style={{color: '#121212'}}>{data.action.action.bot_name}</div>

                                        </div>

                                    </div>

                                </div>}


                            </div>
                        </div>
                        <Handle type="target" position={Position.Left} id={id}/>

                    </div>


                </div>}
            {data.type === "trigger" && <div key={`header_message-${id}`}>
                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}/>
                    <div className="custom-node__trigger" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown" onClick={(e) => toggleDropdownBot(e.currentTarget)} aria-expanded="false">
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius ">
                    <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bxs-paper-plane' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.message}</p>
                        </div>
                    </div>
                    <Handle type="source" position={Position.Right} id={id}/>
                </div>
            </div>}
            {data.type === "tag"  && <div key={`header_tag_${id}`}>

                    <div className="container">
                        <div className="custom-node__tag" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                            <div className="d-flex justify-content-between">
                                <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                                <div className="dropdown"
                                     style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                    <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                       aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                        <i className='bx bx-edit'></i>

                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                            className='bx bx-duplicate'></i>Duplicar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                            className='bx  bx-edit-alt'></i> Editar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                            className='bx  bx-trash'></i> Excluir</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="internal-radius">
                        <div className="d-flex justify-content-start"
                                 style={{alignItems: "center"}}>
                               <div style={{marginLeft: -6}}>
                                 <span className={`texto-limitado badge mb-3 ${getProcess(data.action.action.code_process_status)}`}>{data.action.action.label_process_status}</span>
                                <span className={`texto-limitado badge ${getProcess(data.action.action.code_query_type)}`}>{data.action.action.label_query_type}</span>
                               </div>

                            </div>
                        </div>
                        <Handle type="target" position={Position.Left} id={id}/>
                        <Handle type="source" position={Position.Right} id={id}/>
                    </div>


                </div>}
            {data.type === "notify"  && <div key={`header_notify_${id}`}>

                    <div className="container">
                        <div className="custom-node__notify" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                            <div className="d-flex justify-content-between">
                                <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                                <div className="dropdown"
                                     style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                    <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                       aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                        <i className='bx bx-edit'></i>

                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                            className='bx bx-duplicate'></i>Duplicar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                            className='bx  bx-edit-alt'></i> Editar</a>
                                        <a className="dropdown-item" href="#"
                                           onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                            className='bx  bx-trash'></i> Excluir</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>

                        {data.action.action.users.ids.map((id) => (
                            <div key={id} className="internal-radius mb-3">
                                <div  className="d-flex justify-content-start"
                                     style={{paddingLeft: 5, alignItems: "center"}}>
                                    <ImageWithFallback
                                        classImage={"img-50 img-fluid m-r-20 rounded-circle update_img_0"}
                                        src={data.action.action.users.data[id].avatar}
                                        style={{width: 50, height: 50}}
                                        fallback={data.action.action.users.data[id].avatar}
                                        alt={`${data.action.action.users.data[id].name}'s department`}
                                    />

                                    <div className="ml-2">
                                        <div className="text-lg font-bold"
                                             style={{color: '#121212'}}>{data.action.action.users.data[id].name}</div>

                                    </div>

                                </div>
                                </div>

                            ))
                        }



                        <Handle type="target" position={Position.Left} id={id}/>
                        <Handle type="source" position={Position.Right} id={id}/>
                    </div>


                </div>}
            {data.type === "smart_delay" && <div key={`header_smart_delay_${id}`}>

                <div className="container">
                    <div className="custom-node__smart_delay" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius">
                    <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-time-five' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.message}</p>
                        </div>
                    </div>


                    <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>
                </div>


            </div>}
            {data.type && data.type === "finish" && <div key={`header_finish_${id}`}>
                <div className="container">
                    <div className="custom-node__close" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown" onClick={(e) => toggleDropdownBot(e.currentTarget)}
                                   aria-expanded="false">
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    {data.action.message === "" && <div className="internal-radius">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-conversation bx-flip-horizontal' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>Sem mensagem </p>
                        </div>
                    </div>}
                    {data.action.message !== "" && <div className="internal-radius">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-conversation bx-flip-horizontal' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.message}</p>
                        </div>
                    </div>}

                    <Handle type="target" position={Position.Left} id={id}/>

                </div>
            </div>}
            {data.type === "list" && <div key={`header_list_${id}`}>

                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}/>
                    <div className="custom-node__list" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.title}</p>
                        </div>
                    </div>

                    {data.action.action.description && <div className="internal-radius  mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.description}</p>
                        </div>
                    </div>}
                    {/* <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>*/}

                    {/*<div className="internal-radius  mb-3">
                        <div
                            onClick={toggleExpand} // Chamando a função toggleExpand
                            className="d-flex justify-content-center"
                            style={{paddingLeft: 5, alignItems: "center", cursor: "pointer"}}
                        >
                            <p style={{fontSize: 10, paddingLeft: 5}}>
                                {isExpanded ? "Fechar" : "Click Aqui"}
                            </p>
                        </div>
                    </div>*/}

                    {/*isExpanded && data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&*/}
                    {data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{paddingLeft: 5, width: "100%"}}>
                                    <p>Listagem</p>
                                    { data.action.action.selections.map((selection) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Selection key={selection.id} nodeId={id} title={selection.value} description={selection.description} handleId={selection.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>


            </div>}
            {data.type === "buttons" && <div key={`header_buttons_${id}`}>

                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}/>
                    <div className="custom-node__button" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.title}</p>
                        </div>
                    </div>

                    {data.action.action.description && <div className="internal-radius  mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.description}</p>
                        </div>
                    </div>}
                    {/* <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>*/}

                    {/*<div className="internal-radius  mb-3">
                        <div
                            onClick={toggleExpand} // Chamando a função toggleExpand
                            className="d-flex justify-content-center"
                            style={{paddingLeft: 5, alignItems: "center", cursor: "pointer"}}
                        >
                            <p style={{fontSize: 10, paddingLeft: 5}}>
                                {isExpanded ? "Fechar" : "Click Aqui"}
                            </p>
                        </div>
                    </div>*/}

                    {/*isExpanded && data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&*/}
                    {data.action.action.buttons && Object.keys(data.action.action.buttons).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{paddingLeft: 5, width: "100%"}}>
                                    <p>Butões</p>
                                    { data.action.action.buttons.map((button) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Buttons key={button.id} nodeId={id} title={button.label}  typeButton={button.type_button} info={button.info} handleId={button.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>


            </div>}
            {data.type === "webhook" && <div key={`header_webhook_${id}`}>

                <div className="container">

                    <div className="custom-node__webhook" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>Webhook</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>
                    <br/>
                    <div className="internal-radius mb-10">
                        {data.action.action.title !=="" && <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bxl-telegram' style={{color: '#121212'}}></i>
                            <p style={{
                                fontSize: 10, paddingLeft: 5, wordBreak: "break-word",
                                overflowWrap: "anywhere",
                                whiteSpace: "normal",
                            }}>{data.action.action.title}</p>
                        </div>}
                        {data.action.action.phone !=="" && <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bxl-telegram' style={{color: '#121212'}}></i>
                            <p style={{
                                fontSize: 10, paddingLeft: 5, wordBreak: "break-word",
                                overflowWrap: "anywhere",
                                whiteSpace: "normal",
                            }}>{formatPhoneNumber(data.action.action.phone)}</p>
                        </div>}
                    </div>


                    {/*<div className="internal-radius  mb-3">
                        <div
                            onClick={toggleExpand} // Chamando a função toggleExpand
                            className="d-flex justify-content-center"
                            style={{paddingLeft: 5, alignItems: "center", cursor: "pointer"}}
                        >
                            <p style={{fontSize: 10, paddingLeft: 5}}>
                                {isExpanded ? "Fechar" : "Click Aqui"}
                            </p>
                        </div>
                    </div>*/}

                    {/*isExpanded && data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&*/}
                    {data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{paddingLeft: 5, width: "100%"}}>
                                    <p>atributos</p>
                                    { data.action.action.selections.map((attribute) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Attributes key={attribute.id} nodeId={id} title={attribute.value} description={attribute.description} handleId={attribute.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>


            </div>}
            {data.type === "order_note" && <div key={`header_order_note_${id}`}>

                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}/>
                    <div className="custom-node__list" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.title}</p>
                        </div>
                    </div>

                    {data.action.action.description && <div className="internal-radius  mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.description}</p>
                        </div>
                    </div>}
                    {/* <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>*/}

                    {/*<div className="internal-radius  mb-3">
                        <div
                            onClick={toggleExpand} // Chamando a função toggleExpand
                            className="d-flex justify-content-center"
                            style={{paddingLeft: 5, alignItems: "center", cursor: "pointer"}}
                        >
                            <p style={{fontSize: 10, paddingLeft: 5}}>
                                {isExpanded ? "Fechar" : "Click Aqui"}
                            </p>
                        </div>
                    </div>*/}

                    {/*isExpanded && data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&*/}
                    {data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{paddingLeft: 5, width: "100%"}}>
                                    <p>Listagem</p>
                                    { data.action.action.selections.map((selection) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Selection key={selection.id} nodeId={id} title={selection.value} description={selection.description} handleId={selection.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>


            </div>}
            {data.type === "calendar" && <div key={`header_calendar_${id}`}>

                <div className="container">
                    <Handle type="target" position={Position.Top} id={id}/>
                    <div className="custom-node__list" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{data.action.title}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.title}</p>
                        </div>
                    </div>

                    {data.action.action.description && <div className="internal-radius  mb-10">
                        <div className="d-flex justify-content-start" style={{paddingLeft: 5, alignItems: "center"}}>
                            <i className='bx bx-message-square-dots' style={{color: '#121212'}}></i>
                            <p style={{fontSize: 10, paddingLeft: 5}}>{data.action.action.description}</p>
                        </div>
                    </div>}
                    {/* <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>*/}

                    {/*<div className="internal-radius  mb-3">
                        <div
                            onClick={toggleExpand} // Chamando a função toggleExpand
                            className="d-flex justify-content-center"
                            style={{paddingLeft: 5, alignItems: "center", cursor: "pointer"}}
                        >
                            <p style={{fontSize: 10, paddingLeft: 5}}>
                                {isExpanded ? "Fechar" : "Click Aqui"}
                            </p>
                        </div>
                    </div>*/}

                    {/*isExpanded && data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&*/}
                    {data.action.action.selections && Object.keys(data.action.action.selections).length > 0 &&
                        <div className="internal-radius">
                            <div className="d-flex justify-content-start"
                                 style={{paddingLeft: 5, alignItems: "center"}}>

                                <i className='bx bx-check-square bx-flip-horizontal' style={{color: '#121212'}}></i>
                                <div style={{paddingLeft: 5, width: "100%"}}>
                                    <p>Listagem</p>
                                    { data.action.action.selections.map((selection) => (
                                        <div className="internal-radius mb-3" style={{backgroundColor: "white"}}>
                                            <Selection key={selection.id} nodeId={id} title={selection.value} description={selection.description} handleId={selection.id}/>

                                        </div>

                                    ))}
                                </div>

                            </div>
                        </div>
                    }

                </div>


            </div>}
            {data.type === "input" && <div key={`header_input_${id}`}>

                <div className="container">
                    <div className="custom-node__input" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                        <div className="d-flex justify-content-between">
                            <p style={{fontSize: 10, color: "white"}}>{"Coletar de Dados"}</p>
                            <div className="dropdown"
                                 style={{display: "flex", justifyContent: "center", alignItems: "center"}}>

                                <a href="#" style={{color: "#fffff"}} data-bs-toggle="dropdown"
                                   aria-expanded="false" onClick={(e) => toggleDropdownBot(e.currentTarget)}>
                                    <i className='bx bx-edit'></i>

                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDuplicate && data.onClickDuplicate(data)}><i
                                        className='bx bx-duplicate'></i>Duplicar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickEdit && data.onClickEdit(data)}><i
                                        className='bx  bx-edit-alt'></i> Editar</a>
                                    <a className="dropdown-item" href="#"
                                       onClick={() => data.onClickDelete && data.onClickDelete(data)}><i
                                        className='bx  bx-trash'></i> Excluir</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="internal-radius mb-10">
                        <Collection nodeId={id} handleId={id} inputSelected={data.action.action.inputSelected} inputTextSelected={data.action.action.inputTextSelected} ></Collection>
                    </div>

                    <Handle type="target" position={Position.Left} id={id}/>
                    <Handle type="source" position={Position.Right} id={id}/>




                </div>


            </div>}

            {(data.type === "checkContact") && <div className="custom-node__body">
                <div>
                    <div className="custom-node__message">
                        <p>Ação</p>
                        <p>{data.action.message}</p>
                        {data.action.action.type_redirect === 'DEPARTMENT' && data.action.action.user_name &&
                            <p>Usuário - {data.action.action.user_name}</p>}

                        <Handle type="source" position={Position.Right} id={id}/>
                    </div>

                </div>
            </div>}


        </>
    );
}

export default memo(FormComponent);
