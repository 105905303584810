import React, { useState, useEffect } from 'react';
import "./style/kanban.css.scss";
import styled from 'styled-components';

const styles = {
    addCardButton: {
        backgroundColor: "#a10889",
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        padding: '8px 12px',
        cursor: 'pointer',
        fontSize: '14px',
        margin: '10px 0',
    }
};
const CustomAddCardLink = ({ onClick }) => {
    return (
        <div style={{display: 'none', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
            <button onClick={onClick} style={styles.addCardButton}>
                + Adicionar novo card
            </button>
        </div>
    );
};
export default CustomAddCardLink