import React from "react";

class ContactList  extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }
    componentDidMount() {}
    componentWillUnmount() {}
    render() {
        return (
            <div>
                <p> teste</p>
            </div>);
    }

}
export default ContactList;