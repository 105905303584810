import React from "react";
import axios from 'axios';
import InputMask from "react-input-mask";
import { NumericFormat } from 'react-number-format';
import Loader from "../Loader";
export default class  EditBasicInfoChatContact extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            selectedOptionId: 0,
            selectedOptionDepartment: '',
            selectedOptionUser:'',
            selectedOptionTicket:'',
            openOptionsSelectedUser: false,
            openOptionsSelectedProcessStatus: false,
            selectedProcessStatus: '',
            selectedQueryType: '',
            queryType: [],
            users: [],
            operationalUsers: [],
            attorneyUsers: [],
            isChecked: false,
            isLoading: false,
            tagSelected: '',
            interest: '',
            numProcess: '',
            shareValue: 0,
            email: '',
            name: ''
        };
    }
    handleInputChangeEmail = (event) => {
        this.setState({ email: event.target.value });
    }
    handleInputChangeName = (event) => {
        this.setState({ name: event.target.value });
    }
    handleInputChangeShareValue = (values) => {
        const { floatValue } = values;
        this.setState({ shareValue: floatValue });
    };
    handleChangeInterest = (e) => {
        const {  value } = e.target;
        this.setState({interest: value})

    };
    handleChangeNumProcess = (e) => {
        const {  value } = e.target;
        this.setState({numProcess: value})

    };
    handleChangeTag = (e) => {
        const {  value } = e.target;
        this.setState({tagSelected: value})

    };
    handleSubmit =  (id) => {
        const {selectedOptionUser }  = this.state;
        const formData = new FormData();
        formData.append('user',selectedOptionUser);
        formData.append('chat_id',id);

        fetch('/messagings/user_from_chat', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {



        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });



    }
    handleFormSubmitBasicInfo = (event) => {
        event.preventDefault();
        const {
            email,
            name,
            tagSelected,
            interest,
            numProcess,
            shareValue,
        } = this.state;
        this.setState({ isLoading:   true});
        const {infoContact, currentCompany, tags}  = this.props;

        infoContact.email = email ||  infoContact.email
        infoContact.name = name ||  infoContact.name
        infoContact.title = name ||  infoContact.title


        if(currentCompany.type_company === "advocacia"){

            infoContact.insterest = interest ||  infoContact.insterest
            infoContact.numProcess = numProcess ||  infoContact.numProcess
            infoContact.shareValue = shareValue || infoContact.shareValue
            if ( tags[tagSelected]){
                infoContact.contactTag = tags[tagSelected]?.title || infoContact.contactTag
            }

        }


        const formData = new FormData();
        formData.append('id', infoContact.contactId);
        formData.append('email', email);
        formData.append('name', name);
        formData.append('insterest', interest);
        formData.append('numProcess', numProcess);
        formData.append('shareValue', shareValue);
        formData.append('tagSelected', tagSelected);


        fetch('/contacts/edit', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao editar contact.');
            }
            return response.json();
        }).then(data => {
            this.props.fetchGetChats();
            let interval = setInterval(() => {
                this.stopLoader();
                clearInterval(interval);
            }, 10000);

        }).catch(error => {
                console.error('Erro ao editar contact:', error);});






    }
    stopLoader = () => {
        this.setState({ isLoading:   false})
        this.props.onCloseEditMoreInfo("editBasicInfo", "editMoreInfo", "basicInfo");
    }
    componentDidMount() {
        const {infoContact}  = this.props;

        if(infoContact.email !== "Não cadastrado"){
            this.setState({ email:   infoContact.email || ''});
        }

        this.setState({ name:   infoContact.name || ''});
        this.setState({ interest:   infoContact.insterest || ''});
        this.setState({ numProcess:   infoContact.numProcess || ''});
        this.setState({ tagSelected:   infoContact.contactTag || ''});
        this.setState({ shareValue:   infoContact.shareValue || 0});


    }
    componentWillUnmount() {

    }
    render() {


        const {
            tagSelected,
            interest,
            numProcess,
            shareValue,
            email,
            name,
            isLoading
        } = this.state;
        const {
            infoContact,
            instance_id,
            tickets,
            myService,
            user_role,
            onMyService,
            selectedChatInfo,
            departments,
            process_status,
            query_types_new_process,
            query_types_in_progress,
            query_types_in_close,
            tags,
            currentCompany,
            onCloseEditMoreInfo,
            lawyers,
            loader
        } = this.props;




        return (<div>
            <form className="col-xl-12 mt-20" action="#" onSubmit={this.handleFormSubmitBasicInfo}
                  method="post" acceptCharset="utf-8">

                <div className="row mt-2">
                    <div className="col-sm-12">
                        <div className="email-general">
                            <h6 className="mb-3 text-info">Editar Informações básicas</h6>
                            {isLoading ? <Loader src={loader} className= "loader-edit"/> :

                                <>
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Nome:</label>
                                            <input type="name" onChange={this.handleInputChangeName}
                                                   value={name} className="form-control string  optional"

                                                   id="edit_contact_name" name="edit_contact_name"/>

                                        </div>
                                    </div>
                                    <div className="mb-4 mt-24 ">
                                        <div className="form-group">
                                            <label>Email:</label>
                                            <input type="email" onChange={this.handleInputChangeEmail}
                                                   value={email} className="form-control string email optional"
                                                   placeholder="email@email.com"
                                                   id="edit_contact_email" name="edit_contact_email"/>

                                        </div>
                                    </div>


                                    {currentCompany.type_company === "advocacia" && <div className="form-group">

                                        <div className="mb-10 col-sm-12">
                                            <label>Agrupamento</label>
                                            <select className="form-control"
                                                    onChange={this.handleChangeTag}>
                                                <option
                                                    defaultValue> {tags[tagSelected]?.title || "selecione qual Agrupamento deseja associar"}</option>

                                                {Object.values(tags).map(tag => (

                                                    <option
                                                        key={tag.id}
                                                        value={tag.id}>
                                                        {tag.title}
                                                    </option>
                                                ))}

                                            </select>
                                        </div>
                                        <div className="mb-10 col-md-12">
                                            <label>Interresse</label>
                                            <select
                                                id="opt_interest"
                                                name="opt_interest"
                                                className="form-control"
                                                onChange={this.handleChangeInterest}
                                            >


                                                <option
                                                    defaultValue> {lawyers[interest.toLowerCase()] || "Selecione o Interresse"}</option>
                                                <option value="labor_lawyer">Advogado Trabalhista</option>
                                                <option value="civil_lawyer">Advogado Civil</option>
                                                <option value="criminal_lawyer">Advogado Criminal</option>
                                                <option value="family_lawyer">Advogado de Família</option>
                                                <option value="tax_lawyer">Advogado Tributarista</option>
                                                <option value="environmental_lawyer">Advogado Ambiental</option>
                                                <option value="business_lawyer">Advogado Empresarial</option>
                                                <option value="real_estate_lawyer">Advogado Imobiliário</option>
                                                <option value="social_security_lawyer">Advogado Previdenciário
                                                </option>
                                                <option value="consumer_lawyer">Advogado do Consumidor</option>
                                                <option value="international_lawyer">Advogado Internacional</option>
                                                <option value="intellectual_property_lawyer">Advogado de Propriedade
                                                    Intelectual
                                                </option>
                                                <option value="military_lawyer">Advogado Militar</option>
                                                <option value="administrative_lawyer">Advogado Administrativo
                                                </option>
                                                <option value="human_rights_lawyer">Advogado de Direitos Humanos
                                                </option>

                                            </select>
                                        </div>
                                        <div className="mb-10 col-md-12">
                                            <label>Número do Processo</label>
                                            <div className="row">
                                                <div
                                                    className="col-sm-12">
                                                    <InputMask

                                                        onChange={this.handleChangeNumProcess}
                                                        mask="9999999-99.9999.9.99.9999"
                                                        maskChar="_"
                                                        className="form-control"
                                                        id="num_Process"
                                                        name="num_Process"
                                                        type="text"
                                                        value={numProcess}
                                                        placeholder="Por favor, digite o número do Processo"
                                                        title="Por favor, digite o número do Processo."
                                                        autoComplete="off"
                                                        data-bs-original-title=""
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                        <div className="mb-10 col-md-12">
                                            <label>Valor da Ação</label>
                                            <div className="row">
                                                <div
                                                    className="col-sm-12">
                                                    <NumericFormat
                                                        value={shareValue}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        prefix="R$ "
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                        allowNegative={false}
                                                        onValueChange={this.handleInputChangeShareValue}
                                                        className="form-control"
                                                        id="share_value"
                                                        name="share_value"
                                                        placeholder="Por favor, digite o valor"
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>}


                                    <div>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <button
                                                style={{marginRight: 20}}
                                                className="btn btn-secondary update-contact"
                                                type="submit"
                                                data-bs-original-title=""
                                                title="">Salvar
                                            </button>
                                            <button
                                                className="btn btn-primary"
                                                type="button"
                                                onClick={() => onCloseEditMoreInfo("editBasicInfo", "editMoreInfo", "basicInfo")}
                                                title="">Cancelar
                                            </button>
                                        </div>

                                    </div>
                                </>
                            }


                        </div>
                    </div>
                </div>


            </form>
            </div>
        );
    }
}