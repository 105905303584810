import React from 'react';

function ImageChoiceNode({ data }) {
    return (
        <div className="input-node">
            <label>{data.label || "Escolha de Imagem"}</label>
            <input type="file" accept="image/*" />
        </div>
    );
}
export default ImageChoiceNode;