import React, { useState } from 'react';

const Pagination = ({ contactsCountAll, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
    };

    const renderPaginationItems = () => {
        const currentPageIndex = currentPage - 1;
        const pagesToShow = 5; // Define quantas páginas serão exibidas por vez

        const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
        const endPage = Math.min(contactsCountAll, startPage + pagesToShow - 1);

        const pageItems = [];
        for (let i = startPage; i <= endPage; i++) {
            pageItems.push(
                <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(i)}>{i}</button>
                </li>
            );
        }

        return pageItems;
    };

    return (
        <nav>
            <ul className="pagination">
                <li key="page-previous" className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage - 1)}>&laquo;</button>
                </li>
                {renderPaginationItems()}
                <li key="page-next" className={`page-item ${currentPage === contactsCountAll ? 'disabled' : ''}`}>
                    <button className="page-link" onClick={() => handlePageChange(currentPage + 1)}>&raquo;</button>
                </li>
            </ul>
        </nav>
    );
};

export default Pagination;