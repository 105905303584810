import React, { useState, useEffect } from 'react';
import "./style/kanban.css.scss";


const CustomNewCardForm = ({ laneId, onCancel, onAdd }) => {
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAdd({ title, description });  // Passa os dados do card para a função de adicionar
        setTitle('');  // Reseta os campos
        setDescription('');
    };

    return (
        <form
            onSubmit={handleSubmit}
            style={{
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '8px',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <label style={{ marginBottom: '10px', fontWeight: 'bold' }}>Título do Card</label>
            <input
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                style={{
                    padding: '10px',
                    marginBottom: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                }}
                required
            />

            <label style={{ marginBottom: '10px', fontWeight: 'bold' }}>Descrição</label>
            <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={{
                    padding: '10px',
                    borderRadius: '5px',
                    border: '1px solid #ddd',
                    marginBottom: '20px',
                }}
                rows="3"
            />

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button
                    type="button"
                    onClick={onCancel}  // Botão para cancelar a criação
                    style={{
                        padding: '10px 20px',
                        marginRight: '10px',
                        backgroundColor: '#f0f0f0',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Cancelar
                </button>

                <button
                    type="submit"
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    Adicionar Card
                </button>
            </div>
        </form>
    );
};
export default CustomNewCardForm;