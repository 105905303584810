import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const phrases = [
    "Atenda seus clientes diretamente pelo WhatsApp com nossa plataforma",
    "Gerencie suas conversas no WhatsApp de forma eficiente e integrada",
    "Melhore o atendimento ao cliente com nossa integração com a API do WhatsApp",
    "Centralize todas as suas conversas do WhatsApp em um só lugar",
    "Facilite a comunicação com seus clientes usando nossa integração com o WhatsApp",
    "Transforme seu atendimento ao cliente com a API do WhatsApp integrada",
    "Converse com seus clientes via WhatsApp, tudo em uma única plataforma",
    "Aproveite a integração com o WhatsApp para um atendimento mais rápido e eficiente",
    "Nossa plataforma torna as conversas do WhatsApp mais gerenciáveis e organizadas",
    "Conecte-se com seus clientes no WhatsApp sem sair da nossa plataforma"
];

const CarouselComponent = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    return (
        <div style={{ width: 580,height:80, margin: '0 auto', textAlign: 'center' }}>
            <Slider {...settings}>
                {phrases.map((phrase, index) => (
                    <div key={index}>
                        <p>{phrase}</p>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default CarouselComponent;