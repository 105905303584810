import React from "react";
import ActionCable from 'actioncable';
import axios from 'axios';
import "../style/slick.css";
import "../style/slick-theme.css";
import "../style/conversa.css";
import "../style/scrollbar.css";
import "../style/emoji/uikit.min.css";
import CarouselComponent from './CarouselComponent';
import Chat from './Chat';
import Chats from './Chats';
import NewChats from "./NewChats";
import AllChats from "./AllChats";
import ImageWithFallback from "../ImageWithFallback";
class ChatRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            initilMessages: {},
            selectedChat: null,
            myService: false,
            selectedInfoContact: null,
            selectInputSearch: false,
            chatValidate: true,
            isLoading: true,
            currentMessageId: '',
            currentChat: null,
            messagesUnread: 0,
            messagesUnreadAllChats: 0,
            messagesUnreadChatsWaiting: 0,

            currentSelectChatType: (this.props.user_role === "central"  || this.props.user_role === "support" || this.props.user_role === "operational") ? "new-chats" : "chats",
            chats:[],
            my_chats:[],
            waitingForServiceChats:[],
            selectedChatInfo:{
                ticket:[],
                selectedChat: null
            }
        };
    }

    backGroundStyle = {
        height: 770,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: `url(${this.props.login_bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    };
    handleChatSelect = (chat) => {
        const{initilMessages} = this.state
        this.setState({isLoading: true});
        this.setState({ selectedChat: chat });

        this.setState({messages: initilMessages[chat.id]});
        this.setState(prevState => ({
            selectedChatInfo: {
                ...prevState.selectedChatInfo,
                [chat]: chat
            }
        }));
    }
    isLoadingUp = (isLoading) => {
        this.setState({isLoading: isLoading});
    }
    changeChat = (chat) => {
        this.setState({currentChat: chat});
    }
    changeChatMessages = (messages) => {
        this.setState({messages: messages});
    }
    lastMessageChat = (lastTypeMessage, lastMessage) => {

        if (lastTypeMessage === "conversation" || lastTypeMessage === "extendedTextMessage" ){
            return lastMessage
        }else{
            if (lastTypeMessage === "videoMessage"  ){
                return "Video"
            }else{
                if (lastTypeMessage === "audioMessage"  ){
                    return "Audio"
                }else{
                    if (lastTypeMessage === "documentMessage"  ){
                        return "Arquivo"
                    }else{
                        if (lastTypeMessage === "stickerMessage"  ){
                            return "Sticker"
                        }
                    }
                }
            }
        }
    }
    changeCurrentChatMessages = (messages, currentChat) => {
        this.setState(prevState => {
            const chatId = currentChat.id;

            // Verifica se initialMessages está definido, se não, inicializa como um objeto vazio.
            const initialMessages = prevState.initialMessages || {};

            // Verifica se o array de mensagens para o chat atual está definido, se não, inicializa como um array vazio.


            return {
                initialMessages: {
                    ...initialMessages,
                    [chatId]: [messages],
                },
            };
        });
    }
    changeChatSendMessages = (messages) => {
        this.setState(prevState => ({
            messages: [...prevState.messages, messages],
        }));

    }

    changeSelectChatType = (typeChat) => {
        this.setState({currentSelectChatType: typeChat});
    }
    setActive = (id, typeChat) => {

        const items = document.querySelectorAll('.menu-item');
        const classesToRemove = ['active', 'show'];
        classesToRemove.forEach((className) => {
            const elements = document.querySelectorAll(`.${className}`);
            elements.forEach((el) => {
                el.classList.remove(className);
            });
        });
        const targetElement = document.getElementById(typeChat);
        if (targetElement) {
            targetElement.classList.add('active', 'show');
        } else {
            console.warn(`Elemento com ID "${typeChat}" não encontrado.`);
        }
        items.forEach(item => {
            item.classList.remove('active');
        });
        document.getElementById(id).classList.add('active');




        this.setState({currentSelectChatType: typeChat});
    }


    handleSendSearch = (chats, chatTypeSearch)=> {
        if(chatTypeSearch === "newChat"){
            this.setState({ waitingForServiceChats: chats });
        }else{
            if(chatTypeSearch ==="myChat"){
                this.setState({ my_chats: chats });
            }else{
                if(chatTypeSearch ==="allChat"){
                    this.setState({ chats: chats });
                }
            }
        }


    }
    handleSelectSendSearch = (selectInputSearch)=> {
        this.setState({ selectInputSearch: selectInputSearch });
    }
    handleMyService= (myService)=> {
        this.setState({ myService: myService });
    }
    handleChatValidate= (chatValidate)=> {
        this.setState({ chatValidate: chatValidate });
    }
    handleSelectedInfoContact= (selectedInfoContact)=> {
        this.setState({ selectedInfoContact: selectedInfoContact });
    }
    updateChats = (chats, chat_type) => {
        if(chat_type === "newChat"){
            this.setState({ waitingForServiceChats: chats });
        }else{
            if(chat_type === "myChat"){
                this.setState({ my_chats: chats });
            }else{
                if(chat_type === "allChat"){
                    this.setState({ chats: chats });
                }
            }
        }


    }
    async fetchGetMessagesAllChats() {

        let response = await axios.get('/messagings/get_messages_all_chats.json', {
            params: {
                instance_id: this.props.instance_id,
            }
        });


        this.setState({initilMessages: response.data.messages})
    }
    fetchInstanceStatus = async () => {
        let response = await axios.get('/messagings/validate_phone_state.json')
        if(response.data.instanceStatus === "close"){
            window.location.reload();
        }
    }
    fetchTenants  = () => {
        return  axios.get('/messagings/fetch_tenant.json')
    }
    fetchChats  = async () => {

        let self = this;
        console.log(" chamou fetchChats")

        let response = await axios.get('/messagings/get_chat_list.json', {
            params: {
                instance_id: self.props.instance_id,
                current_select_chat_type: self.state.currentSelectChatType,
                type: "get_all_chat_list"
            }
        });

        console.log("instanceStatus:",response.data.instanceStatus )

        if(response.data.instanceStatus === "close"){

            window.location.reload();
        }else{

            this.setState({chats: response.data.chats, messagesUnread: response.data.messagesUnread, waitingForServiceChats:response.data.chats_waiting, messagesUnreadChatsWaiting:  response.data.messagesUnreadChatsWaiting, messagesUnreadAllChats: response.data.messagesUnreadAllChats, my_chats:response.data.my_chats, initilMessages: response.data.messages })
        }


    }
    readMessage = async () => {
        let self = this;
        await axios.post('/messagings/read_message', {
            params: {
                chat_id: self.state.selectedChat.id
            }
        });
        this.fetchChats();

    }
    handleLogoutClick= ()=> {
        let result =  axios.post(`/messagings/logout.json`, {
            instance_id: this.props.instance_id
        });
        window.location.reload()
    }
    componentDidMount() {
        let self = this;
        this.fetchChats(); // Carrega as mensagens iniciais
        const cable = ActionCable.createConsumer('/cable');

        // Subscribing to Chat Channel
       cable.subscriptions.create("MessageNotificationChannel", {
            connected: () => {
                console.log('Connected to MessageNotificationChannel - CHATROOM');
            },
            received: (data) => {

                this.fetchChats();


            },
        });

        this.fetchInstanceStatus(); // Carrega o status iniciais
        this.interval = setInterval(() => this.fetchInstanceStatus(), 150000);



    }

    componentWillUnmount() {

       // if (this.chatRoomChannel) {
         //   this.chatRoomChannel.unsubscribe();
        //}
        clearInterval(this.interval); // Limpe o intervalo quando o componente for desmontado


    }
    render() {

        const {selectedChat, initilMessages, chats, selectInputSearch,messagesUnread,messagesUnreadChatsWaiting, waitingForServiceChats, messagesUnreadAllChats , chatValidate, my_chats , selectedChatInfo, isLoading, currentChat,messages, currentSelectChatType} = this.state;
        const {process_status, query_types_new_process,query_types_in_progress,query_types_in_close,current_user_name,current_user_id, user_role, image_avatar, loader, instance_id, lawyers, login_bg,login, currentCompany, departments,tickets,profile_picture } = this.props;


        return (
            <div className="container-fluid">
                <div className="row g-0">
                    <div className="col-xxl-3 col-xl-4 col-md-5 box-col-5">
                        <div className="left-sidebar-wrapper card">
                            <div className="left-sidebar-chat">

                            </div>
                            <div className="advance-options">

                                <div className="menu-container">
                                    {(user_role === "central" || user_role === "support" || user_role === "operational" )  &&
                                        <div id="novo"  className={`menu-item  ${this.props.select_chat_phonebook_id === 0  ? ' active' : ''}`}
                                             onClick={() => this.setActive("novo", "new-chats")}>

                                            <i className="bx bx-navigation"></i>
                                            <span>Novo</span>
                                            <div className="notification-badge">{messagesUnreadChatsWaiting}</div>
                                        </div>
                                    }
                                    {(user_role !== "central" && user_role !== "support") &&
                                        <div id="meus"
                                             className={`menu-item  ${user_role === "attorney"  ? ' active' : ''}`}
                                             onClick={() => this.setActive("meus", "chats")}>

                                            <i className="bx bx-user-pin"></i>
                                            <span>Meus</span>
                                            <div className="notification-badge">{messagesUnread}</div>
                                        </div>
                                    }

                                    {(user_role === "central" || user_role === "support") &&
                                        <div id="todos"
                                             className={`menu-item  ${(this.props.select_chat_phonebook_id !== 0) ? ' active' : ''}`}
                                             onClick={() => this.setActive("todos", "all-chats")}>

                                            <i className="bx bx-chat"></i>
                                            <span>Todos</span>
                                            <div className="notification-badge">{messagesUnreadAllChats}</div>
                                        </div>
                                    }

                                </div>
                                <div className="tab-content" id="chat-options-tabContent">
                                    <div
                                        className={`tab-pane fade ${(user_role === "central" || user_role === "support" || user_role === "operational" || currentSelectChatType === 'new-chats') ? 'active show' : ''}`}
                                        id="new-chats" role="tabpanel"
                                        aria-labelledby="new-chats-tab">
                                        <NewChats
                                            chats={waitingForServiceChats}
                                            selectChatPhonebook={this.props.select_chat_phonebook_id}
                                            messagesUnread={messagesUnread}
                                            image_avatar={image_avatar}
                                            instance_id={instance_id}
                                            loader={loader}
                                            lastMessageChat={this.lastMessageChat}
                                            selectInputSearch={selectInputSearch}
                                            updateChats={this.updateChats}
                                            onChatSelect={this.handleChatSelect}
                                            onSendSearch={this.handleSendSearch}
                                            onSelectSendSearch={this.handleSelectSendSearch}/>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${(user_role === "attorney"  ||  currentSelectChatType === 'chats') ? ' active show' : ''}`}
                                        id="chats" role="tabpanel"
                                        aria-labelledby="chats-tab">
                                        <Chats
                                            chats={my_chats}
                                            selectChatPhonebook={this.props.select_chat_phonebook_id}
                                            messagesUnread={messagesUnread}
                                            image_avatar={image_avatar}
                                            instance_id={instance_id}
                                            loader={loader}
                                            lastMessageChat={this.lastMessageChat}
                                            selectInputSearch={selectInputSearch}
                                            updateChats={this.updateChats}
                                            onChatSelect={this.handleChatSelect}
                                            onSendSearch={this.handleSendSearch}
                                            onSelectSendSearch={this.handleSelectSendSearch}
                                        />
                                    </div>
                                    <div  className={`tab-pane fade ${ currentSelectChatType === 'all-chats' ? 'active show' : ''}`}  id="all-chats" role="tabpanel"
                                         aria-labelledby="all-chats-tab">
                                        <AllChats
                                            chats={chats}
                                            image_avatar={image_avatar}
                                            selectChatPhonebook={this.props.select_chat_phonebook_id}
                                            messagesUnread={messagesUnread}
                                            instance_id={instance_id}
                                            loader={loader}
                                            lastMessageChat={this.lastMessageChat}
                                            selectInputSearch={selectInputSearch}
                                            updateChats={this.updateChats}
                                            onChatSelect={this.handleChatSelect}
                                            onSendSearch={this.handleSendSearch}
                                            onSelectSendSearch={this.handleSelectSendSearch}
                                        />
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="col-xxl-9 col-xl-8 col-md-7 box-col-7">

                        {selectedChat && chatValidate &&

                            <Chat
                                    chats={chats}
                                    selectedChat={selectedChat}
                                    selectedChatInfo={selectedChatInfo}
                                    image_avatar={image_avatar}
                                    loader={loader}
                                    user_role={user_role}
                                    isLoading={isLoading}
                                    lawyers={lawyers}
                                    messages={messages}
                                    changeCurrentChatMessages={this.changeCurrentChatMessages}
                                    initilMessages={initilMessages}
                                    changeChatMessages={this.changeChatMessages}
                                    changeChatSendMessages={this.changeChatSendMessages}
                                    profile_picture={profile_picture}
                                    currentCompany={currentCompany}
                                    currentChat={currentChat}
                                    changeChat={this.changeChat}
                                    readMessage={this.readMessage}
                                    isLoadingUp={this.isLoadingUp}
                                    fetchGetChats={this.fetchChats}
                                    image_pdf_url={this.props.image_pdf_url}
                                    image_doc_url={this.props.image_doc_url}
                                    image_video_url={this.props.image_video_url}
                                    image_mp3_url={this.props.image_mp3_url}
                                    image_audio_url={this.props.image_audio_url}
                                    image_image_url={this.props.image_image_url}

                                    process_status={process_status}
                                    query_types_new_process={query_types_new_process}
                                    query_types_in_progress={query_types_in_progress}
                                    query_types_in_close={query_types_in_close}
                                    current_user_name={current_user_name}
                                    current_user_id={current_user_id}
                                    chatValidate={chatValidate}
                                    departments={departments}
                                    tickets={tickets}
                                    onChatValidate={this.handleChatValidate}
                                    selectChatPhonebook={this.props.select_chat_phonebook_id}
                                    onMyService={this.handleMyService}
                                    onSelectedInfoContact={this.handleSelectedInfoContact}
                                    />
                            }
                        {!selectedChat && <>
                            <div className="card right-sidebar-chat">

                                <div className="right-sidebar-Chats">
                                    <div className="msger">

                                        <div style={this.backGroundStyle}>
                                            <div className="center-block">
                                                <div style={{ textAlign: 'center' }}>
                                                    <img style={{height: 60, width: 260}} src={login} alt="Login"/>
                                                    <CarouselComponent />
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </>}


                    </div>
                </div>
            </div>
        );
    }
}

export default ChatRoom;