import React, {useState, useCallback, useEffect} from "react";
import ReactFlow, {
    ReactFlowProvider,
    useNodesState,
    addEdge,
    MiniMap,
    Controls,
    Background,
    useReactFlow,
    MarkerType,
    useEdgesState
} from "react-flow-renderer";


import FormComponent from './FormComponent';
import 'react-flow-renderer/dist/style.css';
import Loader from "../Loader";



const minimapStyle = {
    height: 120,
};
const nodeTypes = {
    custom: FormComponent,
};
function FlowChatBot({nodes, edges, setEdges, onNodesChange,onEdgesChange, setNodeEdit, onSave, setOnSave, setClickInNode, bot_id, bot_name,type_bot, instance_id, loader, inputNodeTypes }) {

    const onConnect = useCallback((params) => {
        // Verifica se o handle de origem já tem uma conexão de saída
        const isAlreadyConnected = edges.some(
            (edge) => edge.source === params.source && edge.sourceHandle === params.sourceHandle
        );

        if (isAlreadyConnected) {
            console.log('Esse handle de saída já está conectado.');
        } else {
            // Permite a conexão se o handle ainda não estiver conectado
            setEdges((eds) => addEdge(params, eds));
        }
    }, [edges]);

    const [modalOpen, setModalOpen] = useState(false);

    const edgesWithUpdatedTypes = edges.map((edge) => {
        const sourceNode = nodes.find((node) => node.id === edge.source);
        const targetNode = nodes.find((node) => node.id === edge.target);

        if (sourceNode && sourceNode.type === 'custom' && targetNode && targetNode.type === 'custom') {
            const edgeType = sourceNode.data.options[edge.sourceHandle];
            edge.type = edgeType;
        }

        return edge;
    });
    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleElementClick = (event, element) => {

        if(element.id !== "1"){
            setClickInNode(true)
            const node_aux = {
                id:element.id,
                type: 'custom',
                data: element.data,
                position: element.position
            };

            setNodeEdit(node_aux);
        }


    };
    const loading = () =>{

        document.getElementById('loading').value = '';

        document.getElementById("loading").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    }
    const closeModal = (id) => {
        document.getElementById(id).style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    }
    const onSaveNodesEdges = (nodes,edges, type) => {

        const nodesString = JSON.stringify(nodes);
        const edgesString = JSON.stringify(edges);
        const formData = new FormData();

        formData.append('nodes', nodesString);
        formData.append('edges', edgesString);
        formData.append('bot_id', bot_id);
        formData.append('type', type);
        formData.append('instance_id', instance_id);
        formData.append('published', !onSave);

        fetch('/bots/save_bot', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar data para o servidor.');
            }
            return response.json();
        }).then(data => {



            {/*document.getElementById('save_bot').value = '';
            document.getElementById('public_bot').value = '';

            document.getElementById("save_bot").style.display = "none";
            document.getElementById("public_bot").style.display = "none";

            var elementos = document.querySelectorAll(".modal-backdrop");

            elementos.forEach(function(elemento) {
                elemento.classList.remove("modal-backdrop");
            });

           document.getElementById("loading").style.display = "block";


            setInterval(() =>   loading(), 15000);


            if(type === "published"){
                setOnSave(!onSave)
            }*/}
            window.location.reload();


        })
            .catch(error => {
                console.error('Erro ao enviar data:', error);
            });
    }
    const handleEdgeClick= (event) => {
        setEdges((eds) => eds.filter((e) => e.id !== event[0].id));

    }

    const handleContainerClick = (event) => {
        // Verifica se o alvo do clique não é um nó
        if (!event.target.closest(".react-flow__node")) {
            setClickInNode(false)

        }
    };



    return (
        <div>
            <div className="save-post">
                <div className="card-options">
                    <div className="btn-list d-flex ">

                        <a data-toggle="modal" data-target="#save_bot"
                           className="btn btn-primary d-flex align-items-center mr-5">Salvar</a>
                        {onSave === true && <a data-toggle="modal"
                                               data-target="#public_bot"
                                               className={`btn btn-secondary d-flex align-items-center mr-5`}>Despublicar</a>}

                        {onSave === false && <a data-toggle="modal"
                                                data-target="#public_bot"
                                                className={`btn btn-success d-flex align-items-center mr-5`}>Publicar</a>
                        }


                    </div>
                </div>
            </div>

            <div className="modal custom-modal fade" style={{top: -255}} id="public_bot"
                 tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> {onSave === true ? 'Despublicar' : 'Publicar'}</h5>
                            <button
                                className="btn-close"
                                type="button"
                                onClick={(e) => closeModal("public_bot")}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-original-title=""
                                title=""></button>
                        </div>

                        <div className="modal-body list-persons">
                            <div className="d-flex align-items-center justify-content-center">
                                <p>Tem certeza de que deseja publicar <span>{bot_name}</span>?</p>
                            </div>

                            <div className="mt-10 ps-0">
                            <div
                                    className="d-flex align-items-center justify-content-center">

                                    <button
                                        style={{marginRight: 20}}
                                        className="btn btn-secondary "
                                        type="submit"
                                        onClick={(e) => onSaveNodesEdges(nodes, edges, "published")}
                                        data-bs-original-title=""
                                        title="">{onSave === true ? 'Despublicar' : 'Publicar'}
                                    </button>

                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => closeModal("public_bot")}
                                        data-bs-original-title=""
                                        title="">Cancelar
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className="modal custom-modal fade" style={{top: -255}} id="save_bot"
                 tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title"> Salvar</h5>
                            <button
                                className="btn-close"
                                type="button"
                                onClick={(e) => closeModal("save_bot")}
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                data-bs-original-title=""
                                title=""></button>
                        </div>

                        <div className="modal-body list-persons">
                            <div className="d-flex align-items-center justify-content-center">
                                <p>Tem certeza de que deseja Salvar <span>{bot_name}</span>?</p>
                            </div>

                            <div className="mt-10 ps-0">
                                <div
                                    className="d-flex align-items-center justify-content-center">

                                    <button
                                        style={{marginRight: 20}}
                                        className="btn btn-secondary "
                                        type="submit"
                                        onClick={(e) => onSaveNodesEdges(nodes, edges, "save")}
                                        data-bs-original-title=""
                                        title="">Salvar
                                    </button>

                                    <button
                                        className="btn btn-primary"
                                        type="button"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => closeModal("save_bot")}
                                        data-bs-original-title=""
                                        title="">Cancelar
                                    </button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>

            <div className="modal custom-modal fade" id="loading" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Loader src={loader}/>
                        </div>

                    </div>
                </div>
            </div>


            <div style={{height: "80vh"}} onClick={handleContainerClick}>
                <ReactFlowProvider>
                    <ReactFlow
                        nodes={nodes}
                        edges={edgesWithUpdatedTypes}
                        onNodeClick={handleElementClick}
                        onNodesChange={onNodesChange}
                        onEdgesChange={handleEdgeClick}
                        nodeTypes={nodeTypes}
                        deleteKeyCode={46}
                        fitView
                        attributionPosition="top-right"
                        onConnect={onConnect}>
                        <MiniMap style={minimapStyle} zoomable pannable/>
                        <Controls/>
                        <Background color="#aaa" gap={16}/>
                    </ReactFlow>
                </ReactFlowProvider>

            </div>
        </div>
    );
};
export default FlowChatBot;