import React, { useState, useEffect } from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import '././style/FilePreview.css';

import { FaFilePdf, FaFileImage, FaFileWord, FaFileAlt, FaDownload } from 'react-icons/fa';




// Componente principal que exibe a tarja com a opção de download e abre em uma nova aba
function FilePreview({ fileUrl, extension, file_name }) {
    const [fileType, setFileType] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [error, setError] = useState(false);
    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '10px',
            padding: '10px',
            border: '1px solid #ccc',
            borderRadius: '5px',
            backgroundColor: '#f1f1f1',
        },
        filePreview: {
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            border: '1px solid #ddd',
            padding: '10px',
            borderRadius: '5px',
            backgroundColor: '#fff',
        },
        icon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fileInfo: {
            display: 'flex',
            flexDirection: 'column',
        },
        fileName: {
            fontSize: '14px',
            fontWeight: 'bold',
            margin: '0',
        },
        downloadButton: {
            color: '#007bff',
            textDecoration: 'none',
        },
    };
    // useEffect para definir o tipo de arquivo
    useEffect(() => {
        if (extension.includes("vnd.openxmlformats-officedocument.wordprocessingml.document")) {
            setFileType("docx");
        } else {
            if (extension.includes("pdf")) {
                setFileType("pdf");
            }else {
                setFileType(extension.split('/')[1]);
            }

        }
    }, [extension]); // Adiciona 'extension' como dependência

    const docs = [
        { uri: fileUrl, fileType: fileType }
    ];

    const getFileIcon = () => {
        if (!fileUrl) return <i className="fas fa-file"></i>;

        if (fileType.includes('pdf')) return <i className="fas fa-file-pdf"></i>;
        {/*if (fileType.includes('image')) return <i className="fas fa-file"></i>;*/}
        if (fileType.includes('zip')) return <i className="fas fa-file-archive"></i>;
        if (fileType.includes('docx') || fileType.includes('doc')) return <i className="fas fa-file-word"></i>;

        return <i className="fas fa-file"></i>
    };

    const getColor = () => {
       if (fileType === 'pdf'){
           return "#830b2b"
       }else{
           if (fileType === 'docx' || fileType === 'doc' ){
               return "#005995"
           }else{
               if (fileType === 'zip'  ){
                   return "#8f8a03"
               }else{
                   return "#025502"
               }
           }
       }


    }
    const maxTitleLength = 20;
    // Função para truncar o título
    const truncateTitle = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    };

    return (
        <div className="file-message" style={{backgroundColor: getColor()  }}>
            <div className="file-icon" >
                {getFileIcon()}
            </div>
            <div className="file-details">
                <span className="file-name" > {truncateTitle(file_name, maxTitleLength)}</span>
                <span style={{ textTransform: 'uppercase' }} className="file-info">{fileType} </span>
            </div>
            <a href={fileUrl} download target="_blank" rel="noopener noreferrer">
                <div className="download-icon">
                    <i className="fas fa-download"></i> {/* Simula o ícone de download */}
                </div>
            </a>


        </div>
    );
}

export default FilePreview;
