import React, { createRef } from 'react';
import ActionCable from 'actioncable';
import axios from 'axios';
import "../style/slick.css";
import "../style/slick-theme.css";
import "../style/conversa.css";
import "../style/scrollbar.css";
import "../style/emoji/uikit.min.css";
import ImageWithFallback from '../ImageWithFallback';
import FilePreview from './../../FilePreview';
import ListMessage  from './ListMessage';
import ListResponseMessage from './ListResponseMessage';
import SendResponseMessage from './SendResponseMessage';
import ResponseMessage from './ResponseMessage';
import ErrorMessage from './ErrorMessage';
import Loader from '../Loader';
import EditBasicInfoChatContact from "./EditBasicInfoChatContact";
import EditServiceInfoChatContact from "./EditServiceInfoChatContact";
import WaveSurfer from 'wavesurfer.js';
import { FaMicrophone } from 'react-icons/fa';

//import 'audio-react-recorder/dist/index.css'
//import "./audiowpp";
export default class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            tags: [],
            tagContacts: {},
            inputMessage: '',
            contactIdSelected: '',
            mediaBlobUrl: null,
            chatContainerRef : null,
            currentRoom: null,
            uploadData: null,
            uploadFile: null,
            eventFile: [],
            selectedFile: null,
            selectedChatInternal: null,
            selectedTypeFile: '',
            selectedNameFile: '',
            selectedFileSubmit: false,
            isRecording: false,
            audioData: null,
            isActive: false,
            audioURL: '',
            audioBlob: null,
            recordState: null,
            counter: 0,
            seconds: 0,
            minutes: 0,
            cronReloadChat: 10000,
            isOpen: false,
            isOpenDropdown: false,
            isHovered: false,
            replyMessageId: "",
            selectedMessageReply:false,
            secondsButtonDisabled: 20,
            isButtonDisabled: true,

        };
        this.mediaRecorder = null;
        this.audioChunks = [];
        this.timer = null;
        this.timerId = null;
        this.chatContainerRef = React.createRef();
        this.interval = null;
        this.dropdownRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }



    startRecording = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);
        this.mediaRecorder.ondataavailable = event => {
            this.audioChunks.push(event.data);
        };
        this.mediaRecorder.onstop = () => {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
            const audioURL = URL.createObjectURL(audioBlob);
            this.setState({ audioURL, audioBlob });
            this.audioChunks = [];
        };
        this.mediaRecorder.start();
        this.setState({ isRecording: true, seconds: 0, minutes: 0 });
        this.startTimer();
    };

    stopRecording = () => {
        this.mediaRecorder.stop();
        this.setState({ isRecording: false });
        this.stopTimer();
    };



    stopTimer = () => {
        clearInterval(this.timer);
    };

    onOpenDropdown = () => {
        this.setState((prevState) => ({
            isOpenDropdown: !prevState.isOpenDropdown,
        }));

    };
    resetRecording = () => {
        this.setState({ audioURL: '', audioBlob: null, seconds: 0, minutes: 0 });
    };
    sendAudio = async () => {
        const { audioBlob } = this.state;
        const { current_user_name, profile_picture } = this.props;
        const formData = new FormData();

        formData.append('text', '');
        formData.append('type', 'audio');
        formData.append('date', new Date());
        formData.append('position', 'right');
        formData.append('chat_id', this.props.selectedChat.id);

        if (audioBlob) {
            formData.append('audio', audioBlob, 'recording.wav');

            const newMessage = {
                position: 'right',
                sender: current_user_name,
                avatar:profile_picture,
                type: 'file',
                subtype: 'audio/wav',
                file: this.state.audioURL,
                date: new Date(),
            };


            this.props.changeChatSendMessages(newMessage)
            this.setState({ inputMessage: ''});


            axios.post('/messagings/send_audio', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
                .then(response => {
                    console.log('Resposta do servidor:', response.data);
                    document.getElementById("record_audio").style.display = "none";
                    const elementos = document.querySelectorAll(".modal-backdrop");
                    elementos.forEach(elemento => {
                        elemento.classList.remove("modal-backdrop");
                    });

                    this.setState({ audioURL: '', audioBlob: null, seconds: 0, minutes: 0,isRecording: false });
                    this.scrollToBottom();
                })
                .catch(error => {
                    console.error('Erro ao enviar arquivos:', error);
                });

                this.setState(
                    (prevState) => ({
                        cronReloadChat: prevState.cronReloadChat + 5000 // Aumente o intervalo conforme necessário
                    }),
                    () => {
                        clearInterval(this.interval);
                    }
                );
        } else {
            document.getElementById("record_audio").style.display = "none";
            const elementos = document.querySelectorAll(".modal-backdrop");
            elementos.forEach(elemento => {
                elemento.classList.remove("modal-backdrop");
            });
        }
    };

    formatTime = (time) => {
        return time < 10 ? `0${time}` : time;
    };

    handleFileChange =  (event) => {
        event.preventDefault();


        this.setState({selectedNameFile: event.target.files[0]["name"]});

        if (event.target.files[0]["type"].split('/')[0] === "audio") {
            this.setState({selectedTypeFile: event.target.files[0]["type"]});
            const blob = new Blob([event.target.files[0]], {type: event.target.files[0]["type"]});
            const blobUrl = URL.createObjectURL(blob);
            this.setState({selectedFile: blobUrl});
        } else {
            if (event.target.files[0]["type"].split('/')[0] === "image") {
                this.setState({selectedTypeFile: event.target.files[0]["type"]});
                const image = URL.createObjectURL(event.target.files[0]);
                this.setState({selectedFile: image});
            } else {
                if (event.target.files[0]["type"].split('/')[0] === "video") {
                    this.setState({selectedTypeFile: event.target.files[0]["type"]});
                    const video = URL.createObjectURL(event.target.files[0]);
                    this.setState({selectedFile: video});
                } else {
                    if (event.target.files[0]["type"].split('/')[0] === "application") {
                        this.setState({selectedTypeFile: event.target.files[0]["name"].split('.')[1]});
                        const file = URL.createObjectURL(event.target.files[0]);
                        this.setState({selectedFile: file});
                    }
                }
            }
        }


        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_name', file["name"]);
        formData.append('type_file', file["type"]);

        fetch('/messagings/upload_data', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {

            this.setState({uploadFile: data.arquivoUrl});
            console.log('Resposta do servidor:', data.arquivoUrl);
            this.scrollToBottom();
            // Faça algo com a resposta do servidor, se necessário
            console.log('Resposta do servidor:', data);
        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });


        {/*
        this.setState({uploadFile: response.data.arquivoUrl});
        console.log('Resposta do servidor:', response.data.arquivoUrl);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('arquivos[]', files[i]);
        }
        formData.append('file_name', files[0]["name"]);
        formData.append('type_file', files[0]["type"]);

        // Faça uma solicitação HTTP POST para o backend
         axios.post('/messagings/upload_data', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                this.setState({uploadFile: response.data.arquivoUrl});
                console.log('Resposta do servidor:', response.data.arquivoUrl);
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });
    */}

    };
    handleSubmit =  () => {
        this.setState({ selectedFileSubmit: true});
        document.getElementById('input_file_update').value = '';

        document.getElementById("upload_arquivo").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    };
    fetchTags = async () => {

        let response = await axios.get('/tags/get_tags.json');
        this.setState({tags: response.data.tags})

    }

    handleInputChange = (event) => {
        this.setState({ inputMessage: event.target.value });
    }


    handleFormSubmit = (event) => {
        event.preventDefault();
        const { inputMessage, selectedFile , selectedTypeFile, selectedNameFile, selectedFileSubmit, uploadData, uploadFile} = this.state;
        const { current_user_name, profile_picture} = this.props;

        this.scrollToBottom();
        console.log("uploadFile --- " + uploadFile)


        const formData = new FormData();

        if (inputMessage.trim() !== '' && selectedFile === null) {
            const newMessage = {
                position: 'right',
                type: 'text',
                sender: current_user_name,
                avatar: profile_picture,
                subtype: 'text',
                text: inputMessage,
                date: new Date()
            };

            formData.append('text',inputMessage);
            formData.append('type', 'text');
            formData.append('subtype', 'text');
            formData.append('date',  new Date());
            formData.append('position',  'right');
            formData.append('chat_id', this.props.selectedChat.id);


            this.props.changeChatSendMessages(newMessage)
            this.setState({ inputMessage: ''});
            this.setState({selectedFileSubmit: false});

        }else{
            if (inputMessage.trim() !== '' && selectedFile  && selectedFileSubmit === true ) {

                const newMessage = {
                    position: 'right',
                    type: 'text/file',
                    sender: current_user_name,
                    avatar: profile_picture,
                    subtype: selectedTypeFile,
                    file: selectedFile,
                    text: inputMessage,
                    date: new Date()
                };

                // Adicione os arquivos selecionados ao objeto FormData

                console.log("file_path ----------- " + uploadFile)

                formData.append('text',inputMessage);
                formData.append('file_path',uploadFile);
                formData.append('selectedFile',selectedFile);

                formData.append('file_name',selectedNameFile);
                formData.append('type', 'text/file');
                formData.append('subtype', selectedTypeFile);
                formData.append('date',  new Date());
                formData.append('position',  'right');
                formData.append('chat_id', this.props.selectedChat.id);


                this.props.changeChatSendMessages(newMessage)
                this.setState({ inputMessage: ''});

                this.setState({selectedFileSubmit: false});
                this.setState({selectedTypeFile: ''});
                this.setState({selectedNameFile: ''});
                this.setState({selectedFile: null});
                this.setState({uploadData: null});
                this.setState({uploadFile: null});

            }else{
                if (inputMessage.trim() === '' && selectedFile && selectedFileSubmit === true  ) {

                    const newMessage = {
                        position: 'right',
                        type: 'file',
                        sender: current_user_name,
                        avatar: profile_picture,
                        subtype: selectedTypeFile,
                        file: selectedFile,
                        date: new Date()
                    };

                    formData.append('file_name',selectedNameFile);
                    formData.append('text','');
                    formData.append('type', 'file');
                    formData.append('file_path',uploadFile);
                    formData.append('selectedFile',selectedFile);
                    formData.append('subtype', selectedTypeFile);
                    formData.append('date',  new Date());
                    formData.append('position',  'right');
                    formData.append('chat_id', this.props.selectedChat.id);

                    this.props.changeChatSendMessages(newMessage)
                    this.setState({ inputMessage: ''});

                    this.setState({selectedFileSubmit: false});
                    this.setState({selectedTypeFile: ''});
                    this.setState({selectedNameFile: ''});
                    this.setState({selectedFile: null});
                    this.setState({uploadData: null});
                    this.setState({uploadFile: null});

                }else{
                    if (inputMessage.trim() !== '' && selectedFile && selectedFileSubmit === false  ) {
                        const newMessage = {
                            position: 'right',
                            type: 'text',
                            sender: current_user_name,
                            avatar: profile_picture,
                            subtype: 'text',
                            text: inputMessage,
                            date: new Date()
                        };

                        formData.append('text',inputMessage);
                        formData.append('type', 'text');
                        formData.append('subtype', 'text');
                        formData.append('date',  new Date());
                        formData.append('position',  'right');
                        formData.append('chat_id', this.props.selectedChat.id);

                        this.props.changeChatSendMessages(newMessage)
                        this.setState({ inputMessage: ''});

                        this.setState({selectedTypeFile: ''});
                        this.setState({selectedNameFile: ''});
                        this.setState({selectedFile: null});
                        this.setState({uploadData: null});
                        this.setState({uploadFile: null});

                    }
                }

            }
        }


        // Faça uma solicitação HTTP POST para o backend
        axios.post('/messagings/send_message', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);

                this.scrollToBottom();
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });

        this.setState(
            (prevState) => ({
                cronReloadChat: prevState.cronReloadChat + 5000 // Aumente o intervalo conforme necessário
            }),
            () => {
                clearInterval(this.interval);

            }
        );


    }
    onSendResponse = (replyMessageId, replyText) => {
        const formData = new FormData();
        const { messages} = this.state;
        const { current_user_name, profile_picture} = this.props;

        const message = messages.find(msg => msg.id === replyMessageId);

        const context = {
            avatar: message.avatar,
            position: message.position,
            sender: message.sender,
            caption: "",
            url: "",
        }
        const newMessage = {
            position: 'right',
            referringMessageType: "",
            type: 'response',
            sender: current_user_name,
            avatar: profile_picture,
            subtype: 'text',
            text: replyText,
            replyMessage: message,
            replyMessageId: replyMessageId,
            date: new Date()
        };

        formData.append('text',replyText);
        formData.append('replyMessageId',replyMessageId);
        formData.append('type', 'text_response');
        formData.append('subtype', 'text');
        formData.append('date',  new Date());
        formData.append('position',  'right');
        formData.append('chat_id', this.props.selectedChat.id);

        this.props.changeChatSendMessages(newMessage)


        // Faça uma solicitação HTTP POST para o backend
        axios.post('/messagings/send_message', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);

                this.scrollToBottom();
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });

        this.setState(
            (prevState) => ({
                cronReloadChat: prevState.cronReloadChat + 5000 // Aumente o intervalo conforme necessário
            }),
            () => {
                clearInterval(this.interval);

            }
        );
    }
    handleClearSelectedFile = () => {
        document.getElementById('input_file_update').value = '';
        this.setState({selectedFileSubmit: false});
        this.setState({selectedFileSubmit: false});
        this.setState({selectedTypeFile: ''});
        this.setState({selectedNameFile: ''});
        this.setState({selectedFile: null});
        this.setState({uploadData: null});
        this.setState({uploadFile: []});
    }
    formatPhoneNumber = (phone) => {
        const cleaned = phone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos

        // Verifica se o número tem 10 ou 11 dígitos
        if (cleaned.length === 10) {
            const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]}-${match[3]}`;
            }
        } else if (cleaned.length === 11) {
            const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
            if (match) {
                return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
            }
        }

        // Retorna o número sem formatação se não corresponder a 10 ou 11 dígitos
        return phone;
    };

    formatCurrencyBRL = (value) => {
        const numericValue = parseFloat(value).toFixed(2); // Garante duas casas decimais
        const [integerPart, decimalPart] = numericValue.split('.'); // Separa a parte inteira da decimal

        // Formata a parte inteira com pontos para separar os milhares
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

        // Retorna o valor formatado como moeda brasileira
        return `R$ ${formattedIntegerPart},${decimalPart}`;
    };

    fetchChat = async () => {
        console.log(" chamou fetchChat");

        try {
            let chat = await axios.get('/messagings/get_chat.json', {
                params: {
                    chat_id: this.props.selectedChat.id
                }
            });

            // Atualizando as mensagens do chat
            await this.props.changeCurrentChatMessages(chat.data.messages, this.props.selectedChat);
            await this.props.changeChatMessages(chat.data.messages);
            await this.props.changeChat(this.props.selectedChat);
            this.scrollToBottom();

        } catch (error) {
            console.error("Erro ao buscar chat:", error);
        }
    }
    getStatus(s) {
        switch (s) {
            case 'simulation':
            case 'NEW_PROCESS':
            case 'IN_PROGRESS':
                return 'badge-primary';
            case 'approved':
                return 'badge-success';
            case 'reproved':
                return 'badge-danger';
            case 'resend_documents':
            case 'under_analysis':
            case 'send_documents':
            case 'company_analysing':
            case 'awaiting_contract':
            case 'CLOSED':
                return 'badge-warning';
            case 'REPASSADO_PARA_EMPRESA':
            case 'PRE_APROVADO':
                return 'badge-success';
            case 'hired':
            case 'fill_data':
                return 'badge-info';
            case 'released':
                return 'badge-purple';
            default:
                return 'badge-success';
        }
    }
    getRandomCharacter = (key) => {
        const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        const ky = []

        for(let i = 0; i < 10; i++){
            ky[1] = characters.charAt(Math.floor(Math.random() * characters.length))
        }
        return `${key}-${ky.join("")}`;
    };
    closeEditMoreInfo(id_tab, editMoreInfo, info) {

        let elementEditMoreInfo = document.getElementById(editMoreInfo);
        let elementInfo = document.getElementById(info);
        let element = document.getElementById(id_tab);

        if (elementEditMoreInfo) {
            elementEditMoreInfo.style.display = "block";
        } else {
            console.error("Element not found:", elementEditMoreInfo);
        }
        if (element) {
            element.style.display = "none";
        } else {
            console.error("Element not found:", id_tab);
        }
        if (elementInfo) {
            elementInfo.style.display = "block";
        } else {
            console.error("Element not found:", elementInfo);
        }

    }
    closeModal(id){
        document.getElementById(id).style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    }
    openEditMoreInfo(id_tab, editMoreInfo, info) {

        let elementEditMoreInfo = document.getElementById(editMoreInfo);
        let elementInfo = document.getElementById(info);
        let element = document.getElementById(id_tab);


        if (elementEditMoreInfo) {
            elementEditMoreInfo.style.display = "none";
        } else {
            console.error("Element not found:", elementEditMoreInfo);
        }


        console.log("Element:", element);
        if (element) {
            element.style.display = "block";
        } else {
            console.error("Element not found:", id_tab);
        }


        if (elementInfo) {
            elementInfo.style.display = "none";
        } else {
            console.error("Element not found:", elementInfo);
        }
    }


    componentDidMount() {
        const {messages} = this.state
        this.fetchTags();
        this.scrollToChatBottom();
        let self = this;
        const cable = ActionCable.createConsumer('/cable');

        if (!messages){
            this.fetchChat();
            this.scrollToChatBottom();
        }

        // Subscribing to Chat Channel
        this.chatChannel = cable.subscriptions.create("MessageNotificationChannel", {
            connected: () => {
                console.log('Connected to MessageNotificationChannel - CHAT');
            },
            received: (data) => {

                if(data.chat_id === self.props.selectedChat.id){

                    this.fetchChat(); // Chama a função fetchGetChats quando uma nova mensagem for recebida
                    this.scrollToBottom();
                }


            },
        });

        document.addEventListener('mousedown', this.handleClickOutside);



    }


    scrollToBottom = () => {
        console.log("chamou o pai - scrollToBottom ");
        if (this.chatContainerRef.current) {
            setTimeout(() => {
                const lastMessage = this.chatContainerRef.current.lastElementChild; // Acessa o último <li>
                if (lastMessage) {
                    lastMessage.scrollIntoView({ behavior: 'smooth' }); // Rola suavemente até o último <li>
                }
            }, 100); // Atraso de 100ms


        }
    };
    scrollToChatBottom = () => {
        console.log("chamou o pai - scrollToBottom ");
        if (this.chatContainerRef.current) {
            setTimeout(() => {
                const lastMessage = this.chatContainerRef.current.lastElementChild; // Acessa o último <li>
                if (lastMessage) {
                    lastMessage.scrollIntoView({ behavior: 'auto' }); // Rola suavemente até o último <li>
                }
            }, 100); // Atraso de 100ms


        }
    };

    componentWillUnmount() {
        if (this.chatChannel) {
            this.chatChannel.unsubscribe(); // Desinscreve do canal quando o componente for desmontado
        }

        document.removeEventListener('mousedown', this.handleClickOutside);

        clearInterval(this.timerId);
    }
    componentDidUpdate(prevProps, prevState) {
        // Se as mensagens mudarem, role para a última mensagem
        if (prevState.messages.length !== this.state.messages.length) {
            console.log("chamou o tio - componentDidUpdate ");
            setTimeout(() => {
                this.scrollToBottom();
            }, 0); // Aguarda a atualização do DOM

        }
        if (this.state.uploadFile && prevState.uploadFile !== this.state.uploadFile) {
            // Reinicia o cronômetro e desabilita o botão
            this.setState({ secondsButtonDisabled: 20, isButtonDisabled: true });
            this.startTimer();
        }

    }
    startTimer = () => {
        // Limpa qualquer intervalo existente antes de iniciar um novo
        clearInterval(this.timerId);
        this.timerId = setInterval(() => {
            this.setState((prevState) => {
                if (prevState.secondsButtonDisabled <= 1) {
                    clearInterval(this.timerId);
                    return { secondsButtonDisabled: 0, isButtonDisabled: false };
                }
                return { secondsButtonDisabled: prevState.secondsButtonDisabled - 1 };
            });
        }, 1000);
    };

    handleClickOutside(event) {
        // Verifica se o clique foi fora do dropdown
        if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }
    toggleDropdown = () => {
        this.setState((prevState) => ({
            isOpen: !prevState.isOpen,
        }));
    };
    toggleDropdownChat = (element, messageId) =>  {
        this.setState({
            replyMessageId: messageId,
        });

        this.onOpenDropdown();
    }

    handleReplyMessage=(selectedMessageReply, replyMessageId)=>{
        this.setState({
            selectedMessageReply: selectedMessageReply,
            replyMessageId: replyMessageId,
        });
    }


    handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault(); // Evita que uma nova linha seja criada
            this.handleFormSubmit(event); // Chama a função para enviar a mensagem
        } // Se "Shift + Enter" for pressionado, insere uma nova linha
        else if (event.key === 'Enter' && event.shiftKey) {
            // Deixe o comportamento padrão do textarea (inserir nova linha)
        }
    }
    handleOptionSelect = (option) => {
        // Define a opção selecionada como resposta

    };
    render() {
        const {isButtonDisabled, secondsButtonDisabled,selectedMessageReply,replyMessageId, isHovered,tags,tagContacts,  inputMessage, isRecording, second, audioData, minute , recordState, selectedFile,uploadFile, selectedNameFile,selectedTypeFile, selectedFileSubmit, selectedChatInternal , currentMessageId, chatContainerRef, audioURL, seconds,minutes , isOpenDropdown  } = this.state;
        const { user_role,lawyers, messages,chatValidate, process_status, query_types_new_process,query_types_in_progress,query_types_in_close, selectedChat,current_user_name, current_user_id, image_avatar, loader, isLoading, currentCompany, departments, tickets, user_name, profile_picture} = this.props;



        return (
            <>
                <div className="card right-sidebar-chat">
                    <div className="right-sidebar-title">
                        <div className="common-space">
                            <div className="chat-time">
                                <div className="active-profile">
                                    <ImageWithFallback
                                        classImage={"img-fluid rounded-circle"}
                                        src={selectedChat.avatar}
                                        fallback={image_avatar}
                                        alt="user"
                                    />

                                    <div className="status bg-success"></div>
                                </div>
                                <div>
                                    <span>{selectedChat.title}</span>
                                    <p>Online </p>
                                </div>
                            </div>
                            <div className="d-flex gap-2">
                                <div className="contact-edit chat-alert" >
                                    <a style={{color: "#000000"}} data-toggle="modal"
                                       data-target="#contact" href="#">
                                        <i className="bx bxs-user"></i>
                                    </a>

                                </div>
                                {(user_role === "privately_held" || user_role === "support" ) &&
                                    <div className="contact-edit chat-alert">
                                        <i className="bx bx-cog" role="menu" data-bs-toggle="dropdown"
                                           aria-expanded="false"></i>
                                        <div className="dropdown-menu dropdown-menu-end">
                                            <a className="dropdown-item" href="#"></a>
                                            <a className="dropdown-item" href="#">Send messages</a>
                                            <a className="dropdown-item" href="#!">Add to favorites</a>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="right-sidebar-chats">
                        <div className="msger">
                            <div className="msger-chat">
                                {/*isLoading ? <Loader src={loader}/>  : */}
                                {
                                    <ul ref={this.chatContainerRef}>
                                        {Array.isArray(messages) && messages.length > 0 && messages.map((msg, index) => (

                                            <li key={msg.id || index} id={msg.id || index}>
                                                <div className={`msg mb-10 ${msg.position === "right" ? 'right-msg' : 'left-msg'}`}>
                                                        <div className="msg-img">
                                                            <ImageWithFallback
                                                                classImage={""}
                                                                src={msg.avatar}
                                                                fallback={image_avatar}
                                                                alt="Imagem"
                                                            />
                                                        </div>
                                                        <div className="msg-bubble" style={{ position: "relative"}}>
                                                            <div className="msg-info">
                                                                <div className="msg-info-name">
                                                                    {msg.position === "right" ?
                                                                        `${currentCompany.name}${msg.sender ? " - " + msg.sender : ""}` :
                                                                        selectedChat.title}
                                                                </div>

                                                                {msg.id && (
                                                                        (msg.type !== 'text/file' && msg.type !== 'file') ||
                                                                        (['audio', 'video'].includes(msg.subtype.split('/')[0]) === false)
                                                                    ) &&
                                                                    <div className="contact-edit chat-alert" style={{background: "transparent"}} id={`dropdown-responseMessage-${msg.id}`} key={`dropdown-responseMessage-${msg.id}`}
                                                                         onClick={(e) => this.toggleDropdownChat(e.currentTarget, msg.id)}>
                                                                        <i className={(replyMessageId === msg.id && isOpenDropdown )? 'bx bx-chevron-up' : 'bx bx-chevron-down'}
                                                                           style={{color:  "#050404"}}
                                                                           role="menu"
                                                                           data-bs-toggle="dropdown"
                                                                           aria-expanded="false"></i>

                                                                        {this.state.isOpenDropdown &&
                                                                            replyMessageId === msg.id
                                                                            && (<div className={`dropdown-menu dropdown-menu-end show ${msg.position === 'right' ? 'right-dropdown' : ''}`}

                                                                                     style={{
                                                                                         position: 'absolute',
                                                                                         top: 54, // Mover para baixo da mensagem
                                                                                         right: 15  , // Alinha à direita se msg.position é 'right'
                                                                                         left: 'auto' // Alinha à esquerda, caso contrário
                                                                                     }}
                                                                        >

                                                                            <a className="dropdown-item"



                                                                               onClick={() => this.handleReplyMessage(true,msg.id)}
                                                                               style={{opacity: 1}}
                                                                               href="#"> <i
                                                                                className='bx bx-message-square-add'></i>Responder</a>
                                                                        </div>)}
                                                                    </div>
                                                                }
                                                            </div>
                                                            {msg.type === 'text' && msg.subtype === 'text' &&
                                                                <div
                                                                    className="msg-text message-bubble">{msg.text}</div>
                                                            }
                                                            {(msg.type === 'text/file' || msg.type === 'file') && (
                                                                <React.Fragment>
                                                                    {msg.subtype.split('/')[0] === 'image' && (
                                                                        <div className="msg-imagem center mb-20" >
                                                                            <a href={msg.file} target="_blank"
                                                                               rel="noopener noreferrer">
                                                                                <img src={msg.file} style={{
                                                                                    minWidth: 400,
                                                                                    maxWidth: 400,
                                                                                    minHeight: 280,
                                                                                    maxHeight: 280,
                                                                                    borderRadius: "5px"
                                                                                }}  alt={`image-${msg.id || index}`}/>
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                    {msg.subtype.split('/')[0] === 'audio' && (
                                                                        <div className="msg-audio mb-20">
                                                                            <div className="holder">
                                                                                <div
                                                                                    className="audio green-audio-player">
                                                                                    <a href={msg.file} target="_blank"
                                                                                       rel="noopener noreferrer">
                                                                                        <audio width={230} height={230}
                                                                                               src={msg.file} controls/>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {msg.subtype.split('/')[0] === 'video' && (
                                                                        <div className="msg-video mb-20">
                                                                            <div className="holder">
                                                                                <div
                                                                                    className="video green-video-player">
                                                                                    <a href={msg.file} target="_blank"
                                                                                       rel="noopener noreferrer">
                                                                                        <video width={230} height={230}
                                                                                               src={msg.file} controls/>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                    {(msg.subtype.split('/')[0] === 'pdf' || msg.subtype.split('/')[1] === 'pdf') && (
                                                                        <div className="msg-imagem mb-20">
                                                                            <FilePreview fileUrl={msg.file}
                                                                                         file_name={msg.file_name || ''}
                                                                                         extension={msg.subtype}/>
                                                                        </div>
                                                                    )}

                                                                    {msg.type === 'text/file' && msg.subtype.split('/')[0] !== 'image' && (
                                                                        <div className="msg-text message-bubble">{msg.text}</div>
                                                                    )}
                                                                    { msg.type === 'text/file' &&  msg.subtype.split('/')[0] === 'image' && msg.context && msg.context["caption"] && (
                                                                        <div className="msg-text message-bubble">{msg.context["caption"]}</div>
                                                                    )}

                                                                </React.Fragment>
                                                            )}
                                                            {msg.type === 'list' && msg.subtype === 'text' &&
                                                                <React.Fragment>
                                                                    <ListMessage title={msg.title.replace(/\*/g, "")}
                                                                                 description={msg.description}
                                                                                 sections={msg.sections}></ListMessage>
                                                                </React.Fragment>
                                                            }
                                                            {msg.type === 'listResponse' && msg.subtype === 'text' &&
                                                                <React.Fragment>
                                                                    <ListResponseMessage
                                                                        title={msg.contextInfo["title"].replace(/\*/g, "")}
                                                                        description={msg.contextInfo["description"]}/>
                                                                    <div
                                                                        className="list-response-message-response">{msg.text}</div>
                                                                </React.Fragment>
                                                            }
                                                            {msg.type === 'response' && msg.subtype === 'text' &&
                                                                <React.Fragment>
                                                                    <ResponseMessage  message={msg} image_avatar={image_avatar} chat={selectedChat} currentCompany={currentCompany}/>
                                                                    <div
                                                                        className="list-response-message-response">{msg.text}</div>
                                                                </React.Fragment>
                                                            }
                                                            {msg.type === 'error' && msg.subtype === 'text' &&
                                                                <React.Fragment>
                                                                    <ErrorMessage  message={msg} image_avatar={image_avatar} chat={selectedChat} currentCompany={currentCompany}/>
                                                                </React.Fragment>
                                                            }

                                                            {/*
                                                             <div className="check-mark">
                                                            <i className="fas fa-check-double"></i>
                                                        </div> */}
                                                            <div className="time-info ">{(new Date(msg.date)).toLocaleString()}</div>

                                                        </div>
                                                    </div>
                                            </li>

                                        ))}
                                        {!messages && <div className="center">
                                            <p> loading...</p>
                                        </div>}
                                    </ul>

                                }


                            </div>
                            <form className="msger-inputarea col-xl-12" action="#" onSubmit={this.handleFormSubmit}
                                  method="post" acceptCharset="utf-8">

                                {selectedFileSubmit === true && selectedFile && <div className="col-xl-10 w-80">
                                    <ul className=" mb-2 d-flex">
                                        <li className="left text-center w-10">

                                            {selectedTypeFile.split('/')[0] === "audio" && selectedFile && (
                                                <img width={60} height={60} src={this.props.image_mp3_url}/>
                                            )}
                                            {selectedTypeFile.split('/')[0] === "image" && selectedFile && (
                                                <img width={60} height={60} src={this.props.image_image_url}/>
                                            )}
                                            {selectedTypeFile.split('/')[0] === "video" && selectedFile && (
                                                <img width={60} height={60} src={this.props.image_video_url}/>
                                            )}
                                            {selectedTypeFile === "pdf" && selectedFile && (
                                                <img width={60} height={60} src={this.props.image_pdf_url}/>
                                            )}
                                            {(selectedTypeFile === "doc" || selectedTypeFile === "docx") && selectedFile && (
                                                <img width={60} height={60} src={this.props.image_doc_url}/>

                                            )}


                                        </li>
                                        <li className="right w-90">

                                                             <textarea className="w-100" name="message"
                                                                       style={{height: 55}}
                                                                       onChange={this.handleInputChange}
                                                                       value={inputMessage}
                                                                       placeholder="Escreva sua mensagem..."></textarea>

                                        </li>
                                    </ul>
                                </div>

                                }

                                {selectedFileSubmit === false && selectedFile && <div className="col-xl-10 w-80">
                                    <ul className=" mb-2 d-flex">
                                        <li className="left text-center w-2">
                                        </li>
                                        <li className="right w-100">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      style={{height: 55}}
                                                      onKeyDown={this.handleKeyPress}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                        </li>
                                    </ul>

                                </div>

                                }
                                {!selectedFile && <div className="col-xl-10 w-80">
                                    <ul className=" mb-2 d-flex">
                                        <li className="left text-center w-2">
                                        </li>
                                        <li className="right w-100">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      onKeyDown={this.handleKeyPress}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                        </li>
                                    </ul>
                                </div>
                                }


                                <div className="col-x-2 d-flex">
                                    {/*<div className="open-emoji">
                                        <a href="#" title="Enviar Arquivo (imagem/pdf/video)"
                                           onClick={this.handleClearSelectedFile} data-toggle="modal"
                                           data-target="#upload_arquivo">
                                            <i className='bx bx-paperclip'></i>
                                        </a>
                                    </div>*/}

                                    <div className="contact-edit chat-alert">
                                         <span className="icon-message">
                                            <a style={{color: "#000000"}} href="#" title="Gravação de Audio"
                                               data-toggle="modal"
                                               data-target="#record_audio">
                                                <i className='bx bx-microphone'></i>
                                            </a>
                                        </span>
                                    </div>

                                    <div className="contact-edit chat-alert" ref={this.dropdownRef} style={{marginLeft: 10,color: "#000000" }}>
                                        <i className="bx bx-plus" role="menu" title="Anexo" data-bs-toggle="dropdown"  onClick={this.toggleDropdown}
                                           aria-expanded="false"></i>
                                        {this.state.isOpen && (
                                            <div className="dropdown-menu dropdown-menu-end show">
                                                <a className="dropdown-item" style={{color: "#000000"}}
                                                   onClick={this.handleClearSelectedFile}
                                                   data-toggle="modal"
                                                   data-target="#upload_arquivo" href="#">
                                                    <i className="bx bx-paperclip"></i>
                                                    Buscar Arquivo</a>

                                            </div>
                                        )}

                                    </div>
                                    <button className="msger-send-btn" title="Enviar" type="submit"><i
                                        className="bx bxs-paper-plane"></i></button>
                                </div>


                            </form>

                        </div>
                    </div>
                </div>
                {Array.isArray(messages) && messages.length > 0 && messages.map((msg, index) => (
                    <>
                        { msg?.id && (
                            <SendResponseMessage
                                messageContext={msg}
                                messageId={msg.id}
                                replyMessageId={replyMessageId}
                                image_avatar={image_avatar}
                                selectedChat={selectedChat}
                                onSendResponse={this.onSendResponse}
                                currentCompany={currentCompany}
                                selectedMessageReply={selectedMessageReply}
                                handleReplyMessage={this.handleReplyMessage}
                            />
                        )}
                    </>


                ))}
                <div className="modal custom-modal fade" id="record_audio" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Enviar Audio</h5>
                                <button
                                    onClick={() => this.closeModal("record_audio")}
                                    className="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    data-bs-original-title=""
                                    title=""></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-header">
                                    <div className=" mt-10 mb-5 wave-animation-container">
                                        {isRecording && <div className="wave-animation"></div>}
                                        <ImageWithFallback
                                            classImage={"img-fluid rounded-circle"}
                                            src={profile_picture}
                                            fallback={image_avatar}
                                            style={{width: 80, height: 80, borderRadius: "50%"}}
                                            alt="user"
                                        />
                                    </div>
                                    <div className="d-flex" style={{
                                        paddingLeft: 10,
                                        marginBottom: 10,
                                        flexDirection: "column",
                                        flexWrap: "nowrap",
                                        justifyContent: "flex-start",
                                        alignContent: "stretch",
                                        alignItems: "center"
                                    }}>

                                        <div className="msg-info-name">
                                            {current_user_name}
                                        </div>
                                        <div className="msg-text center">
                                            {isRecording &&
                                                <h6>Gravando...</h6>}
                                        </div>
                                    </div>


                                    {/* <div className="user-pic text-center mt-5">

                                    <div className="avatar ">
                                        <ImageWithFallback
                                                    classImage={"img-fluid rounded-circle"}
                                                    src={profile_picture}
                                                    fallback={image_avatar}
                                                    style={{width: 80, height: 80, borderRadius: "50%"}}
                                                    alt="user"
                                                />

                                                <h5 className="pro-user-username text-dark mb-30 fs-15 mt-10 color-span">
                                                    {current_user_name}
                                                </h5>
                                            </div>

                                            <div className="pro-user mt-3">

                                                {!isRecording && !audioURL &&
                                                    <h6 className="pro-user-desc text-muted fs-14">Gravar</h6>}
                                                {isRecording &&
                                                    <h6 className="pro-user-desc text-muted fs-14">Gravando...</h6>}

                                            </div>
                                        </div> */}
                                </div>
                                <div className="modal-btn delete-action">
                                    <div className=" text-center">

                                        <div>
                                            <div className="audio-message-input mb-30">


                                                {audioURL && <audio controls src={audioURL}/>}
                                                <div className=" mt-10">
                                                    <a className="minute">{this.formatTime(minutes)}</a>
                                                    <a>:</a>
                                                    <a className="second">{this.formatTime(seconds)}</a>
                                                </div>

                                            </div>


                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {audioURL && <button onClick={this.resetRecording}
                                                                 style={{
                                                                     padding: "0.8rem 2rem",
                                                                     border: "none",
                                                                     backgroundColor: "#c5b165",
                                                                     marginLeft: "15px",
                                                                     fontSize: "1rem",
                                                                     cursor: "pointer",
                                                                     color: "white",
                                                                     borderRadius: "5px",
                                                                     fontWeight: "bold",
                                                                     transition: "all 300ms ease-in-out",
                                                                     transform: "translateY(0)"
                                                                 }}>Regravar</button>
                                            }

                                            {audioURL && <button onClick={this.sendAudio}
                                                                 style={{
                                                                     padding: "0.8rem 2rem",
                                                                     border: "none",
                                                                     backgroundColor: "#3c21f7",
                                                                     marginLeft: "15px",
                                                                     fontSize: "1rem",
                                                                     cursor: "pointer",
                                                                     color: "white",
                                                                     borderRadius: "5px",
                                                                     fontWeight: "bold",
                                                                     transition: "all 300ms ease-in-out",
                                                                     transform: "translateY(0)"
                                                                 }}>Enviar</button>
                                            }
                                        </div>

                                        {!isRecording && !audioURL && <button onClick={this.startRecording}
                                                                              style={{
                                                                                  padding: "0.8rem 2rem",
                                                                                  border: "none",
                                                                                  backgroundColor: "#3c21f7",
                                                                                  marginLeft: "15px",
                                                                                  fontSize: "1rem",
                                                                                  cursor: "pointer",
                                                                                  color: "white",
                                                                                  borderRadius: "5px",
                                                                                  fontWeight: "bold",
                                                                                  transition: "all 300ms ease-in-out",
                                                                                  transform: "translateY(0)"
                                                                              }}>Gravar</button>}

                                        {isRecording && <button onClick={this.stopRecording}
                                                                style={{
                                                                    padding: "0.8rem 2rem",
                                                                    border: "none",
                                                                    backgroundColor: "#df3636",
                                                                    marginLeft: "15px",
                                                                    fontSize: "1rem",
                                                                    cursor: "pointer",
                                                                    color: "white",
                                                                    borderRadius: "5px",
                                                                    fontWeight: "bold",
                                                                    transition: "all 300ms ease-in-out",
                                                                    transform: "translateY(0)"
                                                                }}>Parar</button>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal fade" id="upload_arquivo" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"> Enviar Arquivo</h5>
                                <button
                                    onClick={() => this.closeModal("upload_arquivo")}
                                    className="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    data-bs-original-title=""
                                    title=""></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-header">
                                    <div className="user-pic text-center">
                                        <div className="avatar ">

                                            {selectedTypeFile.split('/')[0] === "audio" && selectedFile && (
                                                <div className="pro-user-username  fs-14"
                                                     style={{marginTop: "10%"}}>
                                                    <audio src={selectedFile} controls/>
                                                </div>

                                            )}
                                            {selectedTypeFile.split('/')[0] === "image" && selectedFile && (
                                                <img width={230} height={230} src={selectedFile} alt="Preview"/>
                                            )}
                                            {selectedTypeFile.split('/')[0] === "video" && selectedFile && (
                                                <video width={230} height={230} src={selectedFile} controls/>
                                            )}
                                            {selectedTypeFile === "pdf" && selectedFile && (
                                                <img width={230} height={230} src={this.props.image_pdf_url}/>
                                            )}
                                            {(selectedTypeFile === "doc" || selectedTypeFile === "docx") && selectedFile && (
                                                <img width={230} height={230} src={this.props.image_doc_url}
                                                     alt="Word Document"/>
                                            )}


                                        </div>

                                        <div className="pro-user mt-3">
                                            <h5 className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span">
                                                {selectedNameFile}
                                            </h5>
                                            <h6 className="pro-user-desc text-muted fs-14"></h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-btn  text-center">


                                    <div className="form-group">
                                        <label>Upload</label>
                                        <input className="form-control"
                                               accept="image/*, audio/*, video/*,document/pdf,  document/doc, document/docx"
                                               id="input_file_update" type="file"
                                               onChange={this.handleFileChange}/>
                                    </div>

                                    <div>
                                        {selectedFile && (
                                            <button
                                                onClick={this.handleSubmit}
                                                disabled={isButtonDisabled}
                                                style={{
                                                    padding: "0.8rem 2rem",
                                                    border: "none",
                                                    backgroundColor: isButtonDisabled ? "" : "#3c21f7",
                                                    marginLeft: "15px",
                                                    fontSize: "1rem",
                                                    cursor: "pointer",
                                                    color: isButtonDisabled ? "#3c21f7" : "white",
                                                    borderRadius: "5px",
                                                    fontWeight: "bold",
                                                    transition: "all 300ms ease-in-out",
                                                    transform: "translateY(0)"
                                                }}
                                            >
                                                {isButtonDisabled ? `Espere ${secondsButtonDisabled}s` : "Confirmar"}
                                            </button>
                                        )}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal custom-modal fade" id="contact" role="dialog">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"> {selectedChat.title}</h5>
                                <button
                                    onClick={() => this.closeModal("contact")}
                                    className="btn-close"
                                    type="button"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    data-bs-original-title=""
                                    title=""></button>
                            </div>
                            <div className="modal-body">
                                <div className="form-header">

                                    <div className="user-pic text-center">
                                        <div className="avatar ">
                                            <ImageWithFallback
                                                classImage={""}
                                                src={selectedChat.avatar}
                                                fallback={image_avatar}
                                                width={80} height={80}
                                                alt="Imagem"
                                            />

                                        </div>
                                        <div className=" d-flex justify-content-center mt-10 mb-10">
                                            {selectedChat.chatTag &&
                                                <div className="pro-user-desc " key={selectedChat.chatTag}>
                                                                <span style={{marginLeft: 10}}
                                                                      className={`badge ${this.getStatus(selectedChat.chatTag)}`}>
                                                                    {process_status.map((item) => {
                                                                        if (item[1] === selectedChat.chatTag) {
                                                                            return item[0]; // Retorna o nome da consulta quando o código do ticket corresponde
                                                                        }
                                                                        return null; // Retorna null para outros casos
                                                                    })}
                                                                </span>

                                                </div>
                                            }

                                            {selectedChat.chatSubTag &&
                                                <div className="pro-user-desc text-muted fs-14"
                                                     key={selectedChat.chatSubTag}>
                                                        <span style={{marginLeft: 10}}
                                                              className={`badge ${this.getStatus(selectedChat.chatSubTag)}`}>

                                                            {selectedChat.chatTag === 'IN_PROGRESS' && query_types_in_progress.map((item) => {
                                                                if (item[1] === selectedChat.chatSubTag) {
                                                                    return item[0]; // Retorna o nome da consulta quando o código do ticket corresponde
                                                                }
                                                                return null; // Retorna null para outros casos
                                                            })}
                                                            {selectedChat.chatTag === 'NEW_PROCESS' && query_types_new_process.map((item) => {
                                                                if (item[1] === selectedChat.chatSubTag) {
                                                                    return item[0]; // Retorna o nome da consulta quando o código do ticket corresponde
                                                                }
                                                                return null; // Retorna null para outros casos
                                                            })}
                                                            {selectedChat.chatTag === 'CLOSED' && query_types_in_close.map((item) => {
                                                                if (item[1] === selectedChat.chatSubTag) {
                                                                    return item[0]; // Retorna o nome da consulta quando o código do ticket corresponde
                                                                }
                                                                return null; // Retorna null para outros casos
                                                            })}
                                                        </span>
                                                </div>
                                            }
                                        </div>
                                        {/*<div className="pro-user">
                                             <h5 className="pro-user-username text-dark mb-2 fs-15 mt-12 color-span"></h5>
                                            <h6 className="pro-user-desc text-muted mt-10  fs-14"> {this.formatPhoneNumber(selectedChat.phone?.startsWith('55') ? selectedChat.phone.slice(2) : selectedChat.phone)}</h6>
                                        </div>*/}
                                    </div>
                                </div>
                                <div className="modal-btn">
                                    <div id={`info${selectedChat.contactId}`} className="profile-mail">


                                        <div className="email-general">
                                            <>

                                            </>
                                            <div id={`basicInfo`}>

                                                <h5 className="mb-3 text-info">Informações básicas</h5>
                                                <ul>
                                                    <li>
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <h6 className="text-info">Telefone</h6>
                                                            <span
                                                                className="font-primary url_add_0">{this.formatPhoneNumber(selectedChat.phone?.startsWith('55') ? selectedChat.phone.slice(2) : selectedChat.phone)}</span>
                                                        </div>

                                                    </li>

                                                    <li>
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <h6 className="text-info">Email</h6>
                                                            <span
                                                                className="font-primary url_add_0">{selectedChat.email}</span>
                                                        </div>
                                                    </li>


                                                    {currentCompany.type_company === "advocacia" && <>
                                                        <li>
                                                            <div style={{
                                                                display: "flex",
                                                                flexWrap: "nowrap",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <h6 className="text-info">Agrupamento</h6>
                                                                <span
                                                                    className="font-primary url_add_0">{selectedChat.contactTag || 'Não cadastrado'}</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div style={{
                                                                display: "flex",
                                                                flexWrap: "nowrap",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <h6 className="text-info">Interesse</h6>
                                                                <span
                                                                    className="font-primary interest_0">{lawyers[selectedChat.insterest.toLowerCase()] || 'Não cadastrado'}</span>
                                                            </div>


                                                        </li>
                                                        <li>
                                                            <div style={{
                                                                display: "flex",
                                                                flexWrap: "nowrap",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <h6 className="text-info">Processual</h6>
                                                                <span
                                                                    className="font-primary url_add_0">{selectedChat.numProcess}</span>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div style={{
                                                                display: "flex",
                                                                flexWrap: "nowrap",
                                                                justifyContent: "space-between"
                                                            }}>
                                                                <h6 className="text-info">Valor da Ação</h6>
                                                                <span
                                                                    className="font-primary url_add_0">{this.formatCurrencyBRL(selectedChat.shareValue)}</span>
                                                            </div>
                                                        </li>

                                                    </>}

                                                    <a className="ps-0 edit-information mb-20 mt-20 "
                                                       href="#"
                                                       style={{color: "#b3088f"}}
                                                       data-bs-original-title=""
                                                       id={`editMoreInfo`}
                                                       onClick={() => this.openEditMoreInfo("editBasicInfo", "editMoreInfo", "basicInfo")}
                                                       title="">Editar informações básicas</a>


                                                </ul>

                                            </div>
                                            <div
                                                id={`editBasicInfo`}
                                                className="row g-2 more-data"
                                                style={{display: "none"}}>

                                                <EditBasicInfoChatContact process_status={process_status}
                                                                          query_types_new_process={query_types_new_process}
                                                                          query_types_in_progress={query_types_in_progress}
                                                                          query_types_in_close={query_types_in_close}
                                                                          infoContact={selectedChat}
                                                                          user_role={user_role}
                                                                          departments={departments}
                                                                          lawyers={lawyers}
                                                                          loader={loader}
                                                                          fetchGetChats={this.props.fetchGetChats}
                                                                          currentCompany={currentCompany}
                                                                          selectedChatInfo={selectedChat}
                                                                          tags={tags}
                                                                          onMyService={this.handleMyService}
                                                                          onCloseEditMoreInfo={this.closeEditMoreInfo}
                                                />


                                            </div>


                                            <div id={`serviceInfo`}>
                                                <h5 className="mb-3 text-info">Atendimento</h5>

                                                <ul>

                                                    <li>
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <h6 className="text-info">Atendente</h6>
                                                            <span
                                                                className="font-primary url_add_0">{selectedChat.operational_user_name || 'Não cadastrado'}</span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div style={{
                                                            display: "flex",
                                                            flexWrap: "nowrap",
                                                            justifyContent: "space-between"
                                                        }}>
                                                            <h6 className="text-info">Advogado</h6>
                                                            <span
                                                                className="font-primary url_add_0">{selectedChat.attorney_user_name || 'Não cadastrado'}</span>
                                                        </div>
                                                    </li>
                                                    <a className="ps-0 edit-information mb-20 mt-20"
                                                       href="#"
                                                       data-bs-original-title=""
                                                       style={{color: "#b3088f"}}
                                                       id={`editServiceMoreInfo`}
                                                       onClick={() => this.openEditMoreInfo("editServiceInfo", "editServiceMoreInfo", "serviceInfo")}
                                                       title="">Editar informações de Atendimento</a>
                                                </ul>
                                            </div>
                                            <div
                                                id={`editServiceInfo`}
                                                className="row g-2 more-data"
                                                style={{display: "none"}}>

                                                <EditServiceInfoChatContact process_status={process_status}
                                                                            query_types_new_process={query_types_new_process}
                                                                            query_types_in_progress={query_types_in_progress}
                                                                            query_types_in_close={query_types_in_close}
                                                                            infoContact={selectedChat}
                                                                            user_role={user_role}
                                                                            departments={departments}
                                                                            lawyers={lawyers}
                                                                            loader={loader}
                                                                            fetchGetChats={this.props.fetchGetChats}
                                                                            user_name={user_name}
                                                                            currentCompany={currentCompany}
                                                                            selectedChatInfo={selectedChat}
                                                                            tags={tags}
                                                                            onMyService={this.handleMyService}
                                                                            onCloseEditMoreInfo={this.closeEditMoreInfo}
                                                />


                                            </div>


                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}