import React from 'react';
import axios from 'axios';
import "../style/slick.css";
import "../style/slick-theme.css";
import "../style/conversa.css";
import "../style/scrollbar.css";

import ImageWithFallback from "../ImageWithFallback";
import Loader from "../Loader";
import EditBasicInfoChatContact from "../whatsapp/EditBasicInfoChatContact";
import EditServiceInfoChatContact from "../whatsapp/EditServiceInfoChatContact";


export default class Chat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            tags: [],
            tagContacts: {},
            inputMessage: '',

            contactIdSelected: '',
            mediaBlobUrl: null,
            chatContainerRef : null,
            currentRoom: null,
            uploadData: null,
            uploadFile: [],
            eventFile: [],
            selectedFile: null,
            selectedChatInternal: null,
            selectedTypeFile: '',
            selectedNameFile: '',
            selectedFileSubmit: false,
            isRecording: false,
            audioData: null,
            second:"00" ,
            minute: "00",
            isActive: false,
            recordState: null,
            counter: 0,

        };
    }


    handleInputChange = (event) => {
        this.setState({ inputMessage: event.target.value });
    }

    calculateTimeElapsed = (createdAt) => {
        const createdTime = createdAt;
        const currentTime = new Date();
        const elapsedSeconds = (currentTime - createdTime) / 1000;

        const minutes = elapsedSeconds / 60;
        const hours = elapsedSeconds / 3600;
        const days = elapsedSeconds / 86400;

        let timeReturn;
        if (days > 1) {
            timeReturn = `${Math.floor(days)} dias`;
        } else if (days === 1) {
            timeReturn = `1 dia`;
        } else if (hours >= 1) {
            timeReturn = `${Math.floor(hours)} ${Math.floor(hours) > 1 ? 'horas' : 'hora'}`;
        } else {
            timeReturn = `${Math.floor(minutes)} min`;
        }

        return timeReturn

    };
    handleFormSubmit = (event) => {
        event.preventDefault();
        const { inputMessage, selectedFile , selectedTypeFile, selectedNameFile, selectedFileSubmit, uploadData, uploadFile} = this.state;
        const { current_company,user_support, current_user_id, current_user_profile_picture,user_role, current_user_name, isLoadingUp, selectedChat, chat} = this.props;
        let currentChat
        if(user_role !== "support"){
            currentChat = chat
        }else{
            currentChat = selectedChat
        }


        const formData = new FormData();

        if (inputMessage.trim() !== '' && selectedFile === null) {


            const currentMessage ={
                position: 'right',
                type: 'text',
                subtype: 'text',
                text: inputMessage,
                sender:current_user_name,
                sender_user_idx:current_user_id,
                avatar: current_user_profile_picture,
                date: this.calculateTimeElapsed(new Date())
            }
            this.setState(prevState => ({
                messages: [...prevState.messages, currentMessage],
                inputMessage: ''
            }));

            formData.append('text',inputMessage);
            formData.append('type', 'text');
            formData.append('subtype', 'text');
            formData.append('date',  new Date());
            formData.append('chat_id', currentChat === undefined ? ( current_company.id.toLocaleString() +  user_support.id.toLocaleString() ) : currentChat?.id);
            formData.append('send_user_id',current_user_id);
            formData.append('company_id',current_company.id);


            this.setState({selectedFileSubmit: false});
        }else{
            if (inputMessage.trim() !== '' && selectedFile  && selectedFileSubmit === true ) {


                this.setState(prevState => ({
                    messages: [...prevState.messages, {
                        position: 'right',
                        type: 'text/file',
                        subtype: selectedTypeFile,
                        file: selectedFile,
                        text: inputMessage,
                        sender_user_idx:current_user_id,
                        sender:current_user_name,
                        avatar: current_user_profile_picture,
                        date: this.calculateTimeElapsed(new Date())
                    }],
                    inputMessage: ''
                }));



                formData.append('text',inputMessage);
                formData.append('file_path',uploadFile);
                formData.append('file_name',selectedNameFile);
                formData.append('type', 'text/file');
                formData.append('subtype', selectedTypeFile);
                formData.append('date',  new Date());

                formData.append('chat_id', currentChat === undefined ? ( current_company.id.toLocaleString() +  user_support.id.toLocaleString() ) : currentChat?.id);
                formData.append('send_user_id',current_user_id);
                formData.append('company_id',current_company.id);

                this.setState({selectedFileSubmit: false});
                this.setState({selectedTypeFile: ''});
                this.setState({selectedNameFile: ''});
                this.setState({selectedFile: null});
                this.setState({uploadData: null});
                this.setState({uploadFile: null});

            }else{
                if (inputMessage.trim() === '' && selectedFile && selectedFileSubmit === true  ) {


                    this.setState(prevState => ({
                        messages: [...prevState.messages, {
                            position: 'right',
                            type: 'file',
                            subtype: selectedTypeFile,
                            file: selectedFile,
                            sender_user_idx:current_user_id,
                            sender:current_user_name,
                            avatar: current_user_profile_picture,
                            date: this.calculateTimeElapsed(new Date())
                        }],
                        inputMessage: ''
                    }));

                    formData.append('file_name',selectedNameFile);
                    formData.append('text','');
                    formData.append('type', 'file');
                    formData.append('file_path',uploadFile);
                    formData.append('subtype', selectedTypeFile);
                    formData.append('date',  new Date());
                    formData.append('chat_id', currentChat === undefined ? ( current_company.id.toLocaleString() +  user_support.id.toLocaleString() ) : currentChat?.id);
                    formData.append('send_user_id',current_user_id);
                    formData.append('company_id',current_company.id);


                    this.setState({selectedFileSubmit: false});
                    this.setState({selectedTypeFile: ''});
                    this.setState({selectedNameFile: ''});
                    this.setState({selectedFile: null});
                    this.setState({uploadData: null});
                    this.setState({uploadFile: null});

                }else{
                    if (inputMessage.trim() !== '' && selectedFile && selectedFileSubmit === false  ) {


                        this.setState(prevState => ({
                            messages: [...prevState.messages, {
                                position: 'right',
                                type: 'text',
                                subtype: 'text',
                                text: inputMessage,
                                sender_user_idx:current_user_id,
                                sender:current_user_name,
                                avatar: current_user_profile_picture,
                                date: this.calculateTimeElapsed(new Date())
                            }],
                            inputMessage: ''
                        }));

                        formData.append('text',inputMessage);
                        formData.append('type', 'text');
                        formData.append('subtype', 'text');
                        formData.append('date',  new Date());
                        formData.append('chat_id', currentChat === undefined ? ( current_company.id.toLocaleString() +  user_support.id.toLocaleString() ) : currentChat?.id);
                        formData.append('user_support_id',user_support.id);
                        formData.append('send_user_id',current_user_id);
                        formData.append('company_id',current_company.id);

                        this.setState({selectedTypeFile: ''});
                        this.setState({selectedNameFile: ''});
                        this.setState({selectedFile: null});
                        this.setState({uploadData: null});
                        this.setState({uploadFile: null});

                    }
                }

            }
        }



        // Faça uma solicitação HTTP POST para o backend
        axios.post('/messagings/support/commit_menssage', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                console.log('Resposta do servidor:', response.data);
                isLoadingUp(false);
            })
            .catch(error => {
                console.error('Erro ao enviar :', error);
            });


    }
    formatPhoneNumber = (phone) => {
        const cleaned = phone.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        const match = cleaned.match(/^(\d{2})(\d{1})(\d{4})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
        }
        return phone;
    };
    handleClearSelectedFile = () => {
        document.getElementById('input_file_update').value = '';
        this.setState({selectedFileSubmit: false});
        this.setState({selectedFileSubmit: false});
        this.setState({selectedTypeFile: ''});
        this.setState({selectedNameFile: ''});
        this.setState({selectedFile: null});
        this.setState({uploadData: null});
        this.setState({uploadFile: []});
    }
    handleSubmit =  () => {
        this.setState({ selectedFileSubmit: true});
        document.getElementById('input_file_update').value = '';

        document.getElementById("upload_arquivo").style.display = "none";
        var elementos = document.querySelectorAll(".modal-backdrop");

        elementos.forEach(function(elemento) {
            elemento.classList.remove("modal-backdrop");
        });
    };
    handleFileChange =  (event) => {
        event.preventDefault();


        this.setState({selectedNameFile: event.target.files[0]["name"]});

        if (event.target.files[0]["type"].split('/')[0] === "audio") {
            this.setState({selectedTypeFile: event.target.files[0]["type"]});
            const blob = new Blob([event.target.files[0]], {type: event.target.files[0]["type"]});
            const blobUrl = URL.createObjectURL(blob);
            this.setState({selectedFile: blobUrl});
        } else {
            if (event.target.files[0]["type"].split('/')[0] === "image") {
                this.setState({selectedTypeFile: event.target.files[0]["type"]});
                const image = URL.createObjectURL(event.target.files[0]);
                this.setState({selectedFile: image});
            } else {
                if (event.target.files[0]["type"].split('/')[0] === "video") {
                    this.setState({selectedTypeFile: event.target.files[0]["type"]});
                    const video = URL.createObjectURL(event.target.files[0]);
                    this.setState({selectedFile: video});
                } else {
                    if (event.target.files[0]["type"].split('/')[0] === "application") {
                        this.setState({selectedTypeFile: event.target.files[0]["name"].split('.')[1]});
                        const file = URL.createObjectURL(event.target.files[0]);
                        this.setState({selectedFile: file});
                    }
                }
            }
        }


        const file = event.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        formData.append('file_name', file["name"]);
        formData.append('type_file', file["type"]);

        fetch('/messagings/upload_data', {
            method: 'POST',
            body: formData
        }).then(response => {
            if (!response.ok) {
                throw new Error('Erro ao enviar arquivo para o servidor.');
            }
            return response.json();
        }).then(data => {

            this.setState({uploadFile: data.arquivoUrl});
            console.log('Resposta do servidor:', data.arquivoUrl);
            // Faça algo com a resposta do servidor, se necessário
            console.log('Resposta do servidor:', data);
        })
            .catch(error => {
                console.error('Erro ao enviar arquivo:', error);
            });


        {/*
        this.setState({uploadFile: response.data.arquivoUrl});
        console.log('Resposta do servidor:', response.data.arquivoUrl);
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('arquivos[]', files[i]);
        }
        formData.append('file_name', files[0]["name"]);
        formData.append('type_file', files[0]["type"]);

        // Faça uma solicitação HTTP POST para o backend
         axios.post('/messagings/upload_data', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then(response => {
                this.setState({uploadFile: response.data.arquivoUrl});
                console.log('Resposta do servidor:', response.data.arquivoUrl);
            })
            .catch(error => {
                console.error('Erro ao enviar arquivos:', error);
            });
    */}

    };

    async fetchGetChat() {

        const {selectedChat,chat, user_role} = this.props;
        if(user_role !== "support"){
            let response = await axios.get('/messagings/support/get_menssages.json', {
                params: {

                    chat_id: chat.chat_id,
                }
            });

            this.setState({messages: response.data.messages})
        }else{
            if(selectedChat){

                let response = await axios.get('/messagings/support/get_menssages.json', {
                    params: {

                        chat_id: selectedChat.chat_id,
                    }
                });

                this.setState({messages: response.data.messages})
                if(this.props.isLoading === true ){
                    this.props.isLoadingUp(false);

                }
            }
        }



    }
    componentDidMount() {
        // Inicieuma chamada de API a cada X segundos
        this.fetchGetChat();
        this.interval = setInterval(() => this.fetchGetChat(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.interval); // Limpe o intervalo quando o componente for desmontado

    }

    render() {
        const {
            tags,
            tagContacts,
            messages,
            inputMessage,
            isRecording,
            second,
            audioData,
            minute,
            recordState,
            selectedFile,
            selectedNameFile,
            selectedTypeFile,
            selectedFileSubmit,
            selectedChatInternal,
            currentMessageId,
            chatContainerRef
        } = this.state;
        const {
            user_role,
            image_support_v2,
            user_support,
            lawyers,
            chatValidate,

            selectedChat,
            current_user_name,
            current_user_id,
            image_avatar,
            loader,
            isLoading,
            currentCompany,
            departments,
            tickets,
            user_name,
            chat,
            initial_messages
        } = this.props;

        let msgs =[]
        if(user_role !== "support"){
            if(!messages.length > 0){
                msgs = initial_messages
            }else{
                msgs = messages
            }
        }



        return (<>

            {user_role !== "support" && <div className="card right-sidebar-chat">
                <div className="right-sidebar-title">
                    <div className="common-space">
                        <div className="chat-time">
                            <div className="active-profile">
                                <ImageWithFallback
                                    classImage={"img-fluid rounded-circle"}
                                    src={image_support_v2}
                                    fallback={image_avatar}
                                    alt="user"
                                />
                                <div className="status bg-success"></div>
                            </div>
                            <div><span>{user_support.name}</span>
                                <p>Online </p>
                            </div>
                        </div>
                        {/*
                        <div className="d-flex gap-2">
                            <div className="contact-edit chat-alert"><i className="bx bxs-user"></i></div>
                            <div className="contact-edit chat-alert">
                                <i className="bx bx-cog" role="menu" data-bs-toggle="dropdown"
                                   aria-expanded="false"></i>
                                <div className="dropdown-menu dropdown-menu-end"><a
                                    className="dropdown-item" href="#">View details</a><a
                                    className="dropdown-item" href="#">
                                    Send messages</a><a className="dropdown-item" href="#!">
                                    Add to favorites</a></div>
                            </div>
                        </div>*/}

                    </div>
                </div>
                <div className="right-sidebar-Chats">
                    <div className="msger">
                        <div className="msger-chat">
                            {msgs.map((msg) => (
                                <div key={msg.id}>
                                    <div className={`msg mb-10 ${msg.position === "right" ? 'right-msg' : 'left-msg'}`}>
                                        <div className="msg-img">
                                            <ImageWithFallback
                                                classImage={""}
                                                src={msg.sender_user_idx === current_user_id ? msg.avatar : image_support_v2}
                                                fallback={image_avatar}
                                                alt="Imagem"
                                            />
                                        </div>
                                        <div className="msg-bubble">
                                            <div className="msg-info">
                                                <div
                                                    className="msg-info-name">{msg.sender}</div>
                                                <div
                                                    className="msg-info-time">{msg.date}</div>
                                            </div>
                                            <div className="msg-info">
                                                {/*<div className="msg-info-name">{msg.position === "right" ? "" : msg.sender}</div>*/}
                                            </div>
                                            {msg.type === 'text' && msg.subtype === 'text' &&
                                                <div className="msg-text">{msg.text}</div>}
                                            {(msg.type === 'text/file' || msg.type === 'file') &&
                                                <React.Fragment>
                                                    {msg.subtype.split('/')[0] === 'image' &&
                                                        <div className="msg-imagem">
                                                            <a href={msg.file} target="_blank"
                                                               rel="noopener noreferrer">
                                                                <img src={msg.file}
                                                                     style={{
                                                                         width: "100%",
                                                                         borderRadius: "5px"
                                                                     }}/>
                                                            </a>

                                                        </div>
                                                    }
                                                    {msg.subtype.split('/')[0] === 'audio' &&
                                                        <div className="msg-audio">
                                                            <div className="holder">
                                                                <div className="audio green-audio-player">
                                                                    <a href={msg.file} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <audio width={230} height={230}
                                                                               src={msg.file}
                                                                               controls/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    }
                                                    {msg.subtype.split('/')[0] === 'video' &&
                                                        <div className="msg-video">
                                                            <div className="holder">
                                                                <div className="video green-video-player">
                                                                    <a href={msg.file} target="_blank"
                                                                       rel="noopener noreferrer">
                                                                        <video width={230} height={230}
                                                                               src={msg.file}
                                                                               controls/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {(msg.subtype.split('/')[0] === 'pdf' || msg.subtype.split('/')[1] === 'pdf') &&
                                                        <div className="msg-imagem">
                                                            <a href={msg.file} target="_blank"
                                                               rel="noopener noreferrer">
                                                                <img src={this.props.image_pdf_url}
                                                                     style={{
                                                                         width: "100%",
                                                                         borderRadius: "5px"
                                                                     }}/>
                                                            </a>
                                                        </div>
                                                    }
                                                    {(msg.subtype.split('/')[0] === 'doc' || msg.subtype.split('/')[0] === 'docx' || msg.subtype.split('/')[1] === 'doc' || msg.subtype.split('/')[1] === 'docx' || msg.subtype.split('/')[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document") &&
                                                        <div className="msg-imagem">
                                                            <a href={msg.file} target="_blank"
                                                               rel="noopener noreferrer">
                                                                <img src={this.props.image_doc_url}
                                                                     style={{
                                                                         width: "100%",
                                                                         borderRadius: "5px"
                                                                     }}/>
                                                            </a>
                                                        </div>
                                                    }

                                                    {msg.type === 'text/file' &&
                                                        <div className="msg-text">{msg.text}</div>
                                                    }

                                                </React.Fragment>}

                                        </div>
                                    </div>
                                </div>
                            ))
                            }
                            { /* <div className="msg left-msg">
                                <div className="msg-img"></div>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Jose</div>
                                        <div className="msg-info-time">01:14 PM</div>
                                    </div>
                                    <div className="msg-text">Olá, estou pensando em reformular meu site.
                                        Pode me ajudar? 😄
                                    </div>
                                </div>
                            </div>
                            <div className="msg left-msg">
                                <div className="msg-img"></div>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Jose</div>
                                        <div className="msg-info-time">01:14 PM</div>
                                    </div>
                                    <div className="msg-imagem"></div>
                                    <div className="msg-imagem"><img
                                        src="https://static.todamateria.com.br/upload/pa/is/paisagem-natural-og.jpg?class=ogImageWide"
                                        style={{width: "100%", borderRadius: "5px"}}/>
                                    </div>
                                </div>
                            </div>
                            <div className="msg left-msg">
                                <div className="msg-img"></div>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Jose</div>
                                        <div className="msg-info-time">01:14 PM</div>
                                    </div>
                                    <div className="msg-imagem">

                                    </div>
                                    <div className="msg-audio">
                                        <div className="holder">
                                            <div className="audio green-audio-player">
                                                <div className="loading">
                                                    <div className="spinner"></div>
                                                </div>
                                                <div className="play-pause-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                         height="24" viewBox="0 0 18 24">
                                                        <path fill="#566574" fillRule="evenodd"
                                                              d="M18 12L0 24V0" className="play-pause-icon"
                                                              id="playPause"></path>
                                                    </svg>
                                                </div>

                                                <div className="controls">
                                                    <span className="current-time">0:00</span>
                                                    <div className="slider1" data-direction="horizontal">
                                                        <div className="progress">
                                                            <div className="pin" id="progress-pin"
                                                                 data-method="rewind"></div>
                                                        </div>
                                                    </div>
                                                    <span className="total-time">0:00</span>
                                                </div>

                                                <div className="volume">
                                                    <div className="volume-btn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                             height="24" viewBox="0 0 24 24">
                                                            <path fill="#566574" fillRule="evenodd"
                                                                  d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z"
                                                                  id="speaker"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="volume-controls hidden">
                                                        <div className="slider" data-direction="vertical">
                                                            <div className="progress">
                                                                <div className="pin" id="volume-pin"
                                                                     data-method="changeVolume"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <audio crossOrigin="">
                                                    <source
                                                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/Swing_Jazz_Drum.mp3"
                                                        type="audio/mpeg"/>
                                                </audio>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="msg right-msg">
                                <div className="msg-img">

                                </div>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">Você</div>
                                        <div className="msg-info-time">12:14 PM</div>
                                    </div>
                                    <div className="msg-audio">
                                        <div className="holder">
                                            <div className="audio green-audio-player">
                                                <div className="loading">
                                                    <div className="spinner">

                                                    </div>
                                                </div>
                                                <div className="play-pause-btn">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                         height="24" viewBox="0 0 18 24">
                                                        <path fill="#566574" fillRule="evenodd"
                                                              d="M18 12L0 24V0" className="play-pause-icon"
                                                              id="playPause"></path>
                                                    </svg>
                                                </div>

                                                <div className="controls">
                                                    <span className="current-time">0:00</span>
                                                    <div className="slider1" data-direction="horizontal">
                                                        <div className="progress">
                                                            <div className="pin" id="progress-pin"
                                                                 data-method="rewind"></div>
                                                        </div>
                                                    </div>
                                                    <span className="total-time">0:00</span>
                                                </div>

                                                <div className="volume">
                                                    <div className="volume-btn">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                             height="24" viewBox="0 0 24 24">
                                                            <path fill="#566574" fillRule="evenodd"
                                                                  d="M14.667 0v2.747c3.853 1.146 6.666 4.72 6.666 8.946 0 4.227-2.813 7.787-6.666 8.934v2.76C20 22.173 24 17.4 24 11.693 24 5.987 20 1.213 14.667 0zM18 11.693c0-2.36-1.333-4.386-3.333-5.373v10.707c2-.947 3.333-2.987 3.333-5.334zm-18-4v8h5.333L12 22.36V1.027L5.333 7.693H0z"
                                                                  id="speaker"></path>
                                                        </svg>
                                                    </div>
                                                    <div className="volume-controls hidden">
                                                        <div className="slider" data-direction="vertical">
                                                            <div className="progress">
                                                                <div className="pin" id="volume-pin"
                                                                     data-method="changeVolume"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <audio crossOrigin="">
                                                    <source
                                                        src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/355309/Swing_Jazz_Drum.mp3"
                                                        type="audio/mpeg"/>
                                                </audio>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                        </div>

                        <form className="msger-inputarea col-xl-12" action="#" onSubmit={this.handleFormSubmit}
                              method="post" acceptCharset="utf-8">

                            {selectedFileSubmit === true && selectedFile && <div className="col-xl-10">
                                <ul className=" mb-2 d-flex">
                                    <li className="left text-center w-10">

                                        {selectedTypeFile.split('/')[0] === "audio" && selectedFile && (
                                            <img width={60} height={60} src={this.props.image_mp3_url}/>
                                        )}
                                        {selectedTypeFile.split('/')[0] === "image" && selectedFile && (
                                            <img width={60} height={60} src={this.props.image_image_url}/>
                                        )}
                                        {selectedTypeFile.split('/')[0] === "video" && selectedFile && (
                                            <img width={60} height={60} src={this.props.image_video_url}/>
                                        )}
                                        {selectedTypeFile === "pdf" && selectedFile && (
                                            <img width={60} height={60} src={this.props.image_pdf_url}/>
                                        )}
                                        {(selectedTypeFile === "doc" || selectedTypeFile === "docx") && selectedFile && (
                                            <img width={60} height={60} src={this.props.image_doc_url}/>

                                        )}


                                    </li>
                                    <li className="right w-90">

                                                             <textarea className="w-100" name="message"
                                                                       onChange={this.handleInputChange}
                                                                       value={inputMessage}
                                                                       placeholder="Escreva sua mensagem..."></textarea>

                                    </li>
                                </ul>
                            </div>

                            }

                            {selectedFileSubmit === false && selectedFile && <div className="col-xl-10">
                                <ul className=" mb-2 d-flex">
                                    <li className="left text-center w-10">
                                    </li>
                                    <li className="right w-90">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                    </li>
                                </ul>

                            </div>

                            }
                            {!selectedFile && <div className="col-xl-10">
                                <ul className=" mb-2 d-flex">
                                    <li className="left text-center w-10">
                                    </li>
                                    <li className="right w-90">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                    </li>
                                </ul>
                            </div>
                            }


                            <div className="col-xl-2 d-flex">
                                {/*<div className="open-emoji">
                                        <a href="#" title="Enviar Arquivo (imagem/pdf/video)"
                                           onClick={this.handleClearSelectedFile} data-toggle="modal"
                                           data-target="#upload_arquivo">
                                            <i className='bx bx-paperclip'></i>
                                        </a>
                                    </div>*/}

                                <div className="contact-edit chat-alert">
                                    <i className="bx bx-paperclip" role="menu" data-bs-toggle="dropdown"
                                       aria-expanded="false"></i>
                                    <div className="dropdown-menu dropdown-menu-end">
                                        <a className="dropdown-item" onClick={this.handleClearSelectedFile}
                                           data-toggle="modal"
                                           data-target="#upload_arquivo" href="#">Buscar Arquivo</a>

                                    </div>
                                </div>
                                <button className="msger-send-btn" type="submit"><i
                                    className="bx bxs-paper-plane"></i></button>
                            </div>


                        </form>
                    </div>
                </div>
            </div>

            }
            {user_role === "support" &&

                <>
                    {selectedChat &&
                        <div className="card right-sidebar-chat">
                            <div className="right-sidebar-title">
                                <div className="common-space">
                                    <div className="chat-time">
                                        <div className="active-profile">
                                            <ImageWithFallback
                                                classImage={"img-fluid rounded-circle"}
                                                src={selectedChat.avatar}
                                                fallback={image_avatar}
                                                alt="user"
                                            />
                                            <div className="status bg-success"></div>
                                        </div>
                                        <div>
                                            <span>{selectedChat.title}</span>
                                            <p>Online </p>
                                        </div>
                                    </div>

                                    <div className="d-flex gap-2">
                                        <div className="contact-edit chat-alert"><i className="bx bxs-user"></i></div>
                                        <div className="contact-edit chat-alert">
                                            <i className="bx bx-cog" role="menu" data-bs-toggle="dropdown"
                                               aria-expanded="false"></i>
                                            <div className="dropdown-menu dropdown-menu-end"><a
                                                className="dropdown-item" href="#">View details</a><a
                                                className="dropdown-item" href="#">
                                                Send messages</a><a className="dropdown-item" href="#!">
                                                Add to favorites</a></div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="right-sidebar-Chats">
                                <div className="msger">
                                    <div className="msger-chat">
                                        {isLoading ? <Loader src={loader}/> : messages.map((msg) => (
                                            <div key={msg.id}>
                                                <div
                                                    className={`msg mb-10 ${msg.position === "right" ? 'right-msg' : 'left-msg'}`}>
                                                    <div className="msg-img">
                                                        <ImageWithFallback
                                                            classImage={""}
                                                            src={msg.avatar}
                                                            fallback={image_avatar}
                                                            alt="Imagem"
                                                        />
                                                    </div>
                                                    <div className="msg-bubble">
                                                        <div className="msg-info">
                                                            <div
                                                                className="msg-info-name">{msg.sender}</div>
                                                            <div
                                                                className="msg-info-time">{msg.date}</div>
                                                        </div>
                                                        <div className="msg-info">
                                                            {/*<div className="msg-info-name">{msg.position === "right" ? "" : msg.sender}</div>*/}
                                                        </div>
                                                        {msg.type === 'text' && msg.subtype === 'text' &&
                                                            <div className="msg-text">{msg.text}</div>}
                                                        {(msg.type === 'text/file' || msg.type === 'file') &&
                                                            <React.Fragment>
                                                                {msg.subtype.split('/')[0] === 'image' &&
                                                                    <div className="msg-imagem">
                                                                        <a href={msg.file} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <img src={msg.file}
                                                                                 style={{
                                                                                     width: "100%",
                                                                                     borderRadius: "5px"
                                                                                 }}/>
                                                                        </a>

                                                                    </div>
                                                                }
                                                                {msg.subtype.split('/')[0] === 'audio' &&
                                                                    <div className="msg-audio">
                                                                        <div className="holder">
                                                                            <div className="audio green-audio-player">
                                                                                <a href={msg.file} target="_blank"
                                                                                   rel="noopener noreferrer">
                                                                                    <audio width={230} height={230}
                                                                                           src={msg.file}
                                                                                           controls/>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                }
                                                                {msg.subtype.split('/')[0] === 'video' &&
                                                                    <div className="msg-video">
                                                                        <div className="holder">
                                                                            <div className="video green-video-player">
                                                                                <a href={msg.file} target="_blank"
                                                                                   rel="noopener noreferrer">
                                                                                    <video width={230} height={230}
                                                                                           src={msg.file}
                                                                                           controls/>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {(msg.subtype.split('/')[0] === 'pdf' || msg.subtype.split('/')[1] === 'pdf') &&
                                                                    <div className="msg-imagem">
                                                                        <a href={msg.file} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <img src={this.props.image_pdf_url}
                                                                                 style={{
                                                                                     width: "100%",
                                                                                     borderRadius: "5px"
                                                                                 }}/>
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {(msg.subtype.split('/')[0] === 'doc' || msg.subtype.split('/')[0] === 'docx' || msg.subtype.split('/')[1] === 'doc' || msg.subtype.split('/')[1] === 'docx' || msg.subtype.split('/')[1] === "vnd.openxmlformats-officedocument.wordprocessingml.document") &&
                                                                    <div className="msg-imagem">
                                                                        <a href={msg.file} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <img src={this.props.image_doc_url}
                                                                                 style={{
                                                                                     width: "100%",
                                                                                     borderRadius: "5px"
                                                                                 }}/>
                                                                        </a>
                                                                    </div>
                                                                }

                                                                {msg.type === 'text/file' &&
                                                                    <div className="msg-text">{msg.text}</div>
                                                                }

                                                            </React.Fragment>}

                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        }
                                    </div>
                                    <form className="msger-inputarea col-xl-12" action="#"
                                          onSubmit={this.handleFormSubmit}
                                          method="post" acceptCharset="utf-8">

                                        {selectedFileSubmit === true && selectedFile && <div className="col-xl-10">
                                            <ul className=" mb-2 d-flex">
                                                <li className="left text-center w-10">

                                                    {selectedTypeFile.split('/')[0] === "audio" && selectedFile && (
                                                        <img width={60} height={60} src={this.props.image_mp3_url}/>
                                                    )}
                                                    {selectedTypeFile.split('/')[0] === "image" && selectedFile && (
                                                        <img width={60} height={60} src={this.props.image_image_url}/>
                                                    )}
                                                    {selectedTypeFile.split('/')[0] === "video" && selectedFile && (
                                                        <img width={60} height={60} src={this.props.image_video_url}/>
                                                    )}
                                                    {selectedTypeFile === "pdf" && selectedFile && (
                                                        <img width={60} height={60} src={this.props.image_pdf_url}/>
                                                    )}
                                                    {(selectedTypeFile === "doc" || selectedTypeFile === "docx") && selectedFile && (
                                                        <img width={60} height={60} src={this.props.image_doc_url}/>

                                                    )}


                                                </li>
                                                <li className="right w-90">

                                                             <textarea className="w-100" name="message"
                                                                       onChange={this.handleInputChange}
                                                                       value={inputMessage}
                                                                       placeholder="Escreva sua mensagem..."></textarea>

                                                </li>
                                            </ul>
                                        </div>

                                        }

                                        {selectedFileSubmit === false && selectedFile && <div className="col-xl-10">
                                            <ul className=" mb-2 d-flex">
                                                <li className="left text-center w-10">
                                                </li>
                                                <li className="right w-90">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                                </li>
                                            </ul>

                                        </div>

                                        }
                                        {!selectedFile && <div className="col-xl-10">
                                            <ul className=" mb-2 d-flex">
                                                <li className="left text-center w-10">
                                                </li>
                                                <li className="right w-90">
                                            <textarea className="w-100" name="message"
                                                      onChange={this.handleInputChange}
                                                      value={inputMessage}
                                                      placeholder="Escreva sua mensagem..."></textarea>
                                                </li>
                                            </ul>
                                        </div>
                                        }


                                        <div className="col-xl-2 d-flex">
                                            {/*<div className="open-emoji">
                                        <a href="#" title="Enviar Arquivo (imagem/pdf/video)"
                                           onClick={this.handleClearSelectedFile} data-toggle="modal"
                                           data-target="#upload_arquivo">
                                            <i className='bx bx-paperclip'></i>
                                        </a>
                                    </div>*/}

                                            <div className="contact-edit chat-alert">
                                                <i className="bx bx-paperclip" role="menu" data-bs-toggle="dropdown"
                                                   aria-expanded="false"></i>
                                                <div className="dropdown-menu dropdown-menu-end">
                                                    <a className="dropdown-item" onClick={this.handleClearSelectedFile}
                                                       data-toggle="modal"
                                                       data-target="#upload_arquivo" href="#">Buscar Arquivo</a>

                                                </div>
                                            </div>
                                            <button className="msger-send-btn" type="submit"><i
                                                className="bx bxs-paper-plane"></i></button>
                                        </div>


                                    </form>
                                </div>
                            </div>
                        </div>
                    }
                </>}

            <div className="modal custom-modal fade" id="upload_arquivo" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="form-header">
                                <h4 className="modal-title">Enviar Arquivo</h4>
                                <button type="button" className="close"
                                        data-dismiss="modal">&times;</button>

                                <div className="user-pic text-center">
                                    <div className="avatar ">

                                        {selectedTypeFile.split('/')[0] === "audio" && selectedFile && (
                                            <div className="pro-user-username  fs-14"
                                                 style={{marginTop: "10%"}}>
                                                <audio src={selectedFile} controls/>
                                            </div>

                                        )}
                                        {selectedTypeFile.split('/')[0] === "image" && selectedFile && (
                                            <img width={230} height={230} src={selectedFile} alt="Preview"/>
                                        )}
                                        {selectedTypeFile.split('/')[0] === "video" && selectedFile && (
                                            <video width={230} height={230} src={selectedFile} controls/>
                                        )}
                                        {selectedTypeFile === "pdf" && selectedFile && (
                                            <img width={230} height={230} src={this.props.image_pdf_url}/>
                                        )}
                                        {(selectedTypeFile === "doc" || selectedTypeFile === "docx") && selectedFile && (
                                            <img width={230} height={230} src={this.props.image_doc_url}
                                                 alt="Word Document"/>
                                        )}


                                    </div>

                                    <div className="pro-user mt-3">
                                        <h5 className="pro-user-username text-dark mb-2 fs-15 mt-42 color-span">
                                            {selectedNameFile}
                                        </h5>
                                        <h6 className="pro-user-desc text-muted fs-14"></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-btn  text-center">


                                <div className="form-group">
                                    <label>Upload</label>
                                    <input className="form-control"
                                           accept="image/*, audio/*, video/*,document/pdf,  document/doc, document/docx"
                                           id="input_file_update" type="file"
                                           onChange={this.handleFileChange}/>
                                </div>

                                <div>
                                    {selectedFile && <button onClick={this.handleSubmit}
                                                             style={{
                                                                 padding: "0.8rem 2rem",
                                                                 border: "none",
                                                                 backgroundColor: "#3c21f7",
                                                                 marginLeft: "15px",
                                                                 fontSize: "1rem",
                                                                 cursor: "pointer",
                                                                 color: "white",
                                                                 borderRadius: "5px",
                                                                 fontWeight: "bold",
                                                                 transition: "all 300ms ease-in-out",
                                                                 transform: "translateY(0)"
                                                             }}>Confirmar</button>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
}