import React from 'react';


const DateInputNode = ({ label ,handleInputSelecting, inputSelected}) => {
    return (
        <div  key={label}  onClick={() => handleInputSelecting(label)} style={{borderColor: inputSelected[label] ? '#ba3291' : ''}} className=" input-node d-flex center">

            <i className='bx bx-calendar'></i>
            <p style={{color: inputSelected[label] ? '#ba3291' : ''}}>{"Data"}</p>

        </div>
    );
}
export default DateInputNode;