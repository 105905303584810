import React, { useState } from 'react';
const ErrorMessage = ({message, chat, image_avatar, currentCompany}) => {
    return (
        <div className="list-message mb-10" style={{
            borderLeft: "4px solid #FF9800",
            paddingLeft: 16, /* Espaço entre a linha e o conteúdo do card */
            backgroundColor: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: 8
        }}>
            <div className="d-flex">
                <i className='bx bx-info-circle' style={{fontSize: 48, color: "#f4be75", paddingRight: 10}}></i>
                <p style={{marginTop: "-3px"}}
                   className="msg-text">{"Não é possível exibir essa mensagem neste dispositivo. Abra o WhatsApp no seu celular para ver a mensagem. "}</p>
            </div>


        </div>
    )
}
export default ErrorMessage;