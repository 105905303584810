import React from 'react';

const ImageWithFallback = ({classImage, src, fallback, alt, ...props }) => {
    const handleError = (event) => {
        event.target.onerror = null;
        event.target.src = fallback;
    };
    let style = {
        borderRadius: "50%"
    }
    if (props["dashbord"]){
        style = {
            width: 80,
            height: 80,
            borderRadius: "50%"
        }
    }

    return <img className={classImage} style={style} src={src} alt={alt} onError={handleError} {...props} />;
};

export default ImageWithFallback;