import React from "react";

import { ReactMic } from 'react-mic';
import { FaPlayCircle } from 'react-icons/fa';

export default class  RecordVoice extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            audioData: null,
            second:"00" ,
            minute: "00",
            isActive: false,
            counter: 0,

        };
    }

    onData = (recordedBlob) => {
        this.setState({ audioData: recordedBlob.blob});
    }
    onStop = () => {

        // Enviar os dados de áudio para o backend
       // const formData = new FormData();
        //formData.append('audio', this.state.audioData);
        {/*fetch('/messagings/upload_audio', {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Audio uploaded successfully:', data.url);
            })
            .catch(error => {
                console.error('Error uploading audio:', error);
            }); */}
    };



    handleStartTime = () =>  {

        this.intervalId = setInterval(() => {
            const secondCounter = this.state.counter % 60;
            const minuteCounter = Math.floor(this.state.counter / 60);

            let computedSecond =
                String(secondCounter).length === 1
                    ? `0${secondCounter}`
                    : secondCounter;
            let computedMinute =
                String(minuteCounter).length === 1
                    ? `0${minuteCounter}`
                    : minuteCounter;


            this.setState({ second: computedSecond});
            this.setState({ minute: computedMinute});
            let counter = this.state.counter + 1
            this.setState({ counter: counter});

        }, 1000);
    }
    handleClearTimer = () =>  {
        //clearInterval(this.intervalId);
        this.setState({ isActive: false});
        //this.props.onIsRecording(false);
        this.setState({ audioData: null});
        this.setState({ counte: 0});
        this.setState({ second: "00"});
        this.setState({ minute: "00"});

    }
    handleStartAudio = () =>  {

        //this.props.onIsRecording(true);
        this.setState({ isActive: true});
        //this.handleStartTime()
    };
    handleStopTime = () =>  {
       // this.props.onIsRecording(false);
        this.setState({ isActive: false});
        //clearInterval(this.intervalId);
    };
    render() {
        const { minute, second ,isActive, audioData} = this.state;

        console.log("isActive " + isActive)


        return (<div className=" text-center">

            <div>
                { !audioData &&
                    <div className="audio-message-input">
                        <input type="text" name="upload_audio_input" value={audioData || ''} readOnly id="upload_audio_input" hidden />
                        <div className="audio-recorder">
                            <ReactMic
                                width="430"
                                record={isActive}
                                onStop={this.onStop}
                                onData={this.onData}

                                className="react-mic-recorder"
                            />

                        </div>
                        { audioData &&
                            <audio src={audioData} controls loop />
                        }
                    </div>
                }

                <ul>
                    <li>
                        <a className="minute">{minute}</a>
                        <a>:</a>
                        <a className="second">{second}</a>
                    </li>
                    <li>
                        <button
                            style={{
                                backgroundColor: "black",
                                borderRadius: "8px",
                                color: "white"
                            }}
                            onClick={this.handleClearTimer}>
                            Clear
                        </button>
                    </li>
                </ul>


            </div>
            { !isActive  &&  <button onClick={this.handleStartAudio}
                                          style={{
                                              padding: "0.8rem 2rem",
                                              border: "none",
                                              backgroundColor: "#3c21f7",
                                              marginLeft: "15px",
                                              fontSize: "1rem",
                                              cursor: "pointer",
                                              color: "white",
                                              borderRadius: "5px",
                                              fontWeight: "bold",
                                              transition: "all 300ms ease-in-out",
                                              transform: "translateY(0)"
            }}>Start</button>}

            { isActive  && <button onClick={this.handleStopTime}
                                       style={{
                                           padding: "0.8rem 2rem",
                                           border: "none",
                                           backgroundColor: "#df3636",
                                           marginLeft: "15px",
                                           fontSize: "1rem",
                                           cursor: "pointer",
                                           color: "white",
                                           borderRadius: "5px",
                                           fontWeight: "bold",
                                           transition: "all 300ms ease-in-out",
                                           transform: "translateY(0)"
                                       }}>Stop</button>}

        </div>)
    }
}

